import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'react-toastify/dist/ReactToastify.css';
import { PostApi } from '../../../services/CommonService2';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const MyProgramsItemList = (props) => {


    useEffect(() => {
    }, [])
    const history = useHistory();
    const [msg, setMsg] = useState(false)
    const [modal, setModal] = useState(false)
    const [newData, setNewData] = useState([])
    const [userId, setUserId] = useState("")
    const [programid, setProgramid] = useState("")
    // const [mainSectionId, setMainSectionId] = useState(null)
    const [QuizTitle, setQuizTitle] = useState(null)
    const [show, setShow] = useState(false);
    const [tanantid, setTanantId] =useState("")
    const [confirm, setConfirm] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const npage = Math.ceil(props.courseList.length / recordsPerPage)
    const records = props.courseList.slice(firstIndex, lastIndex)
    const numbers = [...Array(npage + 1).keys()].slice(1)
    const [loading,setLoading] =useState(false)


    const prePage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }


    }
    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }

    }
    const changeCPage = (id) => {
        setCurrentPage(id)

    }
    const detailsFun = (data) => {
        setNewData(data)
        if (data.coursestatus == "completed") {
            setModal(true)
            // console.log(newData, "newData")
            // console.log("completed");


        }
        else {
            // console.log("pendingg");

            history.push({
                pathname: '/course-grid',
                state: data

            })

        }

    }
    const assesmentModal = async () => {
        cancelmodal()
        setShow(true)
      
        setUserId(newData.userid)
        setProgramid(newData.programid)
     
        const data= records.filter(element=>{
            return element.programid==newData.programid
        })

        setQuizTitle(data[0].name)
  
        const reqparam = {
            pageno: '-1',
            query: {}
          }
      
          const tenantresp = await PostApi(reqparam, 'TENANT');
          setTanantId(tenantresp.data[0].tenantid)
        // setMainSectionId(newData.id)
       


    }
    const viewCourse = async () => {
        // console.log("newData",newData);
        history.push({
            pathname: '/course-grid',
            state: newData

        })


    }
    const cancelmodal = async () => {
        setModal(false)
    }
    useEffect(() => {
        if (records?.length == 0) {
            setMsg(true)
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, 10000);
            
        }
        else {
            setMsg(false)
            setLoading(false)
        }
    }, [])
    const goBack = () => {
        history.push("/");
    };

    const handleConfirm = ()=>{
        setConfirm(false)
        setShow(false)
    }
    const handleMainClose=()=>{
        setConfirm(true)
    }

    return (
        <>
            <button className="btn btn-primary" onClick={goBack} style={{ position: "fixed", bottom: "20px", left: "10px", zIndex: 999, width: "30px" }}><i className="fa-solid fa-arrow-left"></i></button>
            <Fragment>
                {records.length>0? records?.map((data, i) => {
                    let imageid = 'https://visualpathedu.com/static/media/4th-year.15b862d606512e3257c0.jpg';
                    if (data?.image) {
                        imageid = data.image;
                    } else if (data?.image_url) {
                        imageid = data.image_url;
                    }
                    else {
                        imageid = data.imageurl ? data.imageurl : "";
                    }
                    return <Col md="12" key={i} >
                        <div className="course-item d-flex" onClick={() => detailsFun(data)} >
                            <div className="course-image-box">
                                <div className="course-image">
                                    <div className="author-img ">
                                        <div className="img">
                                            {/* <img src={data.image} alt="" />
                                                <Link to={process.env.PUBLIC_URL + data.courseLink}>
                                                    <img src={data.image} alt="" />
                                                </Link> */}
                                        </div>
                                        {/* <div className="title">
                                            <p>{data.price}</p>
                                            <span>{data.price}</span>
                                        </div> */}
                                    </div>
                                    <img src={imageid} className="course-image" ></img>

                                    {/* <div className="sale-div">
                                        {data.on_sale=='Y' && <p>Sale</p> }
                                    </div>
                                    <div className="course-price">
                                        <p>Rs.{data.sale_price} <span className='price-div'  >Rs.{data.price}</span> </p>
                                    </div> */}

                                </div>
                            </div>
                            {/* {console.log(data, "check image data all data")} */}
                            <div className="course-content" style={{ width: "100%" }}  >
                                <h6 className="heading">{data.name}</h6>
                                <div className="rating">
                                    <ul className="list-unstyled list-inline">
                                        {data?.duration && <p className='duration2' ><i className="fa-regular fa-clock projects-icon"></i>duration:{data?.duration}</p>}


                                    </ul>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                    <ProgressBar
                                        // now={50}
                                        now={data.result}
                                        style={{ width: "80%" }}
                                    />

                                    {data?.coursestatus === 'completed' && <label className="desc">Completed</label>}
                                    {data?.coursestatus === 'failed' && <label className="desc">Failed</label>}
                                    {data?.coursestatus === 'started' && <label className="desc">In progress</label>}
                                </div>






                                <p className="desc">{data.description.slice(0, 100)}</p>
                                <button className="details-btn">View Details</button>



                            </div>

                        </div>

                    </Col>
                }):loading? <Skeleton height={220} count={4} className='mb-3'/>:<div style={{width:"100%", height:"60vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <h2>No Courses Purchased</h2>
                </div>
                }


                {!msg && <Col md="12" className="text-center">
                    <div className='mainpagination'>
                        <ul className='pagination'>
                            <li className='page-item'>
                                <label className='btn btn-outline-success page-link' onClick={prePage}><i className="fa-solid fa-angles-left"></i></label>

                            </li>
                            {numbers.map((n, i) => (
                                <li className={`page-item ${currentPage === n ? 'active' : ""}`} key={i}>
                                    <label className='btn btn-outline-success page-link' onClick={() => changeCPage(n)}>{n}</label>

                                </li>

                            ))}

                            <li className='page-item'>
                                <label className='btn btn-outline-success page-link' onClick={nextPage}><i className="fa-solid fa-angles-right"></i></label>

                            </li>


                        </ul>
                    </div>
                </Col>}

                {modal &&
                    <Modal show={modal} onHide={cancelmodal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Course Completed</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>You have successfully completed your courses . Please finish your assessment to generate your course completion certificate</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="btn btn-outline-dark" onClick={assesmentModal}>Assessment</Button>
                            <Button variant="btn btn-outline-dark" onClick={viewCourse}>ViewCourse</Button>
                            <Button onClick={cancelmodal}>Close</Button>
                        </Modal.Footer>

                    </Modal>
                }




            </Fragment>
            {/* {console.log(QuizTitle, "QuizTitle")} */}
            {show &&
                <Modal show={show} backdrop="static" onHide={handleMainClose} style={{ width: "367px", overflow:"hidden", marginLeft:"30%" }} aria-labelledby="contained-modal-title-vcenter"
                centered >

                    <Modal.Header closeButton="true" >
                        <h3 style={{ padding: "0px 20px" }}>{QuizTitle}</h3>
                        <hr />
                    </Modal.Header>


                     <iframe src={"https://techbricksedu.com/finalquiz/" + userId + "/" + programid+ "/"+tanantid} title="description" style={{ minHeight: "600px", overflow:"hidden" }}  ></iframe>
                    
                   {/* <iframe src={"http://localhost:19006/finalquiz/" + userId + "/" + programid+ "/"+tanantid} title="description" style={{ minHeight: "600px", overflow:"hidden" }} ></iframe>  */}



                </Modal>}
                <Modal show={confirm} onHide={()=>setConfirm(false)} backdrop="static" style={{ width: "340px", overflow: "hidden", marginLeft: "30%" }} aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header  style={{backgroundColor:"#2C79FF"}}>
             <h4 className='text-center' style={{color:"white"}}> Are you sure want to exit from the Test ??</h4>
                </Modal.Header>


       
                <Modal.Footer style={{backgroundColor:"#2C79FF"}}>
          <Button variant="dark" onClick={()=>setConfirm(false)} >No</Button>
          <Button variant="danger" onClick={handleConfirm}>Yes</Button>
        </Modal.Footer>


            </Modal>
            {/* <FooterTwo/> */}
        </>
    )
}


export default MyProgramsItemList
