import React, { useEffect, useState } from 'react';
import Datas from '../data/course/filter.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Styles } from "./styles/courseFilter.js";
import { GetApi, PostApi } from '../services/CommonService2.js';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import LoginModal from './LoginModal.js';

function CourseFilter() {

    useEffect(() => {
        getcompetitiveexams()
        getCategory();

    }, [])
    const [courseloading, setCourseLoading] = useState(true);
    const [competitiveList, setCompetitiveList] = useState([]);
    const [modalUp, setModalUp] = useState(false)
    const history = useHistory();
    const [category, setCategory]=useState([])
    const [examtypeid,setExamtypeid]=useState([])

    const getcompetitiveexams = async () => {

        const reqparams = {
            pageno: '-1',
            query: { status: 'Active' }
        }
        const dataResponse = await GetApi(reqparams, 'COMPETITIVEEXAM');

        setCompetitiveList(dataResponse.data);
        console.log("competitiveList", dataResponse.data);
        setCourseLoading(false);
        setExamtypeid("all")

    }

    const loginValidate = (data) => {
        var tntId = JSON.parse(localStorage.getItem('tID'))

        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        if (userdata == null) {
            setModalUp(true)
            // e.preventDefault()

            toast.info('login to check course details', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else {
            history.push({
                pathname: "/examslist",
                state: data
            })
        }
    }
    const openModal = () => {
        setModalUp(false)
    }

    const logIn = () => {

    }

    const getCategory = async () => {
        const reqparam = {
            pageno: '-1',
            query: { status: 'Active' }
        }
        const action = "EXAMTYPE"

        const ResponseData = await PostApi(reqparam, action);
        console.log("Category List", ResponseData.data);
        setCategory(ResponseData.data)

    }

    const newData=async(data)=>{
        setExamtypeid(data.id)
        console.log("data",data);
        const reqparams = {
            pageno: '-1',
            query: { status: 'Active',examtype:data.id }
        }
        const dataResponse = await GetApi(reqparams, 'COMPETITIVEEXAM');

        setCompetitiveList(dataResponse.data);
        console.log("competitiveList", dataResponse.data);
    }






    return (
        <Styles>
            {/* Course Area */}
            <section className="course-filter">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="sec-title text-center">
                                <h4>Competitive Exams</h4>
                            </div>
                        </Col>

                        <Col md="12">
                            <div className="filter-btns text-center">
                                <ul className="filter-btn-list list-unstyled list inline">
                                <li  className={examtypeid =='' || examtypeid =="all" ?"active list-inline-item":"list-inline-item"} onClick={getcompetitiveexams}>All </li>

                                    {category?.map((data,i)=>(
                                        <li  className={examtypeid == data.id ?"active list-inline-item":"list-inline-item" } key={i} onClick={()=>newData(data)}>{data.examtype}</li>

                                    ))}
                                    {/* <li data-target="*" className="active list-inline-item">All Courses</li>
                                    <li data-target="desi" className="list-inline-item">Web Design</li>
                                    <li data-target="deve" className="list-inline-item">Web Development</li>
                                    <li data-target="seo" className="list-inline-item">Seo</li>
                                    <li data-target="prog" className="list-inline-item">Programming</li> */}
                                </ul>
                            </div>
                            <Row className="filter-items">
                                {
                                    competitiveList?.map((data, i) => (
                                        <Col lg="4" md="6" key={i} >
                                           {i<10 ?<div className="course-item">
                                                <div className="course-image" onClick={() => loginValidate(data)}>
                                                    <div className="author-img d-flex">
                                                        <div className="img">

                                                        </div>

                                                    </div>
                                                    <img src={data.image} alt="" className="course-image" />
                                                    {/* <div className="course-price">
                                                            <p>{data.price}</p>
                                                        </div> */}
                                                </div>
                                                <div className="course-content">
                                                    <h6 className="heading">{data.examname}</h6>
                                                    <p className="desc">{data.description.slice(0, 100)}</p>
                                                    <div className="course-face d-flex justify-content-between">
                                                        <div className="duration">
                                                            <p><i className="las la-clock"></i>{data.duration} mins</p>
                                                        </div>
                                                        <div className="rating">
                                                            {/* <ul className="list-unstyled list-inline">
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                                </li>
                                                                <li className="list-inline-item">(4.5)</li>
                                                            </ul> */}
                                                        </div>
                                                        {/* <div className="student">
                                                            <p><i className="las la-chair"></i>60</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>:""} 
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                        <Col md="12" className="text-center">
                            <div className="viewall-btn">
                                <Link to={"/examslist"}>View All Exams</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {modalUp && <LoginModal onClose={openModal} login={logIn}></LoginModal>}
            </section>
        </Styles>
    )
}

export default CourseFilter
