import React from 'react'
import ReactPlayer from 'react-player';
function Editorial() {
    return (
        <div >
            <h3 className='mt-1 mb-2'>Editorial</h3>
           <div className='p-4 border border-2'>
           <ReactPlayer url="https://youtu.be/3ChYwiZx46c" controls={true} className="img-fluid" width="100%"
            />
           </div>
            <div>
<p className='mt-4  fs-4 fw-bold'>Examples:</p>

<p className='fs-4 px-4'>{`Input:`}</p>
<p className='fs-4 px-4'>{`N = 5, S = 12`}</p>
<p className='fs-4 px-4'>{`A[] = {1,2,3,7,5}`}</p>
<p className='fs-4 px-4'>{`Output: 2 4`}</p>
<p className='fs-4 px-4'>{`Explanation: The sum of elements `}</p>
<p className='fs-4 px-4'>{`from 2nd position to 4th position `}</p>
<p className='fs-4 px-4'>{`is 12.`}</p>


            </div>
        </div>
    )
}

export default Editorial