import React from 'react'
import HeaderTwo from '../../components/HeaderTwo'
import HeroImage from '../../components/HeroImage'
import EventCard from '../../components/event-partner-onboard/EventCard'
import SliderImages from '../../components/slider-images/SliderImages'
import News from '../../components/news/News'
import Youtube from '../../components/youtube/Youtube'
import { GetSocialLinks } from './sociallinks.service'
import { useEffect } from 'react'
import { useState } from 'react'
import { GetApi, PostApi } from '../../services/CommonService2'
import { Styles } from '../../components/styles/heroImage'
import FooterTwo from '../../components/FooterTwo'
import TeamSlider from '../../components/TeamSlider'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListGroup from 'react-bootstrap/ListGroup';
import DOMPurify from 'dompurify';

function PartnerOnBoard() {
    const [socialLInkData, setSocialLinkData] = useState([])
    const [sliders, setSlider] = useState([]);
    const [tntId, setTntId] = useState('');
    const [tenantdata, setTenantData] = useState({});
    const [constituency, setConstituency] = useState([])
    const [userData, setUserData] = useState([])
    const [subApplicationId, setSubApplicationId] = useState("");
    const [homeBanners, setHomeBanners] = useState([])


    const [content, setContent] = useState([]);
    const [content2, setContent2] = useState([]);
    const [twitterLink, setTwitterLink] = useState("");
    const [fbLink, setFbLink] = useState("")

    useEffect(() => {
        var tntId = JSON.parse(localStorage.getItem('tID'))

        const userInfo = JSON.parse(localStorage.getItem(`userdata${tntId}`));
        setSubApplicationId(userInfo.user.subapplicationid);
        loadData();
        getHomeBanners()
    }, [])


    useEffect(() => {
        var tntId = JSON.parse(localStorage.getItem('tID'))

        const userInfo = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        setUserData(userInfo)
        socialLink()
        getBanner()



        setTntId(JSON.parse(localStorage.getItem('tID')))
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const value = localStorage.getItem(`tenantdata${tntId}`);
        // const value = localStorage.getItem('tenantdata');
        setTenantData(JSON.parse(value));

        getConstituency()

    }, [subApplicationId])

    const getHomeBanners = async () => {
        const reqparamWeb = {
            pageno: '-1',
            query: { status: 'Active', subapplicationid: "" },
            bannerweb: 'Y',
        }
        const ResponseData2 = await PostApi(reqparamWeb, "HOMEBANNER");
        setHomeBanners(ResponseData2.data)
    }

    const socialLink = async () => {
        const reqdata = {
            pageno: '-1',
            query: { status: 'Active' }

        }
        const getSociaLinkData = await PostApi(reqdata, 'SOCIALLINK')
        setSocialLinkData(getSociaLinkData)
    }
    const getBanner = async () => {

        const reqparam = {
            pageno: '-1',
            query: { status: 'Active' },
            bannerweb: 'Y'
        }

        const ResponseData = await PostApi(reqparam, "BANNER", subApplicationId);
        // console.log("baner", ResponseData.data);
        setSlider(ResponseData.data)


    }
    const getConstituency = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))

        const userInfo = JSON.parse(localStorage.getItem(`userdata${tntId}`))

        const reqparam = {
            pageno: "-1",
            tenantid: tenantdata.tenantid,
            query: {
                status: 'Active',
            },


        }


        const ResponseMessage = await GetApi(reqparam, 'CONSTITUENCY');
        const getDistictId = ResponseMessage.data.filter(ele => {
            return ele.id == subApplicationId
        })

        if (getDistictId.length > 0) {
            const reqparamDist = {
                pageno: "-1",
                tenantid: tenantdata.tenantid,
                query: {
                    status: 'Active', districtid: getDistictId[0].districtid
                },


            }
            const ResponseMessageFinal = await GetApi(reqparamDist, 'CONSTITUENCY');
            console.log("ResponseMessageFinal", ResponseMessageFinal, reqparamDist)



            setConstituency(ResponseMessageFinal.data)
            // if(subApplicationId!=userInfo.user.subapplicationid){

            const reqparam8 = {
                pageno: '-1',
                query: { status: 'Active', _id: subApplicationId }
            }
            const TestMessageFinal = await GetApi(reqparam8, 'CONSTITUENCY');
            // console.log('tenant response after change>>>>>>>>>>>',TestMessageFinal);

            setTwitterLink(TestMessageFinal.data[0].twitterid)
            setFbLink(TestMessageFinal.data[0].facebookid)
            // }
            // else{
            //     const reqparam1 = {
            //         pageno: '-1',
            //         query: {}
            //     }

            //     const tenantresp = await PostApi(reqparam1, 'TENANT');
            //     console.log('tenant response after change????????????????????????',tenantresp);
            //     setTwitterLink(tenantresp.data[0].tenantotherinfo.twitterlink)
            //     setFbLink(tenantresp.data[0].tenantotherinfo.facebooklink)
            // }

        }

        //2534101
        //OffDSB
        // if(subApplicationId!=){

        // }


    }
    const handleSetSubId = (ele, i) => {
        setSubApplicationId(ele.id)


    }

    const loadData = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const userInfo = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        const reqparams = {
            pageno: 1,
            query: { actiontype: "About Leader" },
        }
        const reqparams2 = {
            pageno: 1,
            query: { actiontype: "About Constituency" },
        }

        const respData = await PostApi(reqparams, 'SETTING');
        const respData2 = await PostApi(reqparams2, 'SETTING');

        setContent(respData?.data?.docs[0]?.content);
        setContent2(respData2?.data?.docs[0]?.content);
    }
    const sanitizedData = (data) => {
        const sanitizedHtml = DOMPurify.sanitize(data)
        return { __html: sanitizedHtml }
    }
    return (
        <div style={{ overflowX: "hidden" }}>
            {/* {console.log('content========',content,content2)} */}
            <Styles>
                <HeaderTwo />
                {/* {console.log(userData, "userata mfrom POB")} */}
                {/* {console.log("tenantdata",tenantdata)} */}
                {/* <HeroImage /> */}
                {/* <section className="hero-image-area" style={{ backgroundImage: `url(${sliders[0] && sliders[0].image})`, backgroundPosition: "top", backgroundSize: "100% 20rem", maxHeight: "20rem" }}></section> */}
                <div id="carouselExampleIndicators" class="carousel slide " data-bs-ride="carousel" >
                    <div class="carousel-inner" style={{ height: "440px", marginTop: "3%", cursor: "pointer" }}>

                        <div className="carousel-item active "  >
                            <img src={sliders[0]?.image ? sliders[0]?.image : homeBanners[0]?.image} className="d-block" alt="..." style={{ height: "224%" }} />
                        </div>


                    </div>
                </div>



                <div className='row contentcontainer'>
                    {content2 && <div className='col-3 content'><p dangerouslySetInnerHTML={sanitizedData(content2)}></p></div>}
                    {content && <div className='col-3 content'><p dangerouslySetInnerHTML={sanitizedData(content)}></p></div>}
                </div>

                <div class="container" style={{marginTop: "20px"}}>
                    <div class="row">
                    {constituency.map((item, i) => (
                             <div class="col">   <ListGroup.Item key={i} action eventKey={i} onClick={() => handleSetSubId(item, i)} style={subApplicationId == item.id ? { textAlign: 'center', fontSize: "24px", padding: "10px 30px", background: "rgb(2,0,36)", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)", color: "white", fontWeight: "bolder" } : { textAlign: 'center', fontSize: "24px", padding: "10px 30px" }}>
                                    {item.name}
                                </ListGroup.Item></div>
                            ))}
                    </div>
                </div>

                <div className='row px-4 m-0' >
                    {/* <div style={{ display: "flex", marginTop: "20px" }}>
                        <ListGroup horizontal  >
                            {constituency.map((item, i) => (
                                <ListGroup.Item key={i} action eventKey={i} onClick={() => handleSetSubId(item, i)} style={subApplicationId == item.id ? { textAlign: 'center', fontSize: "24px", padding: "10px 30px", background: "rgb(2,0,36)", background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)", color: "white", fontWeight: "bolder" } : { textAlign: 'center', fontSize: "24px", padding: "10px 30px" }}>
                                    {item.name}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div> */}
                    <EventCard subapllicationid={subApplicationId} />
                    <News subapllicationid={subApplicationId} />
                    <Youtube subapllicationid={subApplicationId} />
                </div>
                <TeamSlider subapllicationid={subApplicationId} />




                {/* <SliderImages /> */}
                {/* <SliderImages /> */}
                {/* <SliderImages /> */}


                <div className='row px-4 mx-0' >
                    <div className='col-md-12 col-lg-6 text-center '>
                        <h2 className='my-3'>Twitter Updates</h2>
                        <iframe src={`https://widgets.sociablekit.com/twitter-feed/iframe/${twitterLink}`} frameborder='0' width='100%' height='600'></iframe>
                    </div>
                    <div className='col-md-12 col-lg-6 text-center'>
                        <h2 className='my-3'>Facebook Updates</h2>
                        <iframe
                            src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${fbLink}%2F&tabs=timeline&width=500&height=550&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId `}

                            width='100%' height='600'
                            style={{
                                border: "none",
                                overflow: "hidden",
                            }}

                            frameborder="0"
                            allowfullscreen="true"
                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        ></iframe>
                    </div>
                </div>


                {/* </div>} */}
                <br />
                <FooterTwo />




            </Styles>
        </div>
    )
}

export default PartnerOnBoard