import React, { useEffect, useState } from 'react';
import { Dropdown, Image, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoginModal from '../components/LoginModal';
import Register from '../pages/account/Register';
import { GetApi, PostApi } from '../services/CommonService2';
import { FaSearch } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import "./home3.css"
function Header3() {
    const [logo, setLogo] = useState([])
    const [headerTabs, setHeaderTabs] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [user, setUser] = useState("")
    const [userMobile, setUserMobile] = useState("")

    const [toggleUser, setToggleUser] = useState(false)
    const [postModal, setPostModal] = useState(false);
    const [userProfile, setUserProfile] = useState("");
    const [courses, setCourses] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [notificationmodal, setNotificationmodal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [tenantdata, setTenantData] = useState({});
    const [loginModal, setLoginModal] = useState(false);
    const [modalUp, setModalUp] = useState(false);
    const [selectedState, setSelectedState] = useState([])
    const [searchValue, setSearchValue] = useState('')
const [mobiletoggle, setMobileToggle] = useState(false)


    const [offlineModalShow, setOfflineModalShow] = useState(false)
    const [isOpenFlag, setIsOpenFlag] = useState(false);

    const handleMouseEnterFlag = () => {
        setIsOpenFlag(true);
    };

    const handleMouseLeaveFlag = () => {
        setIsOpenFlag(false);
    };
    // var headerURL = ""

    useEffect(() => {

        setToggleUser(prevState => !prevState)
    }, [])



    const openRegisterModal = () => {
        setPostModal(true)
        // onClose()
    }
    const closemodal = () => {
        console.log("executing")
        setPostModal(false)
        setModalShow(true)

    }




    useEffect(() => {
        getLogo()
        headerTypes()
        getTrainingRegistrationData()

        // console.log("local storage data", JSON.parse(localStorage.getItem('userdata')));

        // localStorage.removeItem('userdata')
        var tntId = JSON.parse(localStorage.getItem('tID'))

        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))

        if (userdata == null) {
            setUser("")
        }
        else if (userdata.accessToken) {
            setUser(userdata.user.name)
            setUserProfile(userdata.user.image)
            setUserMobile(userdata.user.mobileno)
            setSelectedState(userdata.user.stateid)

        }

    }, [toggleUser])



    const getLogo = async () => {
        const action = "INTRO"
        const reqparam = {
            pageno: '-1',
            query: { status: 'Active' }
        }

        const ResponseData = await GetApi(reqparam, action);
        // console.log("intro response", ResponseData);
        setLogo(ResponseData?.data);


        const reqparam2 = {
            pageno: '-1',
            query: {}
        }

        const ResponseData1 = await GetApi(reqparam2, 'TENANT');
        // console.log("response tenant data", ResponseData1.data[0]);
        // alert()
        setTenantData(ResponseData1.data[0])
        var tntId = JSON.parse(localStorage.getItem('tID'))
        localStorage.setItem(`tenantdata${tntId}`, JSON.stringify(ResponseData1?.data[0]));

        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }

        // adding favcon
        link.href = ResponseData?.data[0]?.smallicon;
        console.log(ResponseData1, "check tenant name");
        //adding document title
        document.title = ResponseData1?.data[0]?.name



    }

    const headerTypes = async () => {
        let reqparams = ''
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        if (userdata?.user?.subapplicationid && userdata != null) {
            reqparams = {
                pageno: '-1',
                query: {
                    status: 'Active', area: {
                        $elemMatch: { "value": userdata?.user?.subapplicationid }
                    }
                },

            }

        } else {
            reqparams = {
                pageno: '-1',
                query: { status: 'Active' },

            }

        }

        // console.log("header reqparams", reqparams);

        const dataResponse = await PostApi(reqparams, 'VPTTYPES');

        console.log("VPT headers", dataResponse.data);
        setHeaderTabs(dataResponse?.data)
    }


    const openModal = () => {
        setModalOpen((prevState) => !prevState);
        // setModalShow((prevState) => !prevState);
    };

    const logIn = () => {
        setToggleUser(prevState => !prevState)

    }
    const logOut = async (e) => {
        // await removeToken()
        e.preventDefault()
        setShowModal(true)



        // openModal()
        // setToggleUser(prevState=>!prevState)

    }

    //logout confirmation

    const exitmodal = () => {

        setShowModal(false)

    }

    const logoutConfirmed = async () => {

        // console.log("userMobile",userMobile);

        const reqparams = {
            mobileno: userMobile
            // mobileno:"9391645909"

        }
        const clearsessionrespone = await PostApi(reqparams, 'LOGOUT');
        // console.log("clearsessionrespone", clearsessionrespone);
        var tntId = JSON.parse(localStorage.getItem('tID'))

        localStorage.removeItem(`userdata${tntId}`)

        logIn()

        setShowModal(false)

        window.location.href = '/'
    }


    const getTrainingRegistrationData = async () => {
        const reqparams = {
            pageno: '-1',
            query: { status: 'Active' }
        }
        const courseslistResp = await PostApi(reqparams, 'TRAININGCOURSES');

        // console.log("TRAININGCOURSES datta", courseslistResp.data);

        let count = 0;
        const rowdat = [];
        await courseslistResp.data.reduce(async (promise, res) => {
            await promise;
            const opts = {
                key: res.id,
                value: res.name
            }
            rowdat.push(opts);
            if (count == courseslistResp.data.length - 1) {


                setCourses(rowdat)
            }
            count++;
        }, Promise.resolve());


    }

    const notifymodal = async () => {
        setNotificationmodal(true)

    }
    const closeNotifyModal = async () => {
        setNotificationmodal(false)
    }

    const clsDeleteModal = async () => {
        setDeleteModal(false)
    }
    const deleteAccount = async () => {
        setDeleteModal(true)

    }
    const deactivateConfirmed = async () => {
        const query = {
            userid: '',
            tenantid: ''
        }
        const deleteResp = await PostApi(query, "DELETEACCOUNT");
        // console.log("deleteResp",deleteResp);
        if (deleteResp.message == 'SUCCESS') {
            var tntId = JSON.parse(localStorage.getItem('tID'))

            localStorage.removeItem(`userdata${tntId}`)
            logIn()
            setDeleteModal(false)
            // history.push(`${"/"}`)
            window.location.href = '/'

        }
        else {
            toast.error("Failed to delete account", {
                position: "center",
                autoClose: 5000,

            })
        }

    }
    const AcademicValidate = async (e) => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        if (userdata == null) {
            setModalUp(true)
            e.preventDefault()

            toast.info('login to check course details', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const closeModal = async () => {
        setModalUp(false)

    }
    const signIn = async () => {

    }

    const loginValidate = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        if (userdata == null) {
            setModalOpen((prevState) => !prevState)


            toast.info('login to register for Offline Enrollment', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
    }
    return (
        <>


            <div className='row p-0 m-0 bg-light devops-header-main'>
                <div className='col-2'>
                    <Link to={"/"}><img src={`${logo[0] && logo[0]?.largeicon}`} alt="" /></Link>
                </div>
                <div className='col-4 d-flex justify-content-between' style={{ margin: "auto 0px" }}>
                    <div><Link to="/"><button className='btn btn-primary py-2 fs-5'>Home</button></Link> </div>
                    <div><Link to="/course-list"> <button className='btn btn-primary py-2 fs-5'>All Programs</button></Link></div>
                    {user && <div><Link to="/MyCourses"> <button className='btn btn-primary py-2 fs-5'>My Programs</button></Link></div>}
                </div>
                <div className='col-4 my-auto'>
                    {/* <div class="input-group " >
  
  <input type="text" class="form-control py-3" placeholder='Search Program' onChange={handleSearchChange}  />

  <Link class="input-group-text fs-2 text-light bg-primary" style={{cursor:"pointer"}}  to={{ pathname: "/course-list", state: {name: searchValue} }}>
  <FaSearch  /></Link>
</div> */}

                </div>

                {!user && <div className='col-2 d-flex justify-content-end '>
                    <div className=' my-auto '>
                        <button type="button" class="btn btn-primary py-2 fs-5 mx-3" onClick={openModal}>Sign In</button>
                    </div>
                    <div onClick={openRegisterModal} className=' my-auto '>
                        <button type="button" class="btn btn-outline-primary py-2 fs-5 mx-3">Sign up</button>
                    </div></div>}
                {modalOpen && <LoginModal onClose={openModal} show={modalShow} Hide={modalOpen} login={logIn} />}

                {user && <div className='col-2 d-flex justify-content-end my-auto'>
                    <Dropdown style={{ width: "auto" }}>
                        <Dropdown.Toggle id="dropdown-basic" className='login-div d-flex justify-content-evenly ' style={{ backgroundColor: "white" }}>
                            {/* {user} */}
                            {/* {console.log("user profile value",userProfile)} */}
                            <Image src={userProfile ? `data:image/jpeg;base64,${userProfile}` : "https://tse3.mm.bing.net/th?id=OIP._lZHLa4KljgIGbchN7qRTgHaHa&pid=Api&P=0&h=180"} roundedCircle height="40px" width="40px" />
                            <span className='mx-2 fs-4 my-auto' style={{ color: "black" }}>{user}</span>

                        </Dropdown.Toggle>

                        <Dropdown.Menu >
                            {/* <Dropdown.Item href="#/action-1">{user}</Dropdown.Item> */}
                            {tenantdata.tenantid != "80b42fdf" && <Link to={`${"/about"}`}>    <Dropdown.Item href="#/action-2" className='profile profile2 fs-5'>My Profile</Dropdown.Item> </Link>}

                            <Link to={`${"/changepassword"}`}>    <Dropdown.Item href="#/action-2" className='profile profile2 fs-5'>Change Password</Dropdown.Item> </Link>
                            <Dropdown.Item className='profile fs-5' href="#" onClick={deleteAccount}>Deactivate account</Dropdown.Item>
                            <Dropdown.Item className='profile fs-5' href="#" onClick={logOut}>Log out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown></div>
                }

            </div>

            <div className='devops-header-mobile'>
                <div className='d-flex justify-content-between p-3'>
                    <div >
                        <Link to={"/"}><img src={`${logo[0] && logo[0]?.largeicon}`} alt="" className='w-25' /></Link>
                    </div>
                    <div>
                        {mobiletoggle ? "": <GiHamburgerMenu className='fs-1' onClick={()=>setMobileToggle(true)} />}
                        
                    </div>
                </div>
                <div className={mobiletoggle ? "devops-header-mobile-menu": "devops-header-mobile-menu-off"}>
                    
                    <div className='d-flex justify-content-between p-3' >
                        {user && <div className=' '>
                            <Dropdown style={{ width: "auto" }}>
                                <Dropdown.Toggle id="dropdown-basic" className='login-div d-flex justify-content-evenly ' style={{ backgroundColor: "white" }}>
                                    {/* {user} */}
                                    {/* {console.log("user profile value",userProfile)} */}
                                    <Image src={userProfile ? `data:image/jpeg;base64,${userProfile}` : "https://tse3.mm.bing.net/th?id=OIP._lZHLa4KljgIGbchN7qRTgHaHa&pid=Api&P=0&h=180"} roundedCircle height="40px" width="40px" />
                                    <span className='mx-2 fs-4 my-auto' style={{ color: "black" }}>{user}</span>

                                </Dropdown.Toggle>

                                <Dropdown.Menu >
                                    {/* <Dropdown.Item href="#/action-1">{user}</Dropdown.Item> */}
                                    {tenantdata.tenantid != "80b42fdf" && <Link to={`${"/about"}`}>    <Dropdown.Item href="#/action-2" className='profile profile2 fs-5'>My Profile</Dropdown.Item> </Link>}

                                    <Link to={`${"/changepassword"}`}>    <Dropdown.Item href="#/action-2" className='profile profile2 fs-5'>Change Password</Dropdown.Item> </Link>
                                    <Dropdown.Item className='profile fs-5' href="#" onClick={deleteAccount}>Deactivate account</Dropdown.Item>
                                    <Dropdown.Item className='profile fs-5' href="#" onClick={logOut}>Log out</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown></div>
                        }
                        {!user && <div className=''>
                            <div className=' my-auto '>
                                <button type="button" class="btn btn-primary py-2 fs-5 mx-3" onClick={openModal}>Sign In</button>
                            </div>
                        </div>}
                        <IoMdClose className='fs-1 text-light' onClick={()=>setMobileToggle(false)} />
                    </div>
                    <div className='d-flex flex-column px-5 py-2'>
                            <div className='my-2 py-2 text-center mx-5' style={{borderBottom:"1px solid white"}} ><Link className="text-light fs-4 " to="/">Home</Link></div>
                            <div className='my-2 py-2 text-center mx-5'style={{borderBottom:"1px solid white"}}><Link className="text-light fs-4 " to="/course-list">All Programs</Link></div>
                            {user &&   <div className='my-2 py-2 text-center mx-5' style={{borderBottom:"1px solid white"}}><Link className="text-light fs-4 " to="/MyCourses">My Programs</Link></div>}
                    </div>
                    </div>

                </div>
            <Modal size="l" show={postModal} >
                <div className="" role="document">
                    <div className="">
                        <form >
                            <div className="modal-header">

                                <button type="button" className="btn-close" onClick={closemodal} data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <Register closemodal={closemodal} loginmodal={openModal} />
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>
            <Modal size="l" show={showModal} >
                <div className="" role="document">
                    <div className="">
                        <form >
                            <div className="modal-header">

                                <button type="button" className="btn-close" onClick={exitmodal} data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body text-center">
                                <h5>Are you sure you want to logout ?</h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={logoutConfirmed}>ok</button>
                                <button type="button" className="btn btn-secondary" onClick={exitmodal} data-dismiss="modal">cancel</button>
                            </div>
                        </form>

                    </div>
                </div>


            </Modal>

            <Modal size="l" show={deleteModal} >
                <div className="" role="document">
                    <div className="">
                        <form >
                            <div className="modal-header">

                                <button type="button" className="btn-close" onClick={clsDeleteModal} data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body text-center">
                                <h5>Are you sure you want to deactivate your account , Once you deactivate, you cannot login again  ?</h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={deactivateConfirmed}>ok</button>
                                <button type="button" className="btn btn-secondary" onClick={clsDeleteModal} data-dismiss="modal">cancel</button>
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>
            {modalUp && <LoginModal onClose={closeModal} login={signIn}></LoginModal>}
        </>
    )
}

export default Header3