import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetApi, PostApi } from '../services/CommonService2.js';
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { CiLinkedin } from "react-icons/ci";
import { TfiYoutube } from "react-icons/tfi";
function Footer3() {

  const [socialLinks, setSocialLinks] = useState([]);
  const [settings, setSettings] = useState([]);
  const [logo, setLogo] = useState([]);
  const [tenant, setTenant] = useState(false)
  const [tntId, setTntId] = useState('');
  const [address, setAddress] = useState([]);
  const [tenantData, getTenantData] = useState([])

  useEffect(() => {
    loadData()
    getLogo()
    getTenant()
    setTntId(JSON.parse(localStorage.getItem('tID')))
    getFooter()



  }, [])
  const getTenant = async () => {
    const reqparam = {
      pageno: '-1',
      query: {}
    }
    const tenantresp = await PostApi(reqparam, 'TENANT');
    console.log("footer tenantresp", tenantresp);
    // console.log("footerrrr ",tenantresp?.data[0].tenantid);
    getTenantData(tenantresp?.data[0])

    if (tenantresp?.data[0].tenantid == "526daf25") {
      setTenant(true)

    }

  }


  const loadData = async () => {
    const reqparams = {
      pageno: 1,
      query: {}

    }
    const respData = await PostApi(reqparams, 'SOCIALLINK');
    const respData2 = await PostApi(reqparams, 'SETTING');
    setSocialLinks(respData.data.docs);
    setSettings(respData2.data.docs.filter((ele) => { return ele.actiontype != 'About Constituency' && ele.actiontype != 'About Leader' }))
    // console.log("settings footer!!!!!!!!!!!!!!!!!!!!!!!!",respData2.data);
  }


  const getLogo = async () => {
    const action = "INTRO"
    const reqparam = {
      pageno: '-1',
      query: { status: 'Active' }
    }

    const ResponseData = await GetApi(reqparam, action);
    setLogo(ResponseData?.data);

  }
  const getFooter = async () => {
    const reqparam = {
      pageno: '-1',
      query: {},
    }
    const tenantresp = await PostApi(reqparam, 'TENANT');
    // console.log("footer resp===", tenantresp.data[0].tenantotherinfo);
    setAddress(tenantresp?.data[0]?.tenantotherinfo)

  }
  return (
    <>
      <div className='bg-dark pb-5 pt-4'>
        
          {socialLinks.map((item, inx) => {
            return (
              <div className=' d-flex justify-content-center py-5 '>
               
                <a href={item.fblink}> <FaFacebook className='text-primary fs-1 mx-1' /></a>
                <a href={item.instalink}><FaInstagram className='text-danger fs-1 mx-1' /></a>
                <a href={item.twitterlink}> <FaTwitterSquare className='text-primary fs-1 mx-1' /></a>
                <a href={item.linkedinlink}> <CiLinkedin className='text-primary fs-1 mx-1' /></a>
                <a href={item.youtubelink}> <TfiYoutube className='text-danger fs-1 mx-1' /></a>
                </div>
            )
          })}
        
        <div className='row px-5 m-0 ' >
          <div className='col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center mt-4'>
            <div>    <div><Link to={"/"}><img src={`${logo[0] && logo[0].smallicon}`} alt="" className="img-fluid" width={120} /></Link></div>
            </div>

            {/* <div className='mx-5'>
              <h3 className='text-light fs-2 mb-2 fw-bold'>Quick Links</h3>
              <p className='fs-5 text-light'>About Us</p>
              <p className='fs-5 text-light'>FAQ</p>

            </div> */}
          </div>
          <div className=' col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center mt-4'>
            <div className='mx-5'>
            <div className="f-links">
                                {settings && <h5 className='text-light fs-2 mb-2 fw-bold'>Quick Links</h5>}
                              
                                    {settings?.map((item, index) => (
                                        <p key={index}>
                                           <Link  to={{ pathname: `/Settings/${item.actiontype}`, state: item }} className="custom-link fs-5 text-light">
                                                {item.actiontype.charAt(0).toUpperCase() + item.actiontype.slice(1)}
                                            </Link><br />
                                        </p>
                                    ))}
                            </div>

            </div>
          </div>

          <div className='col-sm-12 col-md-12 col-lg-4 d-flex justify-content-end mt-4'>
            <div className='mx-5'>
              <h3 className='text-light fs-2 mb-2 fw-bold'>Have questions?</h3>
              <p className='fs-5 text-light mb-1'>{address?.mobilenumber} </p>
              <p className='fs-5 text-light mb-1'>{address?.supportemail}</p>
              <p className='fs-5 text-light mb-1'>{address?.contactaddress}</p>


            </div>
          </div>
        </div>
        <hr />
        <div className='d-flex justify-content-center p-3'>
          <p className='fs-5 text-light'>© 2023 - DevOps-Online-Hub All Rights Reserved.</p>
        </div>
      </div>
    </>
  )
}

export default Footer3