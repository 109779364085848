import React from 'react'
import "./questionbar.css"
import { FaRegFileAlt } from "react-icons/fa";
import { FaCircle } from "react-icons/fa6";
function QuestionItem() {
  return (
    <div className='p-2'>
        <div className='question-item row m-0 p-1'>
        <div className='col-2 d-flex justify-content-end text-right align-items-center '><FaRegFileAlt className='fs-3 text-light' /></div>
        <div className='col-8 fs-5  text-light'>find the index of sub array with given sum</div>
        <div className='col-2 d-flex justify-content-start text-right align-items-center '><FaCircle className=' text-light' /></div>
    </div>
    </div>
  )
}

export default QuestionItem