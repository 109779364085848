import React from 'react'
import { GetEvents } from './event-service'
import { useState, useEffect } from "react";
import { PostApi } from '../../services/CommonService2';

function EventCard(props) {
    const [eventData, seteventData] = useState([])
    useEffect(() => {
        getEventsData()
    },[props.subapllicationid])

    const getEventsData = async () => {
        const reqdata = {
            pageno: '-1',
            query:{status:'Active'},
          

        }
        const response = await PostApi(reqdata, 'EVENTS',props.subapllicationid)
        // console.log('events',response.data);
        seteventData(response.data)
    }
    return (
        <div className='col-sm-12 col-md-6 col-lg-4 my-5'>
            <div className="card h-100">
                <div className="card-header bg-success p-4">
                    <p className='text-light fs-2'><b>Events</b></p>
                </div>
                <ul className="list-group list-group-flush">
                {
                eventData.map(ele=>{
                    return(
                    <>
                    <div className='eventDiv'>
                    <li className="list-group-item list-group-item-action py-2 px-5"><p className='fs-5'>{ele.name}</p></li>
                    </div>
                    {/* <li className="list-group-item list-group-item-action py-2 px-5"><p className='fs-2'>{ele.name}</p></li>
                    <li className="list-group-item list-group-item-action py-2 px-5"><p className='fs-2'>{ele.name}</p></li> */}
                    </>
                    )
                })
               }
                   
                </ul>
            </div>
        </div>
    )
}

export default EventCard