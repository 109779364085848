import React, { useEffect, useState } from 'react';
// import Datas from '../data/about-us/about-us2.json';
// import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/aboutUsTwo.js";

import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';
import { PostApi } from '../services/CommonService2.js';
import Testrimonial from './testrimonial/Testrimonial.jsx';


function AboutUsTwo() {

	const [eduData, setEduData] = useState([])
	const [eduTypes, setEduTypes] = useState([])
	const [tenantData, settenantData] = useState("");
	// const [edutypeId,setEdutypeId]=useState(null)

	useEffect(() => {
		getBtech()
	}, [])


	const getBtech = async () => {
		var edutypeId = null;
		var tntId = JSON.parse(localStorage.getItem('tID'))
		const sessiondetails = localStorage.getItem(`userdata${tntId}`);
		const localUserdata = JSON.parse(sessiondetails);

		if (sessiondetails != null) {
			const localUserdata = JSON.parse(sessiondetails);
			console.log(localUserdata.user.studentedutype, "check About us")
			if (localUserdata.user.studentedutype == "btech") {
				edutypeId = "1"
			} else if (localUserdata.user.studentedutype == "degree") {
				edutypeId = "2"
			} else if (localUserdata.user.studentedutype == "pg") {
				edutypeId = "3"
			}
		}
		settenantData(tntId)
		let reqparams = ''

		if (localUserdata?.user.subapplicationid  && localUserdata!=null) {
			reqparams = {
				pageno: '-1',
				query: {
					status: 'Active',
					area: {
						$elemMatch: { "value": localUserdata?.user?.subapplicationid }
					}
				},
			}

		}
		else {
			reqparams = {
				pageno: '-1',
				query: { status: 'Active' },
			}

		}



		const dataResponse = await PostApi(reqparams, 'EDUCATION');
		console.log("The education response is",dataResponse?.data);
		if (edutypeId == null) {
			if (sessiondetails != null) {
				const filterEdu = dataResponse?.data.filter(ele => ele.educationcode == "1")
				setEduData(filterEdu)
			} else {
				setEduData(dataResponse?.data)
			}
		} else {
			const filterEdu = dataResponse?.data.filter(ele => ele.educationcode == edutypeId)

			setEduData(filterEdu)
		}


		const reqparams2 = {
			pageno: '-1',
			query: { status: 'Active' }
		}
		const dataResponse1 = await PostApi(reqparams2, 'EDUCATIONTYPES');
		setEduTypes(dataResponse1?.data)


	}
	return (
		<Styles>
			{eduData.map((data, ind) => {
				return (<section className="plans-main-block" key={ind}>
					<div className="container">
						<div className="row">
							<div className="col-md-12 text-center">
								<h3 className="main-title">{data.name}</h3>
								<p className="description px-xl-5">{data.description.slice(0, 100)}</p>
							</div>

							<div className="clearfix"></div>

							{/* <div className="row d-flex justify-content-center">
								{eduTypes.map((type,idx) => {
									if (type.educationid == data.id) {
										return <div className="col-xl-3 col-lg-6" key={idx}>
											<Link to={{
												pathname:"/stuprogram-grid",
												state:type
											}} ><div className="plan-block" style={{textAlign:"center"}}>
											{type.name.split(" ")[0]!="Post"? [<span style={{margin:"5px"}}> {type.name.split(" ")[0] } </span>,<span style={{margin:"5px"}}> {type.name.split(" ").map((ele,ind)=>{
												if(ind > 0){
													return ele
												} 
											})} </span> ]:[<span style={{margin:"5px"}}> {type.name.split(" ")[0] } {type.name.split(" ")[1] } </span>,<span style={{margin:"5px"}}> {type.name.split(" ").map((ele,ind)=>{
												if(ind > 1){
													return ele
												} 
											}) } </span>]}
												
											</div></Link>
										</div>
									}
								})}
							</div> */}
							<div className="row d-flex justify-content-center">
								{eduTypes.map((type, idx) => {
									if (type.educationid == data.id) {
										return <div className="col-xl-3 col-lg-6" key={idx}>
											<Link to={{
												pathname: "/stuprogram-grid",
												state: type
											}} ><div className="plan-block" style={{ textAlign: "center" }}>
													{type.name.split(" ")[0] != "Post" ? [<span style={{ margin: "5px" }}> {type.name.split(" ")[0]}  </span>, <span style={{ margin: "5px" }}> {type.name.split(" ").map((ele, ind) => {
														if (ind > 0) {
															return ele
														}
													})} </span>] : [<span style={{ margin: "5px" }}> {type.name.split(" ")[0]} {type.name.split(" ")[1]} </span>, <span style={{ margin: "5px" }}> {type.name.split(" ").map((ele, ind) => {
														if (ind > 1) {
															return ele
														}
													})} </span>]}

												</div></Link>
										</div>
									}
								})}
							</div>
						</div>
					</div>
				</section>)

			})}
			<div className='mt-5 mb-5'>
				{tenantData == "526daf25" && <Testrimonial />}


			</div>
		</Styles>
	)
}

export default AboutUsTwo;