import React, { Component, useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import CourseSidebar from './components/CourseSidebar';
import CourseItemList from './components/CourseItemsList';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/course.js';
import { GetApi, PostApi } from '../../services/CommonService2';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ExamsList from './components/ExamsList.js';
import CompetitiveSidebar from './components/CompetitiveSidebar.js';


const CompetitiveExams = () => {

    const [courseList, setCourseList] = useState([]);
    const [course, setCourse] = useState([]);
    const [category, setCategory] = useState([]);
    const [updatedCategory, setUpdatedCategory] = useState([])

    const didMountRef = useRef(false);
    const didMountRef2 = useRef(false);

    const [categorySelect, setCategorySelect] = useState([])

    const [searchKey, setSearchKey] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(1)
    const [totalRecords, setTotalRecords] = useState(null)

    const location = useLocation();



    useEffect(() => {

        if (course.length != 0) {

            setCategorySelect([])
        }
        setSearchKey("")

    }, [location])

    useEffect(() => {
        getCourses();
        getCategory();
        getAllCourse();
    }, [])


    useEffect(() => {

        let arr = category.map((item) => {
            return {
                ...item,
                check: false
            }
        })

        // console.log("arr mate", arr);
        setUpdatedCategory(arr)
        // setUpdatedCategory2(arr)

    }, [category])





    const getCourses = async () => {
        var reqparam = {
        }

        if (location.state?.examname != undefined) {

            reqparam = {
                pageno: 1,
                query: { status: 'Active' },

            }
            reqparam.query.examname = { $regex: location.state.examname, $options: "i" }



        }
        else {
            reqparam = {
                pageno: 1,
                query: { status: 'Active' },

            }
        }
        // console.log("runnnnnnh");


        const ResponseData = await GetApi(reqparam, 'COMPETITIVEEXAM');

        setCourseList(ResponseData.data.docs);
        setTotalRecords(ResponseData.data.totalDocs)

    }

    const getAllCourse = async () => {
        const reqparam = {
            pageno: currentPage,
            // page:currentPage,
            query: { status: 'Active' },

        }

        const ResponseData = await GetApi(reqparam, 'COMPETITIVEEXAM');
        // console.log("course resp",ResponseData.data);
        setCourse(ResponseData.data.docs)
        setCount(ResponseData)
    }

    const getCategory = async () => {
        const reqparam = {
            pageno: '-1',
            query: { status: 'Active' }
        }
        const action = "EXAMTYPE"

        const ResponseData = await PostApi(reqparam, action);
        setCategory(ResponseData.data)

    }




    async function ctgSearch(e, data) {
        console.log("e",e.target);
        if (e == "All") {
            setCategorySelect([])
            setSearchKey("")
        } else {
            const { checked } = e.target

            if (checked) {
                setSearchKey("")
                if (categorySelect.length < 0) {
                    setCategorySelect([data])
                }

                else {
                    setCategorySelect((prevState) => {
                        return [...prevState, data]
                    })
                }
            }
            else {
                setCategorySelect(categorySelect.filter((item) => item != data))
            }
        }


    }

    useEffect(() => {

        if (didMountRef.current) {

            const categorySelectCheck = categorySelect.filter(t => t != null);

            const ctgbasedsearch = async () => {

                const reqparams1 = {
                    pageno: 1,
                    query: { status: 'Active' },

                }
                reqparams1.query.examtype = categorySelectCheck;
                const response = await GetApi(reqparams1, 'COMPETITIVEEXAM');

                console.log("category resp",response.data.docs);

                if (response.data.docs.length > 0) {
                    setCourseList(response.data.docs);
                    setTotalRecords(response.data.totalDocs)
                    setCurrentPage(1);
                } else {
                    setCourseList([]);
                    setTotalRecords(0)
                    setCurrentPage(1)
                }
                // setCourse(response.data)
                const updatedTypesWithCheck = updatedCategory.map((type) => ({
                    ...type,
                    check: categorySelect.some((categoryId) => categoryId === type.id),
                }));

                setUpdatedCategory(updatedTypesWithCheck)


            }

            if (categorySelectCheck.length != 0) {
                ctgbasedsearch()
                // console.log("serching")
            }
            else {

                if (searchKey == '') {
                    setCourseList(course)
                    setTotalRecords(count.totalDocs)
                    setCurrentPage(1)


                    const updatedTypesWithCheck = updatedCategory.map((type) => ({
                        ...type,
                        check: false,
                    }));

                    setUpdatedCategory(updatedTypesWithCheck)

                }
            }

        }
        else {
            didMountRef.current = true;
        }

    }, [categorySelect])

    //search function

    const searchBar = async (keySearch) => {
        setSearchKey(keySearch)

        const reqparam = {
            pageno: 1,
            query: { status: 'Active' },


        }
        reqparam.query.examname = { $regex: keySearch, $options: "i" }
        const ResponseData = await GetApi(reqparam, 'COMPETITIVEEXAM');
        if (ResponseData.data.docs.length > 0) {
            setCourseList(ResponseData.data.docs);
            setTotalRecords(ResponseData.data.totalDocs)

            setCategorySelect([])
        } else {
            setCourseList([]);
            setTotalRecords(0)

        }

        setCurrentPage(1)
        const updatedTypesWithCheck = updatedCategory?.map((type) => ({
            ...type,
            check: false,
        }));

        setUpdatedCategory(updatedTypesWithCheck)
    }

    useEffect(() => {


        const newData = async () => {
            const reqparam = {
                pageno: 1,
                query: { status: 'Active' }
            }

            if (categorySelect.length != 0) {
                reqparam.query.category = categorySelect;
            }
            else if (searchKey != "") {
                reqparam.query.examname = { $regex: searchKey, $options: "i" }

            }

            const ResponseData = await GetApi(reqparam, 'COMPETITIVEEXAM');
            setCourseList(ResponseData.data.docs);
            setTotalRecords(ResponseData.data.totalDocs)


        }
        // console.log("didMountRef.current",didMountRef.current);
        if (didMountRef2.current) {
            newData();
        }
        else {
            didMountRef2.current = true;
        }





    }, [currentPage])


    return (
        <div className="main-wrapper course-page">
            {console.log("course data", courseList)}
            {/* Header 2 */}
            <HeaderTwo />

            {/* Breadcroumb */}
            {/* <BreadcrumbBox title="Courses" /> */}

            <Styles>
                {/* Course Grid */}
                <section className="course-grid-area">
                    <Container>
                        <Row>
                            <Col lg="3" md="4" sm="5">
                                <CompetitiveSidebar
                                    updatedCategory={updatedCategory}
                                    searchBar={searchBar}
                                    ctgFn={ctgSearch}
                                />
                            </Col>
                            <Col lg="9" md="8" sm="7">
                                <div className="course-items">
                                    <Row>
                                        {/* {console.log("actual data",categoryData)} */}
                                        <ExamsList
                                            courseList={courseList}
                                            course={course}
                                            totalRecords={totalRecords}
                                            setCurrentPage={setCurrentPage}
                                            currentPage={currentPage}

                                        />
                                        {/* currentPage={currentPage} */}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            <FooterTwo />

        </div>
    )


}

export default CompetitiveExams