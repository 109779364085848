import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import CourseSearch from './CourseSearch';
import StateCategory from './StateCategory';


const StateSidebar=(props)=>{
   
        return (
            <div className="course-sidebar">
                <Row>
                    <Col md="12">
                        <CourseSearch setSearchData={props.setSearchData} />
                    </Col>
                    
                    {/* <Col md="12">
                        <StateCategory/>
                    </Col> */}
                    {/* <Col md="12">
                        <CoursePrice />
                    </Col>
                    <Col md="12">
                        <PopularCourse />
                    </Col>
                    <Col md="12">
                        <CourseTag />
                    </Col> */}
                </Row>
            </div>
        )

    
}

export default StateSidebar
