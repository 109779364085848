import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import CourseSearch from './CourseSearch';
import CoursePrice from './CoursePrice';
import PopularCourse from './PopularCourse';
import CourseTag from './CourseTag';
import CourseCategory from './CourseCategory';
import StProgramCategory from './StProgramCategory';
import ProgramSearch from './ProgramSearch';

const StProgramSidebar =(props)=> {

        return (
            <div className="course-sidebar">
                <Row>
                    <Col md="12">
                        {/* <CourseSearch searchData={props.searchData}/> */}
                        <ProgramSearch searchData={props.searchData}/>
                    </Col>
                    <Col md="12">
                        {/* <CourseCategory /> */}
                        <StProgramCategory types={props.types} 
                        ctgFn={props.ctgFn} 
                        dispAllPg={props.dispAllPg}
                        ></StProgramCategory>
                    </Col>
                    {/* <Col md="12">
                        <CoursePrice />
                    </Col>
                    <Col md="12">
                        <PopularCourse />
                    </Col>
                    <Col md="12">
                        <CourseTag />
                    </Col> */}
                </Row>
            </div>
        )
    }


export default StProgramSidebar
