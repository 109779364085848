import React, { useEffect, useState } from 'react'
import { PostApi } from '../services/CommonService2';
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Styles } from './profile/education';

function Notifications() {
    useEffect(() => {
        getNotifications()


    }, [])

    const [data, setData] = useState([]);
    const [content, setContent] = useState(undefined);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const getNotifications = async () => {
        const reqdata = {
            userid: '',
            tenantid: ''
        }
        // console.log("reqdata", reqdata);

        const response = await PostApi(reqdata, 'GETNOTIFICATIONS');
        // console.log("responsee notifications", response);
        if (response.data.length>0) {
            const { data: notifications, total_pages: total } = response;
            // console.log("notifications",notifications,total);
            if (page === 1) {
                setData(notifications);
            } else {
                setData(data.concat(notifications));
            }
            setTotalPages(total);
            setContent(true)
        } else {
            setData([
                {
                    title: " New notification",
                    content: "New training batch starting on react js",
                    time: "25-12-2023"
                }
            ]);
            setContent(false)
        }

    }

    const sharecontent = async (item) => {
        await navigator.share({
            title: item.title,
            text: item.content
        });
    }
    return (
        <Styles>
        <Container className="main-div" style={{maxHeight:"560px",overflowY:"scroll", overflowX:"hidden"}}>
            <h3 className="tab-title text-center">Notifications</h3>
               
                {content && data.map((item) => {
                    return <>

                        <div className="card m-2" >
                           
                            <Row>
                                <Col>
                                    <div className="card-body" >
                                        <h5 className="card-title" >{item.title} <i class="fa-solid fa-share-nodes" onClick={() => sharecontent(item)}></i></h5>
                                        <p className="card-text">{item.content}</p>
                                        <p>{item.time}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                       




                    </>
                    



                })}

                {
                    !content && <h4 className="tab-title text-center" style={{textDecoration:"none"}}>No Notifications found</h4>
                }
            </Container>


        </Styles>
    )
}

export default Notifications