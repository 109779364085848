import React, { useEffect, useState } from "react";

import { ChatEngineWrapper, Socket, ChatFeed } from 'react-chat-engine'

const ChatEngine = props => {
    const [showChat, setShowChat] = useState(false)

    useEffect(() => {
        if (props?.visible) {
            setTimeout(() => {
                setShowChat(true)
            }, 500)
        }
    },[props])

    // console.log("ChatEngineWrapper=============",props?.user?.email, 'chatId',props?.chat?.id,"props?.visible",props?.visible,"key",process.env.REACT_APP_CE_PROJECT_ID);
    // alert(props?.user?.email)
    return (
        <div
            className='transition-5'
            style={{
                ...styles.chatEngineWindow,
                ...{ 
                    height: props?.visible ? '90%' : '0px',
                    zIndex: props?.visible ? '100' : '0',
                }
            }}
        >
           {
                showChat &&
                <ChatEngineWrapper>
                    <Socket 
                        offset={5.7}
                        projectID={process.env.REACT_APP_CE_PROJECT_ID}
                        userName={props?.user?.email}
                        userSecret={props?.user?.email}
                    />
                    <ChatFeed activeChat={props?.chat?.id} />
                </ChatEngineWrapper>
            } 
        </div>
    )
}

export default ChatEngine;

const styles = {
    chatEngineWindow: {
        width: '100%',  
        backgroundColor: '#fff',
    }
}
