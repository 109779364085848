import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    .service-area {
        padding: 56px 0 42px;

        .sec-title {
            h4 {
                color        : ${colors.blue};
                line-height  : 35px;
                font-weight  : 600;
                max-width    : 950px;
                margin       : auto;
                margin-bottom: 40px;

                @media(max-width: 575px) {
                    margin-bottom: 15px;
                    font-size: 20px;
                }
            }
        }

        .service-box {
            padding   : 35px 20px 20px;
            box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
            border-radius : 5px;
            margin-bottom: 30px;

            .box-icon {
                background  : ${colors.blue};
                height      : 36px;
                text-align  : center;
                margin-right: 20px;
                position    : relative;

                i {
                    font-size : 34px;
                    color     : #ffffff;
                    width     : 60px;
                    display   : inline-block;
                    position  : relative;
                    z-index   : 111;
                    margin-top: -8px;

                    @media(max-width: 991px) {
                        font-size: 24px;
                        width: 45px;
                    }

                    @media(max-width: 767px) {
                        font-size: 34px;
                        width: 60px;
                    }
                }

                &::before {
                    content            : "";
                    position           : absolute;
                    border-width       : 15px 30px;
                    border-style       : solid;
                    border-top-color   : transparent;
                    border-right-color : transparent;
                    border-bottom-color: ${colors.blue};
                    border-left-color  : transparent;
                    top                : -30px;
                    left               : 0;
                    z-index            : 1;

                    @media(max-width: 991px) {
                        border-width: 12px 23px;
                        top: -24px;
                    }

                    @media(max-width: 767px) {
                        border-width: 15px 30px;
                        top: -30px;
                    }
                }

                &:after {
                    content            : "";
                    position           : absolute;
                    border-width       : 15px 30px;
                    border-style       : solid;
                    border-top-color   : ${colors.blue};
                    border-right-color : transparent;
                    border-bottom-color: transparent;
                    border-left-color  : transparent;
                    bottom             : -30px;
                    left               : 0;
                    z-index            : 1;

                    @media(max-width: 991px) {
                        border-width: 12px 23px;
                        bottom: -24px;
                    }

                    @media(max-width: 767px) {
                        border-width: 15px 30px;
                        bottom: -30px;
                    }
                }

                @media(max-width: 991px) {
                    height: 22px;
                    margin-right: 10px;
                }

                @media(max-width: 767px) {
                    height: 36px;
                    margin-right: 20px;
                }
            }

            .box-title {
                margin-top: -15px;

                h6 {
                    color         : ${colors.black1};
                    text-transform: uppercase;
                    font-weight   : 600;
                    margin-bottom : 6px;

                    @media(max-width: 991px) {
                        font-size: 13px;
                        letter-spacing: 0;
                    }

                    @media(max-width: 767px) {
                        font-size: 16px;
                        letter-spacing: 0.3px;
                    }

                    @media(max-width: 575px) {
                        font-size: 15px;
                    }
                }

                p {
                    font-size: 14px;
                    color    : ${colors.text3};

                    @media(max-width: 991px) {
                        font-size: 13px;
                        letter-spacing: 0;
                    }

                    @media(max-width: 767px) {
                        font-size: 14px;
                        letter-spacing: 0.3px;
                    }
                }
            }

            @media(max-width: 991px) {
                padding: 25px 10px 8px;
            }

            @media(max-width: 767px) {
                padding: 35px 20px;
                margin-bottom: 25px;
            }
        }

        @media(max-width: 767px) {
            padding: 30px 0 15px;
        }
    }

    
body {
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: #5a5a5a;
    font-family: 'Poppins', sans-serif !important;
  }
  
  .navbar{
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  }
  
  .carousel-indicators{
      margin: 50px auto;
      background: #fff;
      width: 200px;
      border-radius: 50px;
      min-height: 55px;	
      align-items: center;
  }
  
  .carousel-indicators [data-bs-target]{
      width:18px;
      height:18px;
      border:1px solid #2C79FF;
      border-radius:50%;
  }
  
  .carousel-indicators .active{
      background:#2C79FF;
  }
 
  
  .why-Visualpath:before{
      background: url(../../images/circle-bg.svg) no-repeat top center;
      width: 100%;
      height: 1354px;
      content: '';
      position: absolute;
      top: -735px;
      left: 0;
      z-index: -1;
    
  }
  
  .why-Visualpath:after{
      background: url(../assets/images/fundamentals-bg.jpg) no-repeat top center;
      width: 100%;
      height: 1354px;
      content: '';
      position: absolute;
      top: -720px;
      left: 0;
      z-index: -2;
    
  }
  
  .main-title{
      font-size:40px;
      font-weight:600;
      color:#2C79FF;
      margin-bottom: 20px;
  }
  
  .description{
      font-size:16px;
      color:#494949;
      margin-bottom: 50px;
  }
  
  .why-Visualpath .col-block > div{
    //   border:1px solid #EAEAEA;
    //   border-radius:50%;
      width:110px;
      height:110px;
      background:#fff;
      display:block;
    //   margin:0 auto;
      display:flex;
      align-items:center;
      justify-content:Center;
      margin-bottom:10px;
  }
  
  .why-Visualpath .col-block h4{
      font-size:22px;
      font-weight:600;
      line-height: 33px;
      color:#494949
  }
  
  .why-Visualpath .col-block h4 span{
      color:#494949;
      font-weight:500;
      display:block;
  }
  
//   .line0{margin-top:10%;position:absolute;left:-25%; } 
//   .line1{margin-top:10%;position:absolute;left:-20px;} 
//   .line2{margin-top:10%;position:absolute;left: 20%; }
  .line3{margin-top:25%;position:absolute;left:-30%; } 
  .line4{margin-top:25%;position:absolute;left:-10px;} 
  .line5{margin-top:25%;position:absolute;left: 30%; }
  
  
  .plans-main-block{
      padding:80px 0;
  }
  
  .plan-block{
    //   background:linear-gradient(0deg, rgba(44, 121, 255, 0.95), rgba(44, 121, 255, 0.8)), url(../../images/4th-year.jpg);
      border-radius: 5px 50px;
      min-height:200px;
      display:flex;
      align-items:Center;
      justify-content:Center;
      font-size:26px;
      font-weight:600;
      color:#fff;
      cursor:pointer;
    //   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
      margin-bottom:25px;
      transition: 2s ease all;
    text-align: center;
//    background-image: url("%3Csvg width='243' height='267' viewBox='0 0 243 267' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M101.5 5.547C113.876 -1.59831 129.124 -1.59831 141.5 5.547L222.311 52.203C234.687 59.3483 242.311 72.5534 242.311 86.844V180.156C242.311 194.447 234.687 207.652 222.311 214.797L141.5 261.453C129.124 268.598 113.876 268.598 101.5 261.453L20.6895 214.797C8.31341 207.652 0.689453 194.447 0.689453 180.156V86.844C0.689453 72.5534 8.31341 59.3483 20.6895 52.203L101.5 5.547Z' fill='%23007CC1'/%3E%3C/svg%3E%0A");
background-image: url("data:image/svg+xml,%3Csvg width='243' height='267' viewBox='0 0 243 267' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M101.5 5.547C113.876 -1.59831 129.124 -1.59831 141.5 5.547L222.311 52.203C234.687 59.3483 242.311 72.5534 242.311 86.844V180.156C242.311 194.447 234.687 207.652 222.311 214.797L141.5 261.453C129.124 268.598 113.876 268.598 101.5 261.453L20.6895 214.797C8.31341 207.652 0.689453 194.447 0.689453 180.156V86.844C0.689453 72.5534 8.31341 59.3483 20.6895 52.203L101.5 5.547Z' fill='%23007CC1'/%3E%3C/svg%3E%0A");    
background-repeat: no-repeat;
    background-position: center center;
    width: 243px;
    height: 267px;
    margin: 0 auto;
  }
  
  .plan-block:hover{
    //   background:linear-gradient(0deg, rgba(44, 121, 255, 0.6), rgba(44, 121, 255, 0.7)), url(../../images/4th-year.jpg);
    //    -webkit-transition: background 1000ms linear;
    //   -ms-transition: background 1000ms linear;
    //   transition: background 1000ms linear;
    transform: scale(1.15);
  }
  
  .plan-block sup{
      margin-right:10px;
  }
  
  .bg-blue{
      background:#fbfbfb;
      padding:100px 0;
      position:relative;
  }
  /*
  .bg-blue:after{
      content: '';
      position: absolute;
      top: -36px;
      width: 55%;
      height: 212px;
      background: #ffffff;
      border-radius: 50%;
      z-index: -1;
      left: 50%;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      margin-left: -27.5%;
  }
  .bg-blue:before{
      content: '';
      position: absolute;
      bottom: -36px;
      width: 55%;
      height: 212px;
      background: #ffffff;
      border-radius: 50%;
      z-index: -1;
      left: 50%;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      margin-left: -27.5%;
  }*/
  
  .state-block{
      text-align:Center;
      cursor: pointer;
  }
  .state-block .img-icon{
      width:90px;
      height:90px;
      background: #FFFFFF;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
      border-radius: 2px 20px;
      margin:0 auto;
      display:flex;
      align-items:center;
      justify-content:center;
      margin-bottom:12px;
  }
  
  .state-block span{
      display:block;
      font-weight: 500;
      font-size: 18px;
  }
  
  
  
  .nav-pills-custom .nav-link {
      color: #727272;
      background: #fff;
      position: relative;
      box-shadow: 0 .1rem 0.8rem rgba(0,0,0,.10)!important;
      padding: 10px;
      margin-bottom: 8px;
  }
  
  .nav-pills-custom .nav-link.active {
      color: #2C79FF;
      background: #fff;
      font-weight:500;
  }
  
  .tab-content-custom > .tab-pane{
      min-height:355px;
  }
  
  .custom-form-select{
      border: 1px solid #2C79FF;
      color: #2C79FF;
  }
  
  
  /* Add indicator arrow for the active tab */
  @media (min-width: 992px) {
      .nav-pills-custom .nav-link::before {
          content: '';
          display: block;
          border-top: 8px solid transparent;
          border-left: 10px solid #fff;
          border-bottom: 8px solid transparent;
          position: absolute;
          top: 50%;
          right: -10px;
          transform: translateY(-50%);
          opacity: 0;
      }
  }
  
  .nav-pills-custom .nav-link.active::before {
      opacity: 1;
  }
  
  
  
  @media (max-width: 1500px) {
      .plans-main-block, .bg-blue {padding: 50px 0;}
      .carousel-indicators{    margin: 14px auto 38px auto; min-height: 35px; width: 125px;}
      .carousel-indicators [data-bs-target]{width:12px; height:12px;}
  }
  

  
  @media (max-width: 767px) {
      body{
          margin-top:5rem;
      }
      .carousel-indicators{margin: 14px auto;    min-height: 35px; width:160px;}
      .carousel-indicators [data-bs-target]{width:12px; height:12px;}
  }
  .fundamentals{
    font-size:22px;
    font-weight:600;
    line-height: 33px;
    color:#494949
  }

  

`;