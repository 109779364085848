import React, { Component, createContext, useEffect, useState } from 'react';
import { Styles } from '../styles/courseCategory.js';
import { PostApi } from '../../../services/CommonService2.js';
import CourseItemList from './CourseItemsList.js';
const CourseCategory = (props) => {

    return (
        <Styles>

            <div className="course-category">
                <h5>Program Category</h5>

                <ul className="category-item list-unstyled">
                    <li className='btn btn-secondary my-4' style={{ display: "flex",textAlign:"center", justifyContent:"center" }}  onClick={(e) => props?.ctgFn("All")}>
                       <p>All Programs</p>
                    </li>
                    {props?.updatedCategory?.map((data, ind) => {
                        return (<>
                            {/* {ind == 0 && <li className="check-btn" style={{ display: "flex" }}>
                                <input type='checkbox' id="All" name='All' onChange={(e) => props.ctgFn(e, false)} checked={props.dispAllPg}/>
                                <label htmlFor="All" >All</label>
                            </li>} */}
                            <li className="check-btn" style={{ display: "flex" }}  >

                                <input type='checkbox' id={`${ind}`} onChange={(e) => props?.ctgFn(e, data.id)} name={data.name} checked={data.check} />
                                <label htmlFor={`${ind}`} style={{ marginLeft: "10px" }}>{data.name}</label>
                            </li>
                        </>)
                    })}

                </ul>

            </div>
        </Styles>
    )

}

export default CourseCategory
