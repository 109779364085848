import React from 'react'
import { CiBookmark } from "react-icons/ci";
import Accordion from 'react-bootstrap/Accordion';
function Problems() {
    return (
        <div className='m-0 p-0'>
            <p className='fs-5 fw-bold'>Find Indexes of a subarray with given sum <CiBookmark style={{ fontSize: "22px" }} /></p>
            <div className='d-flex justify-content-between pt-3 pb-3 border-bottom border-2'>
                <p className='fw-bold'>Medium</p>
                <p className='fw-bold'>Accuracy: 16.5%</p>
                <p className='fw-bold'>Submissions: 1.6M</p>
                <p className='fw-bold'>Points: 4</p>
            </div>
            <div className='pt-3 pb-3'>
                <p style={{ fontSize: '16px' }}>
                    Given an unsorted array arr of size n that contains only non negative integers, find a sub-array (continuous elements) that has sum equal to s. You mainly need to return the left and right indexes(1-based indexing) of that subarray.return the subarray indexes which come first </p><br />

                <p style={{ fontSize: '16px' }}>In case of multiple subarrays, return the subarray indexes which come first on moving from left to right. If no such subarray exists return an array consisting of element -1.
                </p>
            </div>
            <div>
                <p className='fs-5 fw-bold'>Exampls:</p>
                <div className='p-3 m-3 border border-2 rounded' style={{ backgroundColor: "#e5e4e2" }}>
                    <p className='p-1'><b>Input:</b> {`arr[ ] = {1,2,3,7,5}, n = 5, s = 12`}</p>
                    <p className='p-1'><b>Output:</b> 2 4</p>
                    <p className='p-1'><b>Explanation:</b> The sum of elements from 2nd to 4th position is 12.</p>
                </div>
                <div className='p-3 m-3 border border-2 rounded' style={{ backgroundColor: "#e5e4e2" }}>
                    <p className='p-1'><b>Input:</b> {`arr[ ] = {1,2,3,7,5}, n = 5, s = 12`}</p>
                    <p className='p-1'><b>Output:</b> 2 4</p>
                    <p className='p-1'><b>Explanation:</b> The sum of elements from 2nd to 4th position is 12.</p>
                </div>
                <div className='p-3 m-3 border border-2 rounded' style={{ backgroundColor: "#e5e4e2" }}>
                    <p className='p-1'><b>Input:</b> {`arr[ ] = {1,2,3,7,5}, n = 5, s = 12`}</p>
                    <p className='p-1'><b>Output:</b> 2 4</p>
                    <p className='p-1'><b>Explanation:</b> The sum of elements from 2nd to 4th position is 12.</p>
                </div>
                <div className='p-3 m-3 border border-2 rounded' style={{ backgroundColor: "#e5e4e2" }}>
                    <p className='p-1'><b>Input:</b> {`arr[ ] = {1,2,3,7,5}, n = 5, s = 12`}</p>
                    <p className='p-1'><b>Output:</b> 2 4</p>
                    <p className='p-1'><b>Explanation:</b> The sum of elements from 2nd to 4th position is 12.</p>
                </div>
            </div>

            <div className=' '>
                <p className='fs-5 fw-bold'>Your Task:</p>
                <p className='p-3 ' style={{ fontSize: '16px' }}>
                    You don't need to read input or print anything. The task is to complete the function subarraySum() which takes arr, n, and s as input parameters and returns an array containing the starting and ending positions of the first such occurring subarray from the left where sum equals to s. The two indexes in the array should be according to 1-based indexing. If no such subarray is found, return an array consisting of only one element that is -1.
                </p>
                <p className='p-1'><b>Expected Time Complexity:</b> {` O(n)`}</p>
                <p className='p-1'><b>Expected Auxiliary Space:</b> {` O(1)`} </p>
            </div>
            <div className='mt-4 py-3 px-3 border border-success rounded' style={{backgroundColor:"#f0fff0"}}>
                <div className='d-flex justify-content-between'>
                    <p style={{fontSize:"18px"}}>Seen this question in a real interview before ?</p>
                    <p style={{fontSize:"18px"}}>1/4</p>
                </div>
                <div className='d-flex justify-content-start mt-2'>
                    <button className='py-1 px-4 m-2' style={{fontSize:"18px",border:"1px solid green", color:"green",borderRadius:"30px",backgroundColor:"#f0fff0"}}>Yes</button>
                    <button className='py-1 px-4 m-2' style={{fontSize:"18px",border:"1px solid green", color:"green",borderRadius:"30px",backgroundColor:"#f0fff0"}}>No</button>
                </div>
            </div>

            <div className='mt-4'>

            <Accordion defaultActiveKey={['0']} alwaysOpen>
      <Accordion.Item eventKey="0">
        <Accordion.Header >Company Tags</Accordion.Header>
        <Accordion.Body >
<div className='d-flex flex-wrap'>
<span className=' py-2 px-4 m-2 border border-secondary rounded'>Amazon</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Microsoft</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>TCS</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Accenture</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>VisualpathIT</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Cognizant</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Tech M</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Wipro</span>
</div>
      
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Topic Tags</Accordion.Header>
        <Accordion.Body>
        <div className='d-flex flex-wrap'>
<span className=' py-2 px-4 m-2 border border-secondary rounded'>Arrays</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Objects</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Functions</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Variables</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Datatypes</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Methods</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'> Scopes</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>String Literals</span>
</div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Related Courses</Accordion.Header>
        <Accordion.Body>
        <div className='d-flex flex-wrap'>
<span className=' py-2 px-4 m-2 border border-secondary rounded'>Data Structures and Algorithms</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Database Management Systems</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Operating Systems</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Software Engineering</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Computer Networks</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Machine Learning</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'> Artificial Intelligence</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'> Principles of Management</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'> Operations Management</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Cybersecurity
         </span>
</div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Related Interview Experiences</Accordion.Header>
        <Accordion.Body>
        <div className='d-flex flex-wrap'>
<span className=' py-2 px-4 m-2 border border-secondary rounded'>Technical Skills Assessment</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Technical Problem-Solving</span>
         <span className=' py-2 px-4 m-2 border border-secondary rounded'>Project Management</span>
    
</div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </div>
        </div>
    )
}

export default Problems