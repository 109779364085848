import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { Styles } from './styles/account.js';
import Toast from 'react-bootstrap/Toast';
import { PostApi } from '../../services/CommonService2.js';
import FooterTwo from '../../components/FooterTwo';
import Header3 from '../../home3components/Header3.jsx';
import Footer3 from '../../home3components/Footer3.js';
function ChangePassword() {
    const [isToast, setIsToast] = useState(false);
    const [status, setStatus] = useState(null);
    const [message,setMessage] = useState("");
    const [tenantId,setTenantId] = useState('')
    let history = useHistory ();
    const [password, setPassword] = useState({
        oldpassword: "",
        userid: "",
        newpassword: "",
        verifypassword: ""
    })


    useEffect(() => {
        var tntId= JSON.parse(localStorage.getItem('tID'))
        setTenantId(tntId)
    },[])
    const handleChange = (e) => {
        const { name, value } = e.target
        setPassword({ ...password, [name]: value })

    }
    const handleChangePassword =async (e) => {
        e.preventDefault()
        if (password.oldpassword == '') {
            setIsToast(true)
            setStatus("Failed")
            setMessage("Current password field should not be empty")
            setTimeout(()=>{
                setIsToast(false)
            },6000)
        }
         else if (password.newpassword == '') {
            setIsToast(true)
            setStatus("Failed")
            setMessage("New password field should not be empty")
            setTimeout(()=>{
                setIsToast(false)
            },6000)
        }
        else if (password.verifypassword == '') {
            setIsToast(true)
            setStatus("Failed")
            setMessage("Confirm password field should not be empty")
            setTimeout(()=>{
                setIsToast(false)
            },6000)
        }
         else if (password.newpassword != password.verifypassword) {
            setIsToast(true)
            setStatus("Failed")
            setMessage("New password, Confirm password should be match")
            setTimeout(()=>{
                setIsToast(false)
            },6000)
        } else{
    var tntId= JSON.parse(localStorage.getItem('tID'))
    setTenantId(tntId)
            const sessiondetails =  localStorage.getItem(`userdata${tntId}`);
      if (sessiondetails != null) {
        const userdata = JSON.parse(sessiondetails);
        // console.log(userdata, "userdata")
       
            const respdata ={
              oldpassword:password.oldpassword,
              userid: userdata.id,
              newpassword:password.newpassword
      
            }
            const ResponseMessage = await PostApi(respdata,'CHANGEPASSWORD');
            // console.log(ResponseMessage,"ResponseMessage")
            if(ResponseMessage.data =='USER_NOT_FOUND'){
                setIsToast(true)
                setStatus("Failed")
                setMessage("User not found")
                setTimeout(()=>{
                    setIsToast(false)
                },6000)
            
            }else if(ResponseMessage.data =='INVALIDPASSWORD'){
                setIsToast(true)
                setStatus("Failed")
                setMessage("Invalid old password")
                setTimeout(()=>{
                    setIsToast(false)
                },6000)
             
            }else if(ResponseMessage.data =='INACTIVE'){
                setIsToast(true)
                setStatus("Failed")
                setMessage('Your account is inactive ,Please contact admin')
                setTimeout(()=>{
                    setIsToast(false)
                },6000)
            }
           else if(ResponseMessage.data =='ALREADY_LOGGEDIN'){
            setIsToast(true)
            setStatus("Failed")
            setMessage('You have already logged in with this mobile number , Please kindly logout or contact support team')
            setTimeout(()=>{
                setIsToast(false)
            },6000)
             
            }
            else{
                setIsToast(true)
                setStatus("Success")
                setMessage('Password changed successfully, Please Login again, Redirecting to Home .... ')
                setTimeout(async()=>{
                    setIsToast(false)
                    localStorage.removeItem("userdata")
                    history.push("/")

                    const reqparams = {
                        mobileno: userdata.user.mobileno
                        
            
                    }
                    const clearsessionrespone = await PostApi(reqparams, 'LOGOUT');
                    // console.log("clearsessionrespone", clearsessionrespone);
                    var tntId=JSON.parse(localStorage.getItem('tID'))
            
                    localStorage.removeItem(`userdata${tntId}`)
                    window.location.href = '/'
                },5000)
      
          }
           
          }
    }}

    return (
        <>
        <Styles>
             {/* Main Wrapper */}
             <div className="main-wrapper login-page">

{/* Header 2 */}
{tenantId == "ffe21ec9" ? <Header3 /> : <HeaderTwo />}

            <Toast className='col-sm-12 col-md-6 col-lg-3' style={{position:"absolute", right:"5px", zIndex:999}} show={isToast} onClose={()=>setIsToast(false)}>
                <Toast.Header className={status=="Success"?"bg-success text-light":"bg-danger text-light"}>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">{status}</strong>
                    <small className="text-light">just now</small>
                </Toast.Header>
                <Toast.Body><h6>{message}</h6></Toast.Body>
            </Toast>

            <section className="login-area p-5">
                <Container>
                    <Row>
                        <Col md="12">

                            <div className="login-box">
                                <div className="login-title text-center">
                                    <h3>Change Password</h3>
                                </div>
                                <form id="form_login" className="form">
                                    <p className="form-control">
                                        <label htmlFor="current_password">Current Password</label>
                                        <input type="password" placeholder="*******" id="current_password" name="oldpassword"
                                            value={password.oldpassword}
                                            onChange={handleChange}
                                            style={{letterSpacing:"2px"}}
                                        />
                                        <span className="current_password-msg"></span>
                                    </p>
                                    <p className="form-control">
                                        <label htmlFor="new_password">New Password</label>
                                        <input type="password" placeholder="*******" id="new_password" name="newpassword"
                                            value={password.newpassword}
                                            onChange={handleChange}
                                            style={{letterSpacing:"2px"}}
                                        />
                                        <span className="new_password-msg"></span>
                                    </p>
                                    <p className="form-control">
                                        <label htmlFor="confirm_password">Confirm Password</label>
                                        <input type="password" placeholder="*******" id="confirm_password" name="verifypassword"
                                            value={password.verifypassword}
                                            onChange={handleChange}
                                            style={{letterSpacing:"2px"}}
                                        />
                                        <span className="confirm_input-msg"></span>
                                    </p>
                                    <button onClick={handleChangePassword}>Change Password</button>

                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {tenantId == "ffe21ec9" ? <Footer3 />:<FooterTwo/>}
            
            </div>
        </Styles>
        </>
    )
}

export default ChangePassword