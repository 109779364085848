import React, { useEffect, useState } from "react"

import { styles } from "../styles"

import axios from 'axios'

import { LoadingOutlined } from '@ant-design/icons'

import Avatar from '../Avatar'

const EmailForm = props => {    
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [userData, setuserData] = useState('')
    var [modelData,setModelData]=useState(false)
    useEffect(()=>{
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const storeData = localStorage.getItem(`userdata${tntId}`);
// alert(storeData)
// alert(props.modelData);

       if(storeData!=null){
        const userstoredata=JSON.parse(storeData);
        // alert(userstoredata?.email);
        setuserData(userstoredata);
        setEmail(userstoredata?.email);
        setLoading(true);
        getOrCreateUser(
            user => {
                props.setUser && props.setUser(user)
                getOrCreateChat(chat => {
                    setLoading(false)
                    props.setChat && props.setChat(chat)
                })
            }
        )
       }

    },[props.modelData,modelData]);

    function getOrCreateUser(callback) {
        // console.log('email=========',email);
        axios.put(
            'https://api.chatengine.io/users/',
            {username: email, email: email, secret: email},
            {headers: {"Private-Key":process.env.REACT_APP_CE_PRIVATE_KEY}}
        )
        .then(r => callback(r.data))
        .catch(e => console.log('Get or create user error', e))
    }

    function getOrCreateChat(callback) {
        axios.put(
            'https://api.chatengine.io/chats/',
            {usernames: [email, 'Visualpathedtech'], is_direct_chat: true},
            {headers: {
                "Project-ID": process.env.REACT_APP_CE_PROJECT_ID,
                "User-Name": email,
                "User-Secret": email,
            }}
        )
        .then(r => callback(r.data))
        .catch(e => console.log('Get or create chat error', e))
    }

    function handleSubmit(event) {
        event.preventDefault();
        setLoading(true)

        console.log('Sending Email', event.target.value)

        getOrCreateUser(
            user => {
                props.setUser && props.setUser(user)
                getOrCreateChat(chat => {
                    setLoading(false)
                    props.setChat && props.setChat(chat)
                })
            }
        )
    }

    // const toggleBooleanValue = () => {
    //     setModelData(prevValue => !prevValue);
    //   };
    return (
        <div 
            style={{
                ...styles.emailFormWindow,
                ...{ 
                    height: props?.visible ? '100%' : '0px',
                    opacity: props?.visible ? '1' : '0'
                }
            }}
        >
            <div style={{ height: '0px' }}>
                <div style={styles.stripe} />
            </div>

            <div 
                className='transition-5'
                style={{
                    ...styles.loadingDiv,
                    ...{ 
                        zIndex: loading ? '10' : '-1',
                        opacity: loading ? '0.33' : '0',
                    }
                }}
            />
            <LoadingOutlined
                className='transition-5'
                style={{
                    ...styles.loadingIcon,
                    ...{ 
                        zIndex: loading ? '10' : '-1',
                        opacity: loading ? '1' : '0',
                        fontSize: '82px',
                        top: 'calc(50% - 41px)', 
                        left: 'calc(50% - 41px)',  
                    }
                }}
            />

            <div style={{ position: 'relative', height: '100%', width: '100%', textAlign: 'center' }}
                    >
                <Avatar 
                    style={{ 
                        position: 'absolute',
                        left: 'calc(50% - 44px)',
                        top: '10%',
                    }}
                    onClick={()=>{setModelData(!modelData)}}
                />
                

                <div style={styles.topText}>
                    Welcome to my <br /> support 👋
                </div>

               {userData==''&& <form 
                    onSubmit={e => handleSubmit(e)}
                    style={{ position: 'relative', width: '100%', top: '19.75%' }}
                >
                    <input 
                        placeholder='Your Email'
                        onChange={e => setEmail(e.target.value)}
                        style={styles.emailInput}
                    />
                </form>}
                
                <div style={styles.bottomText}>
                    Enter your email <br /> to get started.
                </div>
            </div>
        </div>
    )
}

export default EmailForm;