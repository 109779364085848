import React from "react";
import { classnames } from "../utils/general";

const CustomInput = ({ customInput, setCustomInput }) => {
  return (
    <>
      {" "}
      <textarea
        rows="5"
        value={customInput}
        onChange={(e) => setCustomInput(e.target.value)}
        placeholder={`Custom input`}
        style={{width:'90%', margin:'15px', border:'2px solid black', boxShadow:'5px 5px black', borderRadius:'5px',padding:'10px', color:'black'}}
      ></textarea>
    </>
  );
};

export default CustomInput;
