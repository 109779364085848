import React, { useEffect, useState } from "react";
import icon2 from './cancel-close-button.png';
import icon from './hero-logo-min.png';

import { styles } from './styles';

const Avatar = props => {
    const [hovered, setHovered] = useState(false)

    useEffect(()=>{
        console.log('props======',props.onClick);
    },[])
    return (
        <div style={props.style}>
            <div 
                className='transition-3'
                style={{
                    ...styles.avatarHello,
                    ...{ opacity: hovered ? '1' : '0' }
                }}
            >
                Hey it's visualpath 
            </div>

            <div 
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={() => props.onClick && props.onClick()}
                className='transition-3'
                style={{
                    ...styles.chatWithMeButton,
                    ...{backgroundImage:!props.visible?`url(${icon})`:`url(${icon2})`},
                    ...{ border: hovered ? '1px solid #f9f0ff' : '4px solid #7a39e0' }
                }}
            />
        </div>
    )
}

export default Avatar;