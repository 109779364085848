import React from 'react';
// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from 'react';
import Slider from "react-slick";
import { PostApi } from '../../services/CommonService2';

function Testrimonial() {

	useEffect(() => {
		getTestimonials()
	}, [])
	const [testimonials, setTestimonial] = useState([])

    const getTestimonials = async () => {
		const reqparam = {
			pageno: '-1',
			query: { status: 'Active',tenant:{
                $elemMatch: { "value": "526daf25" }
              } },

		}
		const ResponseData = await PostApi(reqparam, "Testimonials");
		console.log(ResponseData,"check instructor daata")
		setTestimonial(ResponseData.data)
	}
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    
      };
  return (
  <>
  
  <div className="slider-container " style={{width:"70%", margin:"0% 15%"}} >
    <div className='text-center m-3 ' style={{fontSize:"35px",fontWeight:"bold" }}>Testimonials</div>
    <Slider {...settings}>
 {testimonials.map((ele,ind)=>{
    return(
        <div className='p-3'>
        <div className='d-flex justify-content-center'>
        <img class="card-img-top" src={ele.image} style={{width:"75px",height:"75px", borderRadius:"50%",marginRight:"20px"}} alt="Card image cap"/>
        </div>
          <div class="card-body">
            <p class="card-text fs-5 mt-2 mb-2">{ele.description}</p>
            <h6 style={{textAlign:"right",marginRight:"10px"}}><i>- {ele.name}</i></h6>
          </div>
        </div>
    )
 })}
   


    </Slider>
  </div>
  </>
  )
}

export default Testrimonial