import React, { useEffect, useState } from 'react'
import { PostApi } from '../../../services/CommonService2';
import { Styles } from '../../../components/styles/serviceBox';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import LoginModal from '../../../components/LoginModal';

function GuidanceHome() {
    const [guidancelist, setGuidanceList] = useState([]);
    const history = useHistory();
    const [modalUp, setModalUp] = useState(false);

    useEffect(() => {
        getGuidanceData()

    }, [])

    const getGuidanceData = async () => {

        const reqparams1 = {
            pageno: '-1',
            query: { status: 'Active' }

        }
        const dataResponsecat = await PostApi(reqparams1, 'CATEGORY');
        // console.log("category", dataResponsecat.data);

        const categorydata = dataResponsecat.data.filter(t => t.name == 'Non IT Courses');

        // console.log("category data", categorydata);

        if (categorydata.length > 0) {
            const reqparams = {
                pageno: '-1',
                query: { status: 'Active', category: categorydata[0].id },
                // usertype: 'APP',

            }
            console.log("reqparams", reqparams);

            const dataResponse = await PostApi(reqparams, 'NONITSKILLS');
            console.log("guidance", dataResponse.data);

            //settypesList(dataResponse.data);



            setGuidanceList(dataResponse.data);
        }
    }

    const nonItCourses = async (data) => {
        // console.log("data", data);
  var tntId= await JSON.parse(localStorage.getItem('tID'))

        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        if (userdata == null) {
            setModalUp(true)
            console.log("inside");
            // e.preventDefault()

            toast.info('login to check course details', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
        else {
            history.push({
                pathname: "/course-list",
                state: data
            })

        }



    }
    const openModal = () => {
        setModalUp(false)
    }

    const logIn = () => {

    }

    return (
        <Styles>
            {guidancelist && guidancelist.length > 0 && <section style={{ textAlign: "center", padding: "3%" }}>
                <div className="container">
                    <div className="row" >

                        <div className="col-md-12 mb-5">

                            <h3 className="main-title">Guidance for Non IT Jobs</h3>

                        </div>
                    </div>


                    <div className='row' style={{ boxSizing: "border-box" }}>
                        {guidancelist?.map((ele, ind) => {
                            // let imageid = 'https://visualpathedu.com/static/media/4th-year.15b862d606512e3257c0.jpg';
                            // if (ele?.image) {
                            //     imageid = ele.image;
                            // } else if (ele?.image_url) {
                            //     imageid = ele.image_url;
                            // }
                            // else if (ele?.imageurl) {
                            //     imageid = ele.imageurl ? ele.imageurl : "";
                            // }

                            return (
                                <div className='col-sm-12 col-md-6 col-lg-4' style={{ boxSizing: "border-box" }}>


                                    <Button style={{ width: "75%", textAlign: "center", margin: "15px 10%", padding: "10px" }} key={ind} onClick={() => nonItCourses(ele)}>{ele.name}</Button>
                                </div>
                            )
                        })}
                    </div>
                    <br />

                </div>
            </section>}

            {modalUp && <LoginModal onClose={openModal} login={logIn}></LoginModal>}

        </Styles>
    )
}

export default GuidanceHome