import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { TbRefresh } from "react-icons/tb";
function MySubmissions() {
  return (
   <>
   <div className='d-flex justify-content-end'>
   <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                       Languages
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item >Java</Dropdown.Item>
                        <Dropdown.Item >C</Dropdown.Item>
                        <Dropdown.Item >C++</Dropdown.Item>
                        <Dropdown.Item >Python</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div>
                <TbRefresh className='fs-1' style={{marginLeft:'10px'}} /> <span className='fs-5 pt-1 d-inline-block'>Refresh</span>
                </div>
   </div>
    <div className='d-flex justify-content-center mt-5  '>
    <h5> No Submissions</h5>p
     </div>
   </>
  )
}

export default MySubmissions