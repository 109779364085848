import React from 'react'
import { useState, useEffect } from "react";
import { GetYoutube } from './youtube-service';
import { PostApi } from '../../services/CommonService2';
import YouTube from "react-youtube";
function Youtube(props) {
    const [youtubeData, setYoutubeData] = useState([])
    useEffect(() => {

        getYoutubeVideo()
    }, [props.subapllicationid])
    const getYoutubeVideo = async () => {
        console.log(props, "fron youtube videos")
        const reqdata = {
            pageno: '-1',
            query: { status: 'Active' },


        }
        try {
            const data = await PostApi(reqdata, 'YOUTUBEVIDEOS', props.subapllicationid)
            
            setYoutubeData(data.data)
        } catch (error) {
            console.log(error)
        }
    }
    const opts = {
        width: "100%",
        borderRadius: "2rem",
        playerVars: { autoplay: 0 },
        height:"280px"
    };
    const videoReady = (event) => {
        event.target.pauseVideo();
      };
    return (
        <div className='col-sm-12 col-md-6 col-lg-4 my-5' style={{ maxHeight: "520px", overflowY: "scroll" }}>
            <div className="card">
                <div className="card-header bg-danger p-4">
                    <p className='text-light fs-2'><b>Youtube Videos</b></p>
                </div>
                {/* {console.log(youtubeData,"check youtube data")} */}
                <ul className="list-group list-group-flush">

                    {

                        youtubeData?.map(ele => {
                            return (
                                <>
                                    <li className="list-group-item list-group-item-action">
                                        {/* <iframe width="560" height="315" src={ele.name} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{width:"100%"}}></iframe> */}
                                        <YouTube
                                            videoId={ele.name}
                                            opts={opts}
                                            onReady={videoReady}
                                        />
                                    </li>


                                </>
                            )
                        })
                    }

                </ul>
            </div>
        </div>
    )
}

export default Youtube