import React from 'react'
import { GetNews } from './news-service'
import { useState, useEffect } from "react";
import { PostApi } from '../../services/CommonService2';
function News(props) {
    const [newsData, setNewsData] = useState([])
    useEffect(() => {
        getNews()
    }, [props.subapllicationid])

    const getNews = async () => {
        const reqdata = {
            pageno: '-1',
            query:{status:'Active'},
           

        }
        const news = await PostApi(reqdata,'NEWS',props.subapllicationid)
        // console.log("news",news.data);
        setNewsData(news.data)
    }

    return (
        <div className='col-sm-12 col-md-6 col-lg-4 my-5'>
            <div className="card h-100" >
                <div className="card-header bg-warning p-4">
                    <p className='text-light fs-2'><b>News</b></p>
                </div>
                <ul className="list-group list-group-flush">
                {
                newsData.map(ele=>{
                    return(
                    <>
                    {/* <div className='newsDiv'> */}
                    <li className="list-group-item list-group-item-action py-2 px-5"><p className='fs-5'>{ele.name}</p></li>
                    {/* </div> */}
                    {/* <li className="list-group-item list-group-item-action py-2 px-5"><p className='fs-2'>{ele.name}</p></li>
                    <li className="list-group-item list-group-item-action py-2 px-5"><p className='fs-2'>{ele.name}</p></li> */}
                    </>
                    )
                })
               }
                   
                </ul>
            </div>
        </div>
    )
}

export default News