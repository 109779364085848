import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IoSend } from "react-icons/io5";
import UserComment from './UserComment';
import "./comment.css"
function Comments() {
    const [value, setValue] = useState('');
    return (
        <>
            <div className='d-flex justify-content-between'>
                <h6>Discussions
                    {`( 3927 + Threads )`}</h6>
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Search By
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Most Recent</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Lease Recent</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Most Rated</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className='p-2'>
            <ReactQuill theme="snow" placeholder='Wrire Your Comment . . .' value={value} onChange={setValue} />
            <div className='d-flex justify-content-end mt-3'>
                <button className='btn btn-success'>Post Comment <IoSend /></button>
            </div>
            <div className='comments mt-4' >
                <UserComment />
                <UserComment />
                <UserComment />
                <UserComment />
                <UserComment />
                <UserComment />
                <UserComment />
                <UserComment />
                <UserComment />
                <UserComment />
            </div>
            </div>
        </>
    )
}

export default Comments