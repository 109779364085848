import React, { Component, useState } from 'react';
import { Styles } from '../styles/courseSearch.js';

const ProgramSearch=(props)=>{
    const [data,setData]=useState("")
    const handleChange=(e)=>{
       setData(e.target.value)
    }
    const searchBtn=(e)=>{
        e.preventDefault()
        props.searchData(data)
        
    }


    
        return (
            <Styles>
                {/* Course Search */}
                <div className="course-search">
                    <h5>Search Course</h5>
                    <form>
                        <input type="text" name="search" placeholder="Search Here" value={data} onChange={handleChange}/>
                        <button type="submit" onClick={searchBtn}><i className="las la-search"></i></button>
                    </form>
                </div>
            </Styles>
        )
    
}

export default ProgramSearch
