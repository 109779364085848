import { PostApi } from '../../services/CommonService2';
import { Styles } from './styles/course.js';
import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import LoginModal from '../../components/LoginModal.js';
import 'react-toastify/dist/ReactToastify.css';
import HeaderTwo from '../../components/HeaderTwo.js';
import CourseSidebar from './components/CourseSidebar.js';
import StProgramCategory from './components/StProgramCategory.js';
import StProgramSidebar from './components/StProgramSidebar.js';
import FooterTwo from '../../components/FooterTwo.js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Modal, Button } from 'react-bootstrap';
import StripeForm from '../../components/StripeForm.js';
import { GetApi } from '../../services/CommonService2';
import { FALSE } from 'sass';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


function CrtProgram() {
  const [courseList, setCourseList] = useState([]);
  const [modalUp, setModalUp] = useState(false)
  const [updatedTypes, setUpdatedTypes] = useState([])
  const [category, setCategory] = useState([]);
  const [updatedCategory, setUpdatedCategory] = useState([]);
  const [newcategory, setNewCategory] = useState([]);
  const history = useHistory()
  const [CouponcodeNo, setCouponcodeNo] = useState(0)
  const [enrollFree, setEnrollFree] = useState(false)
  const [userData, setUserData] = useState(null)
  const [confirmation, setConformation] = useState(false)
  const [finalAmount, setFinalAmount] = useState(0)
  const [checkedData, setCheckedData] = useState(undefined)
  const [showError, setShowError] = useState(false)
  const [purchased, setPurchased] = useState(false)
  const [purchaseSUccessFull, setPurchaseSUccessFull] = useState(false)
  const [subscribed, setSubscribed] = useState(false)




  useEffect(() => {
    getData();
  }, []);



  const [courseData, setCourseData] = useState();
  const [show, setShow] = useState(false);
  const [item, setItem] = useState(null);
  const [item2, setItem2] = useState(null);
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [itemModal, setItemModal] = useState(false);
  const [redirect, setRedirect] = useState(false)
  const [crt, setCrt] = useState(false)

  useEffect(() => {

    // let arr = category.map((item) => {
    //     return {
    //         ...item,
    //         check: false
    //     }
    // })

    // // console.log("arr mate", arr);
    // setUpdatedCategory(arr)

    let arr2 = category.filter((item) => item.id == "656f5590373b3a372764c0f3")

    setNewCategory(arr2)


  }, [category])

  useEffect(() => {
    let arr = newcategory.map((item) => {
      return {
        ...item,
        check: true
      }
    })

    // console.log("arr mate", arr);
    setUpdatedCategory(arr)
  }, [newcategory])


  const getData = async () => {
    const reqparams1 = {
      pageno: '-1',
      query: { status: 'Active', category: '656f5590373b3a372764c0f3' },
      optimize: true,
      userid: '',
      usertype: 'APP',
      tenantid: ''
    }
    const response = await PostApi(reqparams1, 'ITPROGRAMS');
    // console.log("ITPROGRAMS based on ctg", response.data);
    setCourseList(response?.data);

    const reqparam = {
      pageno: '-1',
      query: { status: 'Active' }
    }
    const action = "CATEGORY"

    const ResponseData = await PostApi(reqparam, action);
    // console.log("Category List", ResponseData.data);
    setCategory(ResponseData.data)
    setCheckedData('656f5590373b3a372764c0f3')
  }


  async function ctgSearch(e, data) {
    const { checked } = e.target

    // console.log("checked value", checked,data);

    if (checked) {
      const reqparams1 = {
        pageno: '-1',
        query: { status: 'Active', category: `${data}` },
        optimize: true,
        userid: '',
        usertype: 'APP',
        tenantid: ''
      }

      const ResponseData = await PostApi(reqparams1, 'ITPROGRAMS');
      setCourseList(ResponseData?.data);
      let arr = newcategory.map((item) => {
        return {
          ...item,
          check: true
        }
      })

      // console.log("arr mate", arr);
      setUpdatedCategory(arr)
      setCheckedData(data)
      setShowError(false)

    }
    else {

      const reqparam = {
        pageno: "-1",
        query: { status: 'Active' },
        usertype: 'APP',
        tenantid: '',
        optimize: true,
        userid: ""
      }
      const ResponseData = await PostApi(reqparam, "ITPROGRAMS");
      setCourseList(ResponseData?.data);
      let arr = newcategory.map((item) => {
        return {
          ...item,
          check: false
        }
      })

      // console.log("arr mate", arr);
      setUpdatedCategory(arr)
      setCheckedData(undefined)
      setShowError(false)

    }

  }
  const searchData = async (keySearch) => {

    // if (checkedData) {

    const reqparam = {
      pageno: '-1',
      query: checkedData != undefined ? { status: 'Active', category: `${checkedData}` } : { status: 'Active' },
      optimize: true,
      userid: '',
      usertype: 'APP',
      tenantid: ''
    }
    reqparam.query.name = { $regex: keySearch, $options: "i" }
    const ResponseData = await PostApi(reqparam, "ITPROGRAMS");
    setCourseList(ResponseData?.data);

    if (ResponseData?.data == 0) {
      setShowError(true)
    }
    else {
      setShowError(false)
    }
    // }
    // else {
    //   const reqparam = {
    //     pageno: "-1",
    //     query: { status: 'Active' },
    //     usertype: 'APP',
    //     tenantid: '',
    //     optimize: true,
    //     userid: ""
    //   }
    //   reqparam.query.name = { $regex: keySearch, $options: "i" }
    //   const ResponseData = await PostApi(reqparam, "ITPROGRAMS");
    //   setCourseList(ResponseData?.data);
    // }

  }

  const loginValidate = async (data) => {
    setItem(data)
    console.log(data, "check course data")
    var tntId = JSON.parse(localStorage.getItem('tID'))

    const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
    if (userdata == null) {
      setModalUp(true)
      // e.preventDefault()

      toast.info('login to check course details', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    else if (userdata != '' || userdata != null) {

      const reqparams1 = {
        pageno: 1,
        query: { userid: userdata.id, programid: data.id },
        // query: { userid: '6574158e4c53154af2cc274a'},
        ptype: 'PROGRAMPURCHASES'
      }
      setItem(data)
      let response = await PostApi(reqparams1, 'DASHOARDLABEL');
      console.log(response, "response")
      if (!response?.data?.length > 0) {

        const reqdata = {
          userid: userdata.id,
          ptype: 'USERSUBSCRIPTION',
          pageno: '-1',
          query: { userid: userdata.id }
        }

        const userSubsResp = await PostApi(reqdata, 'USERSUBSCRIPTION');
        if (userSubsResp?.data?.length > 0) {

          if (data?.on_sale == "N") {
            setCrt(true)
          }
          setSubscribed(true)
        } else {
          if (data?.on_sale == "N") {
            console.log("Inside")
            setCrt(true)
          } else {
            setCrt(false)
          }

          setSubscribed(false)
          setPurchased(false)
          setShow(true)
        }

      } else {

        handleClose()
        setPurchased(true)

      }
      return
    }
    else {
      return
    }
  }

  const openModal = () => {
    setModalUp(false)
  }


  const handleClose = () => setShow(false);
  // const handleShow = (data) => {


  // }

  const handlePurchase = async () => {
    var tntId = JSON.parse(localStorage.getItem('tID'))

    const sessiondetails = await localStorage.getItem(`userdata${tntId}`);

    if (sessiondetails != null) {
      const userdata = JSON.parse(sessiondetails);
      const reqparams1 = {
        pageno: 1,
        query: { userid: userdata.id, programid: item.id },
        // query: { userid: '6574158e4c53154af2cc274a'},
        ptype: 'PROGRAMPURCHASES'
      }

      let response = await PostApi(reqparams1, 'DASHOARDLABEL');
      // console.log(response, "response")
      if (!response?.data?.length > 0) {
        setPurchased(false)
        setItemModal(true)
        setItem2(item)
        setShowStripeForm(true);
        setShow(false)
      } else {
        handleClose()
        setPurchased(true)
      }
      // window.alert("Payment called")
    }
  };

  const publishableKey = 'pk_test_51ODj74SJbFT27gwoONAnMJ0endmdF071eX4FJ248gS37R5DAlcqrJ3YLiPxg053askOwRi7Pc4cl74mpyjXanfdZ00z0PVoKGR';

  useEffect(() => {
    setStripePromise(loadStripe(publishableKey))
  }, [])


  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 9;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const npage = Math.ceil(courseList.length / recordsPerPage)
  const records = courseList.slice(firstIndex, lastIndex)
  const numbers = [...Array(npage + 1).keys()].slice(1)
  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }


  }
  const changeCPage = (id) => {
    setCurrentPage(id)

  }
  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1)
    }

  }


  const logIn = async () => { }

  var previewFun = async (e) => {
    e.preventDefault();
    setItemModal(false);
    setShow(false)
    setShowStripeForm(false)
    setItem2(item)
    setRedirect(true)
    var tntId = JSON.parse(localStorage.getItem('tID'))

    const sessiondetails = await localStorage.getItem(`userdata${tntId}`);

    if (sessiondetails != null) {
      const userdata = JSON.parse(sessiondetails);
      if (item && (item != '' || item != null)) {
        if (item.on_sale == 'Y' && !item.ispurchased) {
          localStorage.setItem('previewtype', 'Y')
        }
        else if (item.on_sale == 'Y' && item.ispurchased) {
          localStorage.setItem('previewtype', 'Y')
        }
        else if (item?.canenroll == 'Y') {
          if (item.ispurchased || !item.ispurchased) {
            localStorage.setItem('previewtype', 'Y')
          }
        }
        else if (item?.canenroll == 'N') {
          localStorage.setItem('previewtype', 'N');
        }
      }
    }


  }

  if (redirect) {
    history.push({
      pathname: "/course-grid",
      state: item
    })
  }

  const handleProceed = async () => {
    try {
      var tntId = JSON.parse(localStorage.getItem('tID'))

      const sessiondetails = localStorage.getItem(`userdata${tntId}`);
      if (sessiondetails != null) {
        const userdata = JSON.parse(sessiondetails);
        setUserData(userdata)


        const reqParams = { name: userdata.username, amount: Number(item.sale_price), userid: userdata.id, courseid: item.id, couponcode: CouponcodeNo };
        // console.log(reqParams, "reqParams")
        const reqdataCoupon = await GetApi(reqParams, "STRIPEKEY");
        // console.log(reqdataCoupon, "reqdataCoupon")

        if (reqdataCoupon.message == 'SUCCESS') {
          setFinalAmount(Number(reqdataCoupon.amount))

        }
      }
      setConformation(true)
    } catch (error) {
      console.log(error)
    }
  }


  const hashChangeEvent2 = async () => {

    try {
      var tntId = JSON.parse(localStorage.getItem('tID'))

      const sessiondetails = localStorage.getItem(`userdata${tntId}`);
      if (sessiondetails != null) {
        const userdata = JSON.parse(sessiondetails);
        setUserData(userdata)
        // console.log(userdata, "userdata")
        // add coupone here

        const reqParams = { name: userdata.username, amount: Number(item.sale_price), userid: userdata.id, courseid: item.id, couponcode: CouponcodeNo };
        // console.log(reqParams, "reqParams")

        const reqdataCoupon = await GetApi(reqParams, "STRIPEKEY");
        // console.log(reqdataCoupon, "reqdataCoupon")

        if (reqdataCoupon.message == 'SUCCESS') {

          const reqdata = {
            amount: Number(item.sale_price),
            userid: userdata.id
          }
          const orderResp = await PostApi(reqdata, 'RAZORPAYORDERID');
          // console.log(orderResp, "orderResp")
          const options = {
            name: 'visualpath',
            image: 'https://asseshub.com/resume1/assets/images/adaptive-icon.png',
            description: 'Program purchase',
            order_id: orderResp.id,
            key: "rzp_live_VMuQP30hpyTin3",
            prefill: {
              email: userdata.email,
              contact: userdata.username,
              // name: userdata.userdata,
            },
            theme: { color: '#a29bfe' },
            handler: async (transaction) => {
              console.log(transaction, "transaction")
              const resdata = {
                userid: userdata.id,
                transaction: transaction,
                orderID: orderResp.id,
                programid: item.id
              }
              const respSignatureData = await PostApi(resdata, 'VERIFYPAYMENTS');
              console.log(respSignatureData.message, "respSignatureData verify payments");
              setConformation(false)
              setPurchaseSUccessFull(true)

              setTimeout(() => {
                setPurchaseSUccessFull(false)
              }, 10000)
            }
          };
          const razor = new window.Razorpay(options);

          razor.open()
        } else if (reqdataCoupon.message == 'INVALIDCOUPONCODE') {
          toast.info('Invalid coupon code or you have already applied it , Please go back and retry again', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });


        } else if (reqdataCoupon.message == 'COUPONAMOUNTMORE') {
          toast.info('coupon amount value is more than the course amount please choose a different program', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

        } else if (reqdataCoupon.message == 'FREECOURSE') {
          setEnrollFree(true)
        }



      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleFreeEnroll = async () => {
    var tntId = JSON.parse(localStorage.getItem('tID'))


    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    if (sessiondetails != null) {
      const userdata = JSON.parse(sessiondetails);

      const reqparams1 = {
        pageno: 1,
        query: { userid: userdata.id, programid: item.id },
        // query: { userid: '6574158e4c53154af2cc274a'},
        ptype: 'PROGRAMPURCHASES'
      }

      let response = await PostApi(reqparams1, 'DASHOARDLABEL');
      if (!response?.data?.length > 0) {

        const reqdata = {
          programid: item.id,
          userid: userdata.id,
          couponcode: "0"
        }

        const resp = await PostApi(reqdata, 'ENROLL');
        if (resp.message == 'SUCCESS') {
          toast.success('Course enrollment successful,Please check your enrolled couse under my courses', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          history.push("/")
        } else {

          toast.error('Failed to enroll your course', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          history.push("/")
        }
      } else {
        // setItem({ ...item, ispurchased: true })
        setPurchased(true)
      }

    }
  }
  const goBack = () => {
    history.push("/");
  };
  return (
    <div className="main-wrapper course-page">
      <button className="btn btn-primary" onClick={goBack} style={{ position: "fixed", bottom: "20px", left: "10px", zIndex: 999, width: "30px" }}><i class="fa-solid fa-arrow-left"></i></button>
      <HeaderTwo />
      {console.log(item, "check item")}
      <Styles>
        <section className="course-list-area">
          <Container>
            <Row>
              <Col lg="3" md="4" sm="5">

                <StProgramSidebar types={updatedCategory} ctgFn={ctgSearch}
                  dispAllPg={""} searchData={searchData}
                />
              </Col>
              <Col lg="9" md="8" sm="7">
                <div className="course-items2">
                  <Row>
                    <Fragment>


                      {
                       records.length>0? records?.map((data, i) => {

                          let imageid = 'https://visualpathedu.com/static/media/4th-year.15b862d606512e3257c0.jpg';
                          if (data?.image) {
                            imageid = data.image;
                          } else if (data?.image_url) {
                            imageid = data.image_url;
                          }
                          else if (data?.imageurl) {
                            imageid = data.imageurl ? data.imageurl : "";
                          }

                          return <Col md="12" key={i}>

                            <div className="course-item d-flex" onClick={() => loginValidate(data)}>
                              <div className="course-image-box">
                                <div className="course-image">
                                  <div className="author-img ">
                                    <div className="img">

                                    </div>

                                  </div>

                                  <img src={imageid} className="course-image" ></img>
                                  <div className="sale-div">
                                    {data?.on_sale == 'Y' && <p>Sale</p>}
                                  </div>
                                  <div className="course-price">

                                    {Number(data?.sale_price) > 0 ? <p>Rs.{data?.sale_price} <span className='price-div'  >Rs.{data?.price}</span> </p> : <p>Free</p>}
                                  </div>

                                </div>
                              </div>
                              <div className="course-content">
                                <h6 className="heading">{data.name}</h6>
                                <div className="rating">
                                  <ul className="list-unstyled list-inline">
                                    <p className='duration2' ><i class="fa-regular fa-clock projects-icon"></i>duration:{data.duration}</p>

                                  </ul>
                                </div>

                                <p className="desc2">{data.description?.slice(0, 200)}</p>
                                <button className="details-btn">View Details</button>

                              </div>

                            </div>

                          </Col>
                        }) : <Skeleton height={210} count={4} />
                      }

                      {showError && <Skeleton height={180} count={4} /> }


                      {/* <Pagination /> */}

                      {!showError && <Col md="12" className="text-center">
                        <div className='mainpagination'>
                          <ul className='pagination'>
                            <li className='page-item'>
                              <label className=' btn btn-outline-success page-link' onClick={prePage}><i class="fa-solid fa-angles-left"></i></label>

                            </li>
                            {numbers.map((n, i) => (
                              <li className={`page-item ${currentPage === n ? 'active' : ""}`} key={i}>
                                <label className='btn btn-outline-success page-link' onClick={() => changeCPage(n)}>{n}</label>

                              </li>

                            ))}

                            <li className='page-item'>
                              <label className='btn btn-outline-success page-link' onClick={nextPage}><i class="fa-solid fa-angles-right"></i></label>

                            </li>


                          </ul>
                        </div>


                      </Col>}
                      {modalUp && <LoginModal onClose={openModal} login={logIn}></LoginModal>}

                    </Fragment>

                    {item?.on_sale == 'Y' && !item.ispurchased && subscribed == false && <Modal show={show} onHide={handleClose} style={{ textAlign: 'center' }}>
                      <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>Please purchase the course to view the video content ?</Modal.Body>
                      <Modal.Footer>
                        <Button variant="btn btn-outline-success" onClick={handlePurchase}>
                          PURCHASE
                        </Button>
                        {/* <Link to='lms-page'> */}
                        <Button variant="btn btn-outline-dark" onClick={previewFun}  >
                          PREVIEW
                        </Button>
                        {/* </Link> */}

                        <Button variant="btn btn-outline-dark" onClick={handleClose}>
                          CANCEL
                        </Button>
                      </Modal.Footer>
                    </Modal>}


                    {item?.on_sale == 'Y' && purchased && <Modal show={purchased} onHide={() => setPurchased(false)} style={{ textAlign: 'center' }}>
                      <Modal.Header closeButton>
                        <Modal.Title>Purchase Confirmation</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>'You have already purchased the course,You can check under My Courses or you can preview the same here'</Modal.Body>
                      <Modal.Footer>
                        <button className="bg-dark p-2 rounded" >
                          <Link className="text-light" to="/MyCourses">
                            My Courses
                          </Link>
                        </button>
                        {/* <Link to='lms-page'> */}
                        <Button variant="btn btn-outline-dark" onClick={previewFun}  >
                          PREVIEW
                        </Button>
                        {/* </Link> */}

                        <Button variant="btn btn-outline-dark" onClick={() => setPurchased(false)}>
                          CANCEL
                        </Button>
                      </Modal.Footer>
                    </Modal>}

                    {item?.on_sale == 'N' && purchased && <Modal show={purchased} onHide={() => setPurchased(false)} style={{ textAlign: 'center' }}>
                      <Modal.Header closeButton>
                        <Modal.Title>Purchase Confirmation</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>'You have already purchased the course,You can check under My Courses or you can preview the same here'</Modal.Body>
                      <Modal.Footer>
                        <button className="bg-dark p-2 rounded" >
                          <Link className="text-light" to="/MyCourses">
                            My Courses
                          </Link>
                        </button>
                        {/* <Link to='lms-page'> */}
                        <Button variant="btn btn-outline-dark" onClick={previewFun}  >
                          PREVIEW
                        </Button>
                        {/* </Link> */}

                        <Button variant="btn btn-outline-dark" onClick={() => setPurchased(false)}>
                          CANCEL
                        </Button>
                      </Modal.Footer>
                    </Modal>}
                    {console.log(purchased, "check purchase status", item?.on_sale, "chek on sale", crt, "check crt stats")}
                    {item?.on_sale == 'N' && purchased == false && <Modal show={crt} onHide={() => setCrt(false)} style={{ textAlign: 'center' }}>
                      <Modal.Header closeButton>
                        <Modal.Title>Program Enrollment</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>'Enroll now and start your program training '</Modal.Body>
                      <Modal.Footer>
                        <Button variant="btn btn-outline-dark" onClick={handleFreeEnroll}  >
                          Enroll
                        </Button>
                        {/* <Link to='lms-page'> */}
                        <Button variant="btn btn-outline-dark" onClick={previewFun}  >
                          PREVIEW
                        </Button>
                        {/* </Link> */}

                        <Button variant="btn btn-outline-dark" onClick={() => setCrt(false)}>
                          CANCEL
                        </Button>
                      </Modal.Footer>
                    </Modal>}



                    {itemModal && <Modal show={itemModal} style={{ textAlign: 'center' }}>
                      <Modal.Header>
                        <Modal.Title><h2>Discount Coupon</h2>
                          <span className='m-3'>Enter discount coupon code  if you have any and cick on OK else just click Continue to proceed.</span>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <input onChange={(e) => { setCouponcodeNo(e.target.value) }} /> please enter coupon
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setItemModal(false) }}>
                          CANCEL
                        </Button>
                        <Button variant="dark" onClick={() => { setCouponcodeNo(0); setItemModal(false); handleProceed() }}>
                          <b>Continue without coupon code</b>
                        </Button>
                        <Button variant="primary" onClick={() => { setItemModal(false); handleProceed() }}>
                          <b>OK</b>
                        </Button>
                      </Modal.Footer>
                    </Modal>}


                    {confirmation && <Modal show={confirmation} style={{ textAlign: 'center' }}>
                      <Modal.Header>
                        <Modal.Title><h2>Confirmation !!</h2>

                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <h6>your are about to do a Payment of {finalAmount} Rupees for {item.name} program</h6>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setConformation(false) }}>
                          CANCEL
                        </Button>

                        <Button variant="primary" onClick={() => hashChangeEvent2()}>
                          Proceed to pay
                        </Button>
                      </Modal.Footer>
                    </Modal>}
                    {enrollFree && <Modal show={enrollFree} style={{ textAlign: 'center' }}>
                      <Modal.Header>
                        <Modal.Title>You have succesfully applied the offer coupon code ,Please click on enroll now to purchase the free program</Modal.Title>
                      </Modal.Header>
                      {/* <Modal.Body>
          <input onChange={(e) => { setCouponcodeNo(e.target.value) }} /> please enter coupon
        </Modal.Body> */}
                      <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setEnrollFree(false) }}>
                          CANCEL
                        </Button>
                        <Button variant="primary" onClick={handleFreeEnroll}>
                          OK
                        </Button>
                      </Modal.Footer>
                    </Modal>}


                    {purchaseSUccessFull && <Modal show={purchaseSUccessFull} onHide={() => setPurchaseSUccessFull(false)} style={{ textAlign: 'center' }}>
                      <Modal.Header closeButton>
                        <Modal.Title>Course Purchases SuccessFull</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>'Please check your purchased course in MyCourses'</Modal.Body>
                      <Modal.Footer>
                        <button className="bg-dark p-2 rounded" >
                          <Link className="text-light" to="/MyCourses">
                            My Courses
                          </Link>
                        </button>

                      </Modal.Footer>
                    </Modal>}
                    {item?.on_sale == 'Y' && subscribed && <Modal show={subscribed} onHide={() => setSubscribed(false)} style={{ textAlign: 'center' }}>
                      <Modal.Header closeButton>
                        <Modal.Title>Program Enrollment</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>'Enroll now and start your program training '</Modal.Body>
                      <Modal.Footer>
                        <Button variant="btn btn-outline-dark" onClick={handleFreeEnroll}  >
                          Enroll
                        </Button>
                        {/* <Link to='lms-page'> */}
                        <Button variant="btn btn-outline-dark" onClick={previewFun}  >
                          PREVIEW
                        </Button>
                        {/* </Link> */}

                        <Button variant="btn btn-outline-dark" onClick={() => setSubscribed(false)}>
                          CANCEL
                        </Button>
                      </Modal.Footer>
                    </Modal>}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
      <FooterTwo />


    </div>
  )
}

export default CrtProgram;