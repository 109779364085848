import React, { useEffect } from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Toast } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Styles } from '../../pages/account/styles/account';
import { GetApi } from '../../services/CommonService2'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import Register from '../../pages/account/Register';
import { PostApi } from '../../services/CommonService2'



function Employee({ dataprop }) {


    const [data, setData] = useState([
    {
        key: 'Online',
        value: 'Online'
    }]);

    const [modeoftraining, setModeoftraining] = useState(undefined);
    const [course, setCourse] = useState(undefined);
    const [userdata, setUserData] = useState({});
    const [experience, setExperience] = useState('');





    useEffect(() => {

        loaddata();

    }, []);

    const loaddata = async () => {
        var tntId= JSON.parse(localStorage.getItem('tID'))
        const sessiondetails = await localStorage.getItem(`userdata${tntId}`);
        if (sessiondetails != null) {
            //const constuserdetails = JSON.parse(sessiondetails);
            setUserData(JSON.parse(sessiondetails));

        }
    }

    const submitForm = async (e) => {
        e.preventDefault()
        const reqdata = {
            userid: userdata.id,
            name: userdata.user.name,
            email: userdata.email,
            mobilenumber: userdata.username,
            modeoftraining: modeoftraining,
            course: course,
            experience: experience,
            modetype: 'Employee',
          
            ptype: 'TRAININGENROLLMENT'
        }

        // console.log("student reqdata", reqdata);
        const queryreq = {
            pageno: '-1',
            query: { userid: userdata.id },
            ptype: 'TRAININGENROLLMENT'

        }

        const Response = await PostApi(queryreq, 'TRAININGENROLLMENTGET');

        // console.log("TRAININGENROLLGet dara", Response.data);


        if (Response.data.length == 0) {

            const ResponseApi = await PostApi(reqdata, 'TRAININGENROLLMENT');
            console.log("TRAININGENROLLMENT dara", ResponseApi.data);

            if (ResponseApi.data.id) {


                toast.success('Enrollment successful', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {


                toast.error('Failed to enroll', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }

        } else {

            toast.error('You have already enroll for  training', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });



        }

    }





    return (
        <>

            <Styles>
                <section className="login-area">
                    <Container>
                        {/* {console.log("user datta in fresher modal",userdata)} */}
                        <Row>
                            <Col md="12">
                                <div className="login-box">
                                    {/* <div className="login-title text-center">
                                        <h3>Log In</h3>
                                    </div> */}
                                    <form id="form_login" className="form">
                                        <p className="form-control">
                                            <label htmlFor="Title">Select Mode of Training</label>
                                            <select
                                                value={modeoftraining} onChange={(e) => setModeoftraining(e.target.value)}>
                                                <option>training modes</option>
                                                {data.map((item) => (
                                                    <option key={item.key} value={item.key}>
                                                        {item.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="Title">Select Course</label>
                                            <select
                                                value={course} onChange={(e) => setCourse(e.target.value)}>
                                                <option>courses</option>
                                                {dataprop.map((item) => (
                                                    <option key={item.key} value={item.key}>
                                                        {item.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </p>

                                        <p className="form-control">
                                            <label htmlFor="Title">Years of experience</label>
                                            <input type="text" placeholder="experience" id="Title" name='title' value={experience} onChange={(e) => setExperience(e.target.value)} />
                                            <span className="login_input-msg"></span>
                                        </p>

                                        <button style={{ marginTop: "20px" }} onClick={submitForm}>Proceed</button>

                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>


            {/* <ToastContainer /> */}

        </>
    );
}

export default Employee;