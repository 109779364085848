import React, { useState } from 'react'
import { Box, Tab, ThemeProvider } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { createTheme } from '@mui/material/styles';
import MySubmissions from './MySubmissions';
import AllSubmissions from './AllSubmissions';
const theme = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: 'green', // Set the indicator color to green
                },
            },
        },
    },
});
function Submissions() {
    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  return (
    <div>
        <ThemeProvider theme={theme}>

       
         <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList textColor="info" onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                   
                                                    My Submissions
                                                </Box>
                                            } value="1" sx={{
                                                width: "50%",
                                                fontSize: '13px',
                                                color: value === '1' ? 'success.main' : 'black',
                                            }} />
                                            <Tab label={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                
                                                   All Submissions
                                                </Box>
                                            } value="2" sx={{
                                                width: "50%",
                                                fontSize: '13px',
                                                color: value === '2' ? 'success.main' : 'black',
                                            }} />
                                         
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                    <MySubmissions />
                                    </TabPanel>
                                    <TabPanel value="2">
                                     <AllSubmissions />
                                    </TabPanel>
                                    
                                </TabContext>
                            </Box>
                            </ThemeProvider>
    </div>
  )
}

export default Submissions