import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.js';
import { PostApi } from '../../../services/CommonService2.js';
import { Styles } from '../styles/courseCategory.js';
// import { toast } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function StateCategory(props) {
    const stateId=useLocation();



    const [universityData,setUniversityData]=useState([]);
    const [branches,setBranches]=useState([]);
    const [year,setYear]=useState([]);
    const [semister,setSemister]=useState([]);
    // const [postAllData,setPostAllData]=useState({universityDataid:"", branchid:"",academictypeid:"",semisterid:""});
    const [selected, setSelected] = useState(undefined);
    const [selected1, setSelected1] = useState(undefined);
    const [selected2, setSelected2] = useState(undefined);
    const [universities, setUniversity] = useState(undefined);

    //my var
    const [typeslist, settypesList] = useState([]);
    const [college,setCollege]=useState('')


    
    useEffect(() => {
        sectionFun();
      
    }, [])

    const sectionFun=async()=>{
        var tntId = JSON.parse(localStorage.getItem('tID'))
		const sessiondetails = localStorage.getItem(`userdata${tntId}`);
        const userdata = JSON.parse(sessiondetails);
        // setCollege(userdata?.user?.subapplicationid )
     
        var reqparam;
        if(userdata.user.studentedutype ){
            console.log(userdata?.user?.studentedutype,"inside if")
             reqparam = {
            pageno: '-1',
            query: { status: 'Active',edutype:userdata?.user?.studentedutype }

        }
        }else{
            console.log(userdata?.user?.studentedutype,"inside else")
             reqparam = {
                pageno: '-1',
                query: { status: 'Active',edutype:"btech" }
    
            }
        }
       

        var reqparams1 = '';
        if (stateId.state && (userdata?.user?.subapplicationid =='' || userdata?.user?.subapplicationid ==undefined )) {
            reqparams1 = {
            pageno: '-1',
            query: { status: "Active", stateid:stateId.state.data,edutype:userdata?.user?.studentedutype}
            }
          
        } else if (stateId.state && userdata?.user?.subapplicationid ) {
            reqparams1 = {
            pageno: '-1',
            query: { status: "Active", stateid:stateId.state.data,edutype:userdata?.user?.studentedutype,
            area:{
                $elemMatch: { "value": userdata?.user?.subapplicationid }
              }
            }
            }
          
        } 
        else {
            reqparams1 = {
                pageno: '-1',
                query: {
                    status: 'Active',edutype:userdata?.user?.studentedutype
                   }
                 }
              
        }

        console.log("reqparams1",reqparams1);
           const ResponseData = await PostApi(reqparam,'BRANCHES');
           const ResponseData2 = await PostApi(reqparam,'ACADEMICTYPE');
           const ResponseData3 = await PostApi(reqparam,'SEMISTER');
           const ResponseData4 = await PostApi(reqparams1,'UNIVERSITY');
        
        //    setSemister(ResponseData3.data);
        //    setBranches(ResponseData.data);
        //    setYear(ResponseData2.data);
        //    setUniversityData(ResponseData4.data);

           console.log("universityDataResponse",ResponseData4.data);

           let count = 0;
           const rowdat = [];
           await ResponseData4?.data.reduce(async (promise, res) => {
               await promise;
               const opts = {
                   key: res.id,
                   value: res.name
               }
               rowdat.push(opts);
               if (count == ResponseData4.data.length - 1) {
   
                setUniversityData(rowdat);
                //    console.log("rowdat",rowdat);
               }
               count++;
           }, Promise.resolve());

           let counter1 = 0;
           let counter2 = 0;
           let counter3 = 0;
           const rowdata1 = [{
               key: 1,
               value: 'Sem1'
           },
           {
               key: 2,
               value: 'Sem2'
           }
           ];
           const rowdata2 = [];
           const rowdata3 = [
            
               {
                   key: 1,
                   value: '1st Year',
                   studentedutype: 'btech'
               },
               {
                   key: 2,
                   value: '2nd Year',
                   studentedutype: 'btech'
               },
               {
                   key: 3,
                   value: '3rd Year',
                   studentedutype: 'btech'
               },
               {
                   key: 4,
                   value: '4th Year',
                   studentedutype: 'btech'
               },
              
           ];

           setSemister(rowdata1);
         
           setYear(rowdata3);


           await ResponseData.data.reduce(async (promise, res) => {
            await promise;
            const opts = {
                key: res.id,
                value: res.name
            }
            rowdata2.push(opts);
            if (counter2 == ResponseData?.data.length - 1) {

                setBranches(rowdata2);
                // console.log("rowdat2",rowdata2);

            }
            counter2++;
        }, Promise.resolve());
   }
 
var submition=(e)=>{
    e.preventDefault()
    // console.log("selected1===========",selected1);
        if (selected1 == undefined || selected2 == undefined || selected == undefined
            || universities == undefined || selected1 == '' || selected2 == '' || selected == ''
            || universities == '') {

            // toast.error("All the fields are mandatory", {
            //     position: "top-right",
            //     autoClose: 5000,
            // });
            toast.info('Please select all the fields to check courses', {
                position: "top-center",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            props.setStateData([])

        } else {
           
            const reqdata = {
             branchid: selected,
             semisterid: selected2,
             academictypeid: selected1,
             universityid: universities,
         }
// console.log('checking===============',reqdata);
            props.setStateData(reqdata)
        }

}


        return (
            <Styles>
             
                <div className="course-category">
                    <h5>Academic Subjects</h5>
                    <form >
                    <ul className="category-item1 list-unstyled">
                        <li className="check-btn">
  
                            <select value={universities}  onChange={(e) => setUniversity(e.target.value)} className="form-control" name='universityDataid'>
                                <option value="" >Select universityData</option>
                                {universityData?.map((item) => {
                                    return (
                                        <option class="dropdown-item" key={item.key} value={item.key}>
                                                        {item.value}
                                                    </option>
                                    )
                                })}
                            </select>

                        </li><br></br>
                        <li className="check-btn">
                            <select value={selected} onChange={(e) => setSelected(e.target.value)} className="form-control" name='branchid'>
                                <option value="" >Select Branches</option>
                                {branches?.map((item) => {
                                    return (
                                       <option class="dropdown-item" key={item.key} value={item.key}>
                                                        {item.value}
                                                    </option>
                                    )
                                })}
                            </select>
                        </li><br></br>
                        <li className="check-btn">
                        <select  value={selected1} onChange={(e) => setSelected1(e.target.value)} name='academictypeid'>
                            <option value="" >Select Year</option>
                            {/* {console.log(year,"year")} */}
                                {year?.map((item)=>{
                                    return(
                                // <option class="dropdown-item" value={item.id}>{item.name}</option>
                                <option class="dropdown-item" key={item.key} value={item.key}>
                                                        {item.value}
                                                    </option>
                                )
                            })}
                               </select> 
                        </li><br></br>
                        <li className="check-btn">
                        <select value={selected2} onChange={(e) => setSelected2(e.target.value)} name='semisterid'>
                            <option value="" >Select Semister</option>
                            {/* {console.log(semister,"semister")} */}
                                {semister?.map((item)=>{
                                    return(
                                // <option class="dropdown-item" value={item.id}>{item.name}</option>
                                <option class="dropdown-item" key={item.key} value={item.key}>
                                {item.value}
                            </option>
                                )
                            })}
                               </select> 
                        </li><br></br>
                        <li className=' search-btn' >
                            <button onClick={submition} className='btn btn-dark'>Search</button>
                        </li>
                    </ul>
                </form>

            </div>
        </Styles>
    )
}

export default StateCategory
