import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

function Program(props) {
  const [itemid, setItemId] = useState("")
  const [show, setShow] = useState(false)
  const [userid, setUserId] = useState("")
  var data2 = useLocation();
  var getData = data2?.state
  console.log("data2",data2);
  useEffect(() => {
    var tntId = JSON.parse(localStorage.getItem('tID'))
    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    if (sessiondetails != null) {
      const userdata = JSON.parse(sessiondetails);
      setUserId(userdata.id)
    }
    setItemId(getData?.id)

  }, [data2])

  const handleClose = () => {
    setShow(false)
  }
  const hadleCompetative = () => {
    setShow(true)
  }
  return (
    <div>
      <div className="row">

        <div className='col-6'>
          <Modal show={show} onHide={handleClose} style={{ width: "367px", overflow: "hidden", marginLeft: "30%" }} aria-labelledby="contained-modal-title-vcenter"
            centered>

            <Modal.Header closeButton="true" >
              <h3 style={{ padding: "0px 20px" }}></h3>
              <hr />
            </Modal.Header>


            <iframe src={"https://techbricksedu.com/competitive/"+ userid + "/" +itemid } style={{ minHeight: "460px", overflowX:"scroll" }}></iframe>
            
               {/* <iframe src={"http://localhost:19006/competitive/"+ userid + "/" +itemid } style={{ minHeight: "460px", overflowX:"scroll" }}></iframe> */}



          </Modal>

          <a onClick={hadleCompetative}><img src={getData?.image}></img></a>
          <h5>{getData?.examname}</h5>

        </div>
      </div>
    </div>

  )
}

export default Program