import React, {  useState,useEffect } from 'react';
import HeaderTwo from '../../components/HeaderTwo.js';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import AboutUs from '../../components/AboutUs';
import IconBox from '../../components/IconBox';
import TabBox from './../../components/TabBox';
import TestimonialSlider from '../../components/TestimonialSlider';
import FaqEvent from '../../components/FaqEvent';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from "./styles/about.js";
import Header3 from '../../home3components/Header3.jsx';

function About  () {
    const [tenantId,setTenantId] = useState('')
    useEffect(() => {
        var tntId= JSON.parse(localStorage.getItem('tID'))
        setTenantId(tntId)
    },[])
        return (
            <Styles>
                {console.log(tenantId," tenantId tenantId")}
                {/* Main Wrapper */}
                <div className="main-wrapper about-page">

                    {/* Header 2 */}
                    {tenantId == "ffe21ec9" ? <Header3 /> : <HeaderTwo />}

                    {/* Breadcroumb */}
                    {/* <BreadcrumbBox title="About Us" /> */}

                    {/* About Area */}
                    {/* <AboutUs /> */}

                    {/* Icon Box Area */}
                    {/* <IconBox /> */}

                    {/* Tab Section */}
                    <TabBox />

                    {/* Testimonial Slider */}
                    {/* <TestimonialSlider /> */}

                    {/* Faq & Event Area */}
                    {/* <FaqEvent /> */}

                    {/* Footer 2 */}
                    {/* <FooterTwo /> */}

                </div>
            </Styles>
        )
    
}

export default About