import React, { Component } from 'react';
import { Styles } from '../styles/courseCategory.js';

const StProgramCategory = (props) => {


const handleAllPrograms=()=>{

}
    return (
        <Styles>
            {/* Course Tag */}
            <div className="course-category" >
                <h5>Program Category</h5>
                <ul className="category-item list-unstyled">
                    {props.types?.map((type, ind) => {
                        return (<>
                            {/* {ind == 0 && <li className="check-btn">
                            {ind == 0 && <li className="check-btn" style={{ display: "flex" }}>
                                <input type='checkbox' id="All" name='All' onChange={(e) => props.ctgFn(e, false)} checked={props.dispAllPg} />
                                <label htmlFor="All" >All</label>
                            </li>} */}
                            {/* {ind == 0 ?
                             <li className="check-btn" style={{ display: "flex" }}>
                                <input type='checkbox' id={`${ind}`} onChange={(e) => props.ctgFn(e, type.id)} name={type.name} checked={props.dispAllPg} />
                                <label htmlFor={`${ind}`} style={{ marginLeft: "10px" }}>{type.name}</label>
                            </li>: */}
                            <li className="check-btn" style={{ display: "flex" }} key={ind}>
                                <input type='checkbox' id={`${ind}`} onChange={(e) => props.ctgFn(e, type.id)} name={type.name} checked={type.check} />
                                <label htmlFor={`${ind}`} style={{ marginLeft: "10px" }}>{type.name}</label>
                            </li>
                            {/* } */}
                        </>)
                    })}
                    {/* <li className="check-btn">
                            <label htmlFor="cat2"><input type="checkbox" id="cat2" className="check-box" />Graphic Design<span>(11)</span></label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat3"><input type="checkbox" id="cat3" className="check-box" />Php Programming<span>(17)</span></label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat4"><input type="checkbox" id="cat4" className="check-box" />React Js Learning<span>(23)</span></label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat5"><input type="checkbox" id="cat5" className="check-box" />Web Design<span>(19)</span></label>
                        </li> */}
                </ul>
            </div>
        </Styles>
    )
}

export default StProgramCategory
