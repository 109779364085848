import React, { Component, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo.js';
import { BreadcrumbBox } from '../../components/common/Breadcrumb.js';
import FooterTwo from '../../components/FooterTwo.js';
import StateItemGrid from './components/StateItemGrid.js';
import { Styles } from './styles/course';
import StateSidebar from './components/StateSidebar.js';
import StateCategory from './components/StateCategory.js';
import { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const StateClass=()=>{

    const [searchData,setSearchData]=useState("")
    const [stateData,setStateData]=useState([])
    const location = useLocation();
    const [data,setdata]=useState([])

    useEffect(()=>{
        localStorage.removeItem('previewtype')
    },[]);

    

    useEffect(()=>{
        setStateData([])
        setSearchData("")
    },[location])

        return (
            <div className="main-wrapper course-page">
                {/* {console.log("course data",data)} */}
                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                {/* <BreadcrumbBox title="Courses" /> */}

                <Styles>
                    {/* Course Grid */}
                    <section className="course-grid-area">
                        <Container>
                            <Row>
                                {data?.length>0 && <Col lg="3" md="4" sm="5">
                                   <StateSidebar setSearchData={setSearchData} />
                                </Col>}
                                
                                <Col lg="9" md="8" sm="7">
                                    
                                        <Row>
                                       <StateCategory setStateData={setStateData} />
                                        </Row>
                                    
                                </Col>
                               
                            </Row>
                            <Row>
                            <Col lg="12"  sm="7">
                                    <div className="course-items">
                                        <Row>
                                        <StateItemGrid searchData={searchData} stateData={stateData} setdata={setdata}
                                         />
                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Container>
                    </section>
                </Styles>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        )
    

}

export default StateClass