import React, { useState } from "react";

import { styles } from "../styles";

import ChatEngine from "./ChatEngine";
import EmailForm from "./EmailForm";

const SupportWindow = props => {
    const [user, setUser] = useState(null)
    const [chat, setChat] = useState(null)

    return (
        <div 
            className='transition-5'
            style={{
                
                ...props?.visible&&styles.supportWindow,
                ...{ opacity: props?.visible ? '1' : '0' ,position:props?.visible ?'fixed':'absolute',zIndex:props?.visible ?'99999':'0'}
            }}
        >
            <EmailForm 
                visible={user === null || chat === null}
                setUser={user => setUser(user)} 
                setChat={chat => setChat(chat)} 
                modelData={props?.modelData}
            />

            <ChatEngine 
                visible={user !== null && chat !== null}
                user={user} 
                chat={chat} 
            />
        </div>
    )
}

export default SupportWindow;
