import React from 'react'
import CourseFilter from '../components/CourseFilter'
import HeaderTwo from '../components/HeaderTwo'
import FooterTwo from '../components/FooterTwo'

function Compititive() {
  return (
    <div>
      <HeaderTwo />
      <CourseFilter/>
      <FooterTwo/>
      </div>
  )
}

export default Compititive