import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { TbRefresh } from "react-icons/tb";
import Table from 'react-bootstrap/Table';
function AllSubmissions() {
  return (
   <>
    <div className='d-flex justify-content-end'>
    <Dropdown>
                     <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Languages
                     </Dropdown.Toggle>
 
                     <Dropdown.Menu>
                         <Dropdown.Item >Java</Dropdown.Item>
                         <Dropdown.Item >C</Dropdown.Item>
                         <Dropdown.Item >C++</Dropdown.Item>
                         <Dropdown.Item >Python</Dropdown.Item>
                     </Dropdown.Menu>
                 </Dropdown>
                 <div>
                 <TbRefresh className='fs-1' style={{marginLeft:'10px'}} /> <span className='fs-5 pt-1 d-inline-block'>Refresh</span>
                 </div>
    </div>
    <div className='d-flex justify-content-center mt-5'>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>
          Time (IST)</th>
          <th>	Status</th>
          <th>User</th>
          <th>Lang</th>
          <th>Code</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>2024-06-11 10:57:47</td>
          <td>	Wrong</td>
          <td>shivakumac5gf</td>
          <td>Java</td>
          <td>View</td>
        </tr>
        <tr>
          <td>2024-06-11 10:57:49</td>
          <td>	Wrong</td>
          <td>dhanush</td>
          <td>python</td>
          <td>View</td>
        </tr>
        <tr>
          <td>2024-06-11 10:57:21</td>
          <td>	Wrong</td>
          <td>Vicky</td>
          <td>Nodejs</td>
          <td>View</td>
        </tr>
        <tr>
          <td>2024-06-11 10:55:31</td>
          <td>	Wrong</td>
          <td>Swapna</td>
          <td>Devops</td>
          <td>View</td>
        </tr>
        <tr>
          <td>2024-06-11 10:59:22</td>
          <td>	Wrong</td>
          <td>Gopi Krishna</td>
          <td>C++</td>
          <td>View</td>
        </tr>
        <tr>
          <td>2024-06-11 10:52:48</td>
          <td>	Wrong</td>
          <td>Venu</td>
          <td>Node</td>
          <td>View</td>
        </tr>
        <tr>
          <td>2024-06-11 10:20:20</td>
          <td>	Wrong</td>
          <td>Mohan</td>
          <td>C</td>
          <td>View</td>
        </tr>
        <tr>
          <td>2024-06-11 10:57:49</td>
          <td>	Wrong</td>
          <td>Rajesh</td>
          <td>Dot-net</td>
          <td>View</td>
        </tr>

      </tbody>
    </Table>
     </div>
   </>
  )
}

export default AllSubmissions