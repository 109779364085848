import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import CourseSearch from './CourseSearch';
import CoursePrice from './CoursePrice';
import PopularCourse from './PopularCourse';
import CourseTag from './CourseTag';
import CourseCategory from './CourseCategory';
import ProgramSearch from './ProgramSearch';
import CompetitiveCategory from './CompetitiveCategory';

const CompetitiveSidebar=(props)=> {
   
        return (
            <div className="course-sidebar">
                <Row>
                    <Col md="12">
                        {/* <CourseSearch setSearchData={props.setSearchData}/> */}
                        <ProgramSearch searchData={props.searchBar}/>
                    </Col>
                    <Col md="12">
                        {/* <CourseCategory updatedCategory={props.updatedCategory} ctgFn={props.ctgFn} 
                        dispAllPg={props.dispAllPg}/> */}

                        <CompetitiveCategory updatedCategory={props.updatedCategory} ctgFn={props.ctgFn} 
                        dispAllPg={props.dispAllPg}/>
                    </Col>
                    <Col md="12">
                        {/* <CoursePrice /> */}
                    </Col>
                    <Col md="12">
                        {/* <PopularCourse /> */}
                    </Col>
                    <Col md="12">
                        {/* <CourseTag /> */}
                    </Col>
                </Row>
            </div>
        )
    
}

export default CompetitiveSidebar
