import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { toastContainer, toast } from 'react-toastify';
import { GetApi, PostApi } from '../services/CommonService2.js';
import 'react-toastify/dist/ReactToastify.css';
import './PaymentElement.css';
import { Modal } from 'react-bootstrap';

function StripeForm({ tenantData, item , pgitem, itemModal1}) {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState();
  const [couponcodeNo, setCouponcodeNo] = useState();
  const [reqParams, setReqParams] = useState({});
  const [enrollment, setEnrollment] = useState(false);
  const [couponPopup, setCouponPopup] = useState(false);
  // const [itemModal1,setItemModal1]=useState(itemModal)
  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#555',
      },
      invalid: {
        color: '#ff3860',
      },
    },
  };


  useEffect(() => {
    var loadFun = async () => {
      var tntId= JSON.parse(localStorage.getItem('tID'))
      const sessiondetails = await localStorage.getItem(`userdata${tntId}`);
      
      if (sessiondetails != null) {
        const userdata = JSON.parse(sessiondetails);
        if (item && (item != '' || item != null)) {
          if(item.on_sale == 'Y' && !item.ispurchased){
            localStorage.setItem('previewtype','Y')
          }
          else if(item.on_sale == 'Y' && item.ispurchased) {
            localStorage.setItem('previewtype','Y')
          }
          else if (item?.canenroll == 'Y') {
            if (item.ispurchased) {
              localStorage.setItem('previewtype','Y')
            }
          }
          else if(item.on_sale == 'N'){
            localStorage.setItem('previewtype','N');
          }
          setCouponPopup(true);
          // if(item.origin_price<0){
          // setItemModal1(false)}
        }
        else if(pgitem&&(pgitem!=''||pgitem!=null)){
          setEnrollment(true)
        }
        else {
          var reqParams2={ name:userdata.username , amount: tenantData?.offeramount, userid: userdata.id, courseid: '0', couponcode: '0' };
          const getreqdata = await GetApi(reqParams2, 'STRIPEKEY');
          setClientSecret(getreqdata);
        }
      }
     
    };
    loadFun();
  }, [item,tenantData]);

  const handleSubmit = async (e) => {
    if (!stripe || !elements) {
      return;
    }
    setIsProcessing(true);
   
      if(clientSecret.message == 'SUCCESS'){
      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret.data, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (error) {
        toast.error('Payment failed!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        toast.success('Payment successful!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
   
  }
  else if (clientSecret.message == 'INVALIDCOUPONCODE') {
    toast.error('Invalid coupon code', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
}
else if (clientSecret.message == 'COUPONAMOUNTMORE') {

    toast.error('coupon amount value is more than the course amount please choose a different course', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    
}

else if (clientSecret.message == 'FREECOURSE') {
 setEnrollment(true);

}



    setIsProcessing(false);
  };
// close
  const hashChangeEvent = async() => {
    var tntId= JSON.parse(localStorage.getItem('tID'))
    const sessiondetails =localStorage.getItem(`userdata${tntId}`);
      const userdata = JSON.parse(sessiondetails);
      // console.log("userdata====",userdata);
    var reqParams2={ name:userdata.username,amount: item.sale_price, courseid: item.id, couponcode: '0' };
    setCouponPopup(false);
    const getreqdata = await GetApi(reqParams2, 'STRIPEKEY');
    setClientSecret(getreqdata);
  };
//ok
  const hashChangeEvent2 =async () => {
    var tntId= JSON.parse(localStorage.getItem('tID'))
    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
      const userdata = JSON.parse(sessiondetails);
    setCouponPopup(false);
    var reqParams2={name:userdata.username, amount: item.sale_price, courseid: item.id, couponcode: couponcodeNo }
    const getreqdata = await GetApi(reqParams2, 'STRIPEKEY');
    setClientSecret(getreqdata);
  };

  // enrollmentFun
  var enrollmentFun= async()=>{
    var tntId= JSON.parse(localStorage.getItem('tID'))
    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    if(sessiondetails!=''|| sessiondetails!=null){
      const userdata = JSON.parse(sessiondetails);
    const reqdata = {
      programid:pgitem&&pgitem.id,
      userid: userdata.id,
      couponcode: couponcodeNo?couponcodeNo:'0',
      name:'venu',
      query:{}
      // tenantid: 'bb54fcfc',

  }
  const resp = await PostApi(reqdata, 'ENROLL');
  if (resp.message == 'SUCCESS') {
    toast.show({
      type: 'Success',
      text1: 'enrolled couse under my courses',
      position: 'top'
  });
  } else {
      toast.show({
          type: 'success',
          text1: 'Failed to enroll your course',
          position: 'top'
      });
  }}
  }
  return (
    <div>
      {/* {console.log('clientSecret-message=====',item)} */}
{/* coupon popup  */}
{(itemModal1||tenantData)&&
<div>
      <Modal show={couponPopup} style={{textAlign: 'center'}}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input onChange={(e) => { setCouponcodeNo(e.target.value) }} /> please enter coupon
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hashChangeEvent}>
            CANCEL
          </Button>
          <Button variant="primary" onClick={hashChangeEvent2}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <CardElement options={cardElementOptions} className="payment-element" />
      <Button variant="primary" disabled={isProcessing} onClick={handleSubmit}>
        <span id="button-text">{isProcessing ? 'Processing ... ' : 'Pay now'}</span>
      </Button>
      </div>}
      
      

      {/* Enroll popup */}

      <Modal show={enrollment} style={{textAlign: 'center'}}>
        <Modal.Header>
          <Modal.Title>Program Enrollment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        You have succesfully applied the offer coupon code ,Please click on enroll now to purchase the free program
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setEnrollment(false)}}>
            CANCEL
          </Button>
          <Button variant="primary" onClick={enrollmentFun}>
          Enroll
          </Button>
        </Modal.Footer>
      </Modal>



      
    </div>
  );
}

export default StripeForm;
