import React from 'react'
import { FaUser } from "react-icons/fa";
function UserComment() {
    return (
        <div className='row p-0 mb-3'>
            <div className='col-lg-1'>
                <FaUser className='fs-1 p-2 border border-dark border-2 rounded-circle text-dark' />

            </div>
            <div className='col-lg-11'>
                <h5 className='d-inline'>practice content</h5> <span className='mx-3 fw-bold'>9 months ago</span>
                <p className='mt-2 fs-6'>Hi Geeks, <br />
I have written an O(n) solution for given problem <br />
but also i am geting TLE problem <br />
is there better way that O(n),if so, can you proivde an hint<br />
here is my code </p>
            </div>
        </div>
    )
}

export default UserComment