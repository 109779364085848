import React from 'react'
import SideBar from './SideBar'
import MainContainer from './MainContainer'
import HeaderTwo from '../components/HeaderTwo'
import FooterTwo from '../components/FooterTwo'
function CodingHome() {
  return (
 <>
 <HeaderTwo />
    <div className='row p-0 m-0' style={{backgroundColor:"#3b444b"}}>
<div className='col-lg-3'>
<SideBar  />
</div>
<div className='col-lg-9'>
<MainContainer />
</div>
    </div>
    <FooterTwo />
 </>
  )
}

export default CodingHome