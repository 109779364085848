import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Styles } from "./styles/mobileMenu.js";
import { useState } from 'react';
import { PostApi, GetApi } from '../../services/CommonService2.js';
import LoginModal from '../LoginModal.js';
import Register from '../../pages/account/Register.js';
import { Tab, Nav, Button } from 'react-bootstrap';
import Student from '../offlineEnroll/Student.js';
import Fresher from '../offlineEnroll/Fresher.js';
import Employee from '../offlineEnroll/Employee.js';
import { TabStyles } from '../../pages/faq/styles/tab.js';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MobileMenu() {
    const [headerTabs, setHeaderTabs] = useState([])
    const [toggleUser, setToggleUser] = useState(false)
    const [user, setUser] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [userMobile, setUserMobile] = useState("")
    const [modalOpen, setModalOpen] = useState(false)
    const [postModal, setPostModal] = useState(false);
    const [courses, setCourses] = useState([]);
    const [offlineModalShow, setOfflineModalShow] = useState(false)
    const [tenantdata, setTenantData] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);










    
    useEffect(() => {
        setToggleUser(prevState => !prevState)
    }, [])


    useEffect(()=>{
        headerTypes()
        getTrainingRegistrationData()
        var tntId= JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))

        if (userdata == null) {
            setUser("")
        }
        else if (userdata.accessToken) {
            setUser(userdata.user.name)
            setUserMobile(userdata.user.mobileno)
        }

    },[toggleUser])

    const headerTypes = async () => {
        const reqparams = {
            pageno: '-1',
            query: { status: 'Active' },
            // tenantid: "7e71674b"
            // ptype: 'VPTTYPES'
        }

        const dataResponse = await PostApi(reqparams, 'VPTTYPES');

        // console.log("VPT headers", dataResponse.data);
        setHeaderTabs(dataResponse?.data)
    }

    useEffect(() => {
        // Mobile Menu
        // console.log("hiiiiii");
        const hmBtn = document.getElementById("mb-sidebar-btn");

        if (hmBtn) {
            const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
            const mdSidebarBody = document.getElementById("mb-sidebar-body");
            const mdSidebarExit = document.getElementById("close-mb-sidebar");

            hmBtn.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.toggle("visible");
                mdSidebarBody.classList.toggle("opened");
            });

            mdSidebarOverlay.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.remove("visible");
                mdSidebarBody.classList.remove("opened");
            });

            mdSidebarExit.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.remove("visible");
                mdSidebarBody.classList.remove("opened");
            });
        }

        // Menu Accordion -----------------
        const menuButton = document.querySelectorAll(".mb-menu-button");
        menuButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "mb-menu-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "mb-menu-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    },[]);

    const logOut = async (e) => {
        // await removeToken()
        const result = window.confirm('Are you sure you want to logout ?');
        // console.log(result);
        if(result){

            // console.log("logoutConfirmed");

            const reqparams ={
              mobileno:userMobile
              // mobileno:"9391645909"
  
          }
          const clearsessionrespone = await PostApi(reqparams,'LOGOUT');
        //   console.log("clearsessionrespone",clearsessionrespone);
        var tntId=JSON.parse(localStorage.getItem('tID'))

          localStorage.removeItem(`userdata${tntId}`)
  
          logIn()
  
          setShowModal(false)
  
          window.location.href = '/'


        }
       

        // openModal()
        // setToggleUser(prevState=>!prevState)

    }
    const logIn = () => {
        setToggleUser(prevState => !prevState)

    }

    const openModal = (e) => {
        
        setModalOpen((prevState) => !prevState);
    }

    const openRegisterModal = () => {
        setPostModal(true)
        // onClose()
    }

    const closemodal = () => {
        setPostModal(false)
        // setModalShow(true)

    }


    //delete user

    const deleteAccount = async () => {
        toast.success('Close menu to view account deactivation', {
            position: "top-right",
            autoClose: 5000,
            });

        setDeleteModal(true)

    }
    const clsDeleteModal = async () => {
        setDeleteModal(false)
    }


    const deactivateConfirmed = async () => {
        const query = {
            userid: '',
            tenantid: ''
        }
        const deleteResp = await PostApi(query, "DELETEACCOUNT");
        // console.log("deleteResp",deleteResp);
        if (deleteResp.message == 'SUCCESS') {
            var tntId=JSON.parse(localStorage.getItem('tID'))

            localStorage.removeItem(`userdata${tntId}`)
            logIn()
            setDeleteModal(false)
            window.location.href = '/'
            
        }
        else {
            toast.error("Failed to delete account", {
                position: "center",
                autoClose: 5000,

            })
        }

    }

    //offline Enroll

    const getTrainingRegistrationData = async () => {


        const reqparam2 = {
            pageno: '-1',
            query: {}
        }

        const ResponseData1 = await GetApi(reqparam2, 'TENANT');
        // console.log("response tenant data",ResponseData1);
        setTenantData(ResponseData1.data[0])


        const reqparams = {
            pageno: '-1',
            query: { status: 'Active' }
        }
        const courseslistResp = await PostApi(reqparams, 'TRAININGCOURSES');

        // console.log("TRAININGCOURSES datta", courseslistResp.data);

        let count = 0;
        const rowdat = [];
        await courseslistResp.data.reduce(async (promise, res) => {
            await promise;
            const opts = {
                key: res.id,
                value: res.name
            }
            rowdat.push(opts);
            if (count == courseslistResp.data.length - 1) {


                setCourses(rowdat)
            }
            count++;
        }, Promise.resolve());


    }

    function MyVerticallyCenteredModal(props) {

        if(offlineModalShow){
            toast.success('Close menu to check offline enrollment', {
                position: "top-right",
                autoClose: 5000,
                });
        }

       

        return (
            <Modal
                {...props}
            // size="lg"
            // aria-labelledby="contained-modal-title-vcenter"
            // centered
            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter" style={{ marginLeft: "auto" }}>
                        Enroll for offline/online class
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body >
                    <TabStyles >
                        <div className="main-wrapper faq-page">
                            <section className="faq-area" style={{ paddingTop: "2%" }}>
                                <Container>
                                    <Row>
                                        <Col md="12">
                                            <Tab.Container defaultActiveKey="student">
                                                <Nav className="justify-content-center">
                                                    <Nav.Item><Nav.Link eventKey="student">Student </Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="fresher">Fresher</Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="employee">Employee</Nav.Link></Nav.Item>
                                                </Nav>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="student">
                                                        <Row>

                                                            <Student dataprop={courses} />

                                                            

                                                        </Row>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="fresher">
                                                        <Row>

                                                            <Fresher dataprop={courses} />


                                                        </Row>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="employee">
                                                        <Row>

                                                            <Employee dataprop={courses} />


                                                        </Row>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </div>
                    </TabStyles>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
                {/* <ToastContainer /> */}
            </Modal>
        );
    }

    const loginValidate = async()=>{
        var tntId= JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        if(userdata==null){
            setModalOpen((prevState) => !prevState)
           
    
            toast.info('login to register for Offline Enrollment', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
      }

    return (
        <Styles>
            {/* Mobile Menu */}
            <section className="mobile-menu-area">
               
                <Container>
                    <Row>
                        <Col md="0" sm="12">
                            <div className="mb-topbar d-flex justify-content-between">
                                <div className="topbar-item">
                                    <p><i className="las la-phone"></i>+91 9030222849</p>
                                </div>
                                <div className="topbar-item">
                                   {!user && <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item" onClick={openModal}>Log In</li>
                                        <li className="list-inline-item">/</li>
                                        <li className="list-inline-item" onClick={openRegisterModal}>Register</li>
                                    </ul>}
                                    {user && <ul className="list-unstyled list-inline">
                                    <li className="list-inline-item">{user}</li>
                                        </ul>}
                                </div>
                            </div>
                            <div className="mb-logo-area d-flex justify-content-between">
                                <div className="mb-logo-box d-flex">
                                    <div className="hm-button">
                                        <a href={process.env.PUBLIC_URL + "/"} id="mb-sidebar-btn">
                                            <i className="las la-bars"></i>
                                        </a>
                                    </div>
                                    {/* <div className="mb-logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/f-logo.png"} alt="" /></Link>
                                    </div> */}
                                </div>
                                {/* <div className="mb-search-box">
                                    <form action="#">
                                        <input type="text" name="search" placeholder="Search Here" />
                                        <button type="submit"><i className="las la-search"></i></button>
                                    </form>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Mobile Menu Sidebar */}
            <section className="mb-sidebar" id="mb-sidebar-body">
                <div className="mb-sidebar-heading d-flex justify-content-between">
                    <div><h5>Menu</h5></div>
                    <div><a href={process.env.PUBLIC_URL + "/"} id="close-mb-sidebar"><i className="las la-times"></i></a></div>
                </div>
                <div className="mb-sidebar-menu">
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p>Home <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content show">
                            <ul className="list-unstyled">
                                <li><Link to={"/"}>Home </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p>Pages <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content show">
                            <ul className="list-unstyled">

                            {headerTabs?.map((tab, ind) => {
                                    let url = ""
                                    if (tab.name === 'Students') {
                                        url = '/allstuprogram-list'
                                    }
                                    if (tab.name === 'Academic Subjects') {
                                        url = '/subjects'
                                    }
                                    // if(tab.name === 'crt'){
                                    //     url='/crt-program'
                                    // }
                                    if (tab.name === 'Technical Courses') {
                                        url = '/course-list'
                                    }
                                    if (tab.name === 'CRT') {
                                        url = '/crt-program'
                                    }
                                    return (<li key={ind}>
                                        <Link  to={url} >{tab.name} </Link>
                                    </li>)
                                })}
                                {/* <li><Link to={process.env.PUBLIC_URL + "/about"}>About Us</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/gallery"}>Gallery</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/login"}>Log In</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/registration"}>Registration</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/faq"}>Faq</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/404"}>404</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/coming-soon"}>Coming Soon</Link></li> */}
                                {user && <li><Link  to={'/MyCourses'} >My Courses</Link></li>}

                              {tenantdata?.isofflineenrollment &&<li className="custom" onClick={() => user?setOfflineModalShow(true):loginValidate()}>Training Registration</li>}

                            </ul>
                        </div>
                    </div>
                     <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                        {user && <p> Profile <i className="las la-plus"></i></p>}
                        </button>
                        {user && <div className="mb-menu-content show">
                            <ul className="list-unstyled">
                                <li><Link to={"/about"}>My Profile</Link></li>
                                <li><Link to={"/changepassword"}>Change Password</Link></li>
                                <li className="custom" onClick={deleteAccount}>Deactivate account</li>
                                <li className="custom" onClick={logOut}>Logout</li>

                            </ul>
                        </div>}
                    </div>
                    {/* <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p>Courses <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content show">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/course-grid"}>Course Grid</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/course-list"}>Course List</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/course-details"}>Course Details</Link></li>
                            </ul>
                        </div>
                    </div> */}
                    {/* <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Instructor <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/instructor"}>Instructors</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/instructor-details"}>Instructor Details</Link></li>
                            </ul>
                        </div>
                    </div> */}
                    {/* <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Event <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/events"}>Events</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/event-details"}>Event Details</Link></li>
                            </ul>
                        </div>
                    </div> */}
                    {/* <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Blog <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/blog-classic"}>Blog Classic</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog Grid</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/blog-details"}>Blog Details</Link></li>
                            </ul>
                        </div>
                    </div> */}
                    {/* <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Shop <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/products"}>Products</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/product-details"}>Product Details</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/cart"}>Cart</Link></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </section>
            <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>

            {/* login modal */}

            {modalOpen && <LoginModal onClose={openModal} show={""} Hide={"modalOpen"} login={logIn} />}

            {/* registration modal */}

            <Modal size="l" show={postModal} >
                <div className="" role="document">
                    <div className="">
                        <form >
                            <div className="modal-header">

                                <button type="button" className="btn-close" onClick={closemodal} data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <Register closemodal={closemodal} loginmodal={openModal} />
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>


             {/* offlineEnroll modal */}

             <MyVerticallyCenteredModal
                show={offlineModalShow}
                onHide={() => setOfflineModalShow(false)}
            />

            <Modal size="l" show={deleteModal} >
                <div className="" role="document">
                    <div className="">
                        <form >
                            <div className="modal-header">

                                <button type="button" className="btn-close" onClick={clsDeleteModal} data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body text-center">
                                <h5>Are you sure you want to deactivate your account , Once you deactivate, you cannot login again  ?</h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={deactivateConfirmed}>ok</button>
                                <button type="button" className="btn btn-secondary" onClick={clsDeleteModal} data-dismiss="modal">cancel</button>
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>

        
        </Styles>
    )
}

export default MobileMenu