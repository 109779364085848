import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PostApi } from '../services/CommonService2.js';
import { Styles } from "./styles/serviceBox.js";

function ServiceBox() {

    useEffect(() => {
        getCourses();

    }, [])
    const [courseList, setCourseList] = useState([]);
    const [labels, setLabels] = useState(null);
    const [labels1, setLabels1] = useState(null);
    const [trendingList, settrendingList] = useState([]);





    const getCourses = async () => {
        const reqparam = {
            pageno: 1,
            query: { status: 'Active' },
            usertype: 'APP',
            tenantid: '',
            // optimize: true,
            userid: ""

        }
        const action = "ITPROGRAMS"


        const ResponseData = await PostApi(reqparam, action);

        // setCourseList(ResponseData.data.filter((ele) => { return ele.showondashboard }));
        // setCourse(ResponseData.data)

        const dashquery = {
            pageno: "-1",
            query: {
                status: 'Active', keyid: { $in: ['1', '2'] }

            },
            ptype: "DASHBOARDLABELS"
        }


        const dashboardResponse = await PostApi(dashquery, 'GETDASHOARDLABEL1');
        // console.log("dashboard ?", dashboardResponse.data);

        if (dashboardResponse.data.length > 0) {
            const lbdata = dashboardResponse.data.filter(t => t.keyid == '1');
            if (lbdata.length > 0) {

                if (lbdata.length > 0) {
                    setLabels(lbdata[0].name);
                }
                const reqparams = {
                    pageno: 1,
                    query: { status: 'Active', showondashboard: '1' }

                }
                const response = await PostApi(reqparams, 'ITPROGRAMS');
                // console.log("actual dashboard 1", response.data.docs);
                setCourseList(response.data.docs);
            }
            const lbdata1 = dashboardResponse.data.filter(t => t.keyid == '2');
            if (lbdata1.length > 0) {
                setLabels1(lbdata1[0].name);

                const reqparams2 = {
                    pageno: 1,
                    query: { status: 'Active', showondashboard: '2' }

                }
                const response2 = await PostApi(reqparams2, 'ITPROGRAMS');
                settrendingList(response2.data.docs);
            }

        }
    }

    return (
        <Styles>
            {/* Service Box */}

            
                                
            {courseList && courseList.length > 0 &&<section className="why-Visualpath">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12 mb-5">
                            <h3 className="main-title">{labels}</h3>
                            <p className="description mb-0 px-xl-5">The computer Fundamental program is designed for aspiring students joining graduation<br></br> to choose their career as software engineers</p>
                        </div>
                        <br></br>

                        {/* <div className="clearfix"></div> */}
                        {console.log(courseList,"check course list")}

                        {courseList.map((ele, inx) => {

                            let imageid = 'https://visualpathedu.com/static/media/4th-year.15b862d606512e3257c0.jpg';
                            if (ele?.image) {
                                imageid = ele.image;
                            } else if (ele?.image_url) {
                                imageid = ele.image_url;
                            }
                            else if (ele?.imageurl) {
                                imageid = ele.imageurl ? ele.imageurl : "";
                            }

                            return (
                                // <div id='fundamentals-main'> 
                                <div className={`col-block col-sm-12 col-md-6 col-lg-4 mt-5`} key={inx}>

                                    {inx <= 5 &&
                                        <>
                                            <div><Link to={{ pathname: "/course-list", state: ele }} style={{ width: '100%', height: '100%', borderRadius: '50%' }}><img src={imageid} className="img-fluid" style={{ width: '100%', height: '100%', borderRadius: '50%' }} /></Link></div>
                                            <h4 ><Link className='fundamentals' to={{ pathname: "/course-list", state: ele }}><span>{ele.name}</span></Link></h4>
                                           
                                        </>}
                                </div>)
                        })}
-
                    </div>


                </div>
            </section>}
            {trendingList.length != 0 && <section className="why-Visualpath">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <h3 className="main-title">{labels1}</h3>
                        </div>
                        <br></br>


                        {trendingList.map((ele, inx) => {

                            let imageid = 'https://visualpathedu.com/static/media/4th-year.15b862d606512e3257c0.jpg';
                            if (ele?.image) {
                                imageid = ele.image;
                            } else if (ele?.image_url) {
                                imageid = ele.image_url;
                            }
                            else if (ele?.imageurl) {
                                imageid = ele.imageurl ? ele.imageurl : "";
                            }

                            return (
                                // <div id='fundamentals-main'> 
                                <div className={`col col-block line${inx}`}>

                                    {inx <= 6 &&
                                        <>
                                            <div><img src={imageid} className="img-fluid" style={{ width: '100%', height: '100%', borderRadius: '50%' }} /></div>
                                            <h4 ><Link className='fundamentals' to={{ pathname: "/course-grid", state: ele }}>{ele.name}</Link></h4>
                                        </>}
                                    {/* </div> */}
                                </div>)
                        })}
                    </div>


                </div>
            </section>}
           
        </Styles>
    )
}

export default ServiceBox
