import React, { useEffect, useRef, useState } from "react";

import SupportWindow from './SupportWindow';

import Avatar from './Avatar';

const SupportEngine = () => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    const [visible, setVisible] = useState(false);
    var [modelData,setModelData]=useState(false);
    const [refresh,setRefresh]=useState(false);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setVisible(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref])
    }
    setTimeout(()=>{setRefresh(true)},8000)

    return (
        <div ref={wrapperRef}>
            {/* {visible&&  */}
            <SupportWindow visible={visible} modelData={modelData}/>
            {/* // } */}

            {refresh&&
            <Avatar 
            onClick={() => {
                setVisible(!visible)
                setModelData(!modelData)
                // modelData={modelData} 
                // setModelData={setModelData}
            }
            }
            visible={visible}
            style={{
                position: 'fixed',
                bottom: '24px',
                right: '64px',
              
            }}
        />}
        </div>
    )
}

export default SupportEngine;

