import React, { Component } from 'react';
import Datas from '../data/team/team-slider.json';
import { Container, Row, Col } from 'react-bootstrap';
// import Swiper from 'react-id-swiper';
import Slider from "react-slick";
import { Styles } from "./styles/teamSlider.js";
import { PostApi } from '../services/CommonService2.js';
import { useState, useEffect } from "react";


const TeamSlider=(props)=> {

    const [sliderImages, setSliderImages] = useState([])
        
        useEffect(() => {
            getSliderImagesData()
        }, [props.subapllicationid])
    
        const getSliderImagesData = async () => {
            const reqdata = {
                pageno: '-1',
                query:{status:'Active'},
               
    
            }
            const response = await PostApi(reqdata,'CONSTITUENCYDEV',props.subapllicationid)
            setSliderImages(response.data)
            
        }
        var settings = {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
        
          };

        

        return (
            <Styles>
                <section className="team-member-area">
                    <Container>
                        <Row>
                         
                            <Col md="12" className="team-slider">

                               {sliderImages.length!=0 && <Slider {...settings} >
                                    {
                                        
                                        sliderImages?.map((data, i) => (
                                           
                                            <div className="team-item" key={i}>
                                                
                                                <img src={data.image} alt="" className="img-fluid" style={{height:"200px",width:"350px"}} />
                                            </div>
                                            
                                        ))
                                    }
                                </Slider>}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }


export default TeamSlider
