import DOMPurify from 'dompurify';
import React, { useEffect,useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import HeaderTwo from './HeaderTwo';
import FooterTwo from './FooterTwo';
import { PostApi } from '../services/CommonService2';
import Footer3 from '../home3components/Footer3';
import Header3 from '../home3components/Header3';
function Settings() {
  var data=useLocation();
  const [settings, setSettings] = useState([]);
  const [tenantId,setTenantId] = useState('')

  useEffect(()=>{
    var tntId= JSON.parse(localStorage.getItem('tID'))
    setTenantId(tntId)
    getSettings()
  },[])

  const getSettings=async()=>{
     const reqparams2 = {
            pageno: 1,
            query: {}

        }
        const respData2 = await PostApi(reqparams2, 'SETTING');
        var arr=respData2.data.docs.filter((item)=>{return `/Settings/${item.actiontype}`===window.location.pathname})
        setSettings(arr)
  }
  const sanitizedData = (data) => {
    const sanitizedHtml=DOMPurify.sanitize(data)
    return {__html: sanitizedHtml}
  }

 

  return (
    <div>
      
      {tenantId == "ffe21ec9" ? <Header3 /> : <HeaderTwo />}
      {/* <h1>{data.state.actiontype}</h1>
      ----------------------------<br></br> */}
      <div style={{ width: '90%', marginLeft: '3%'}}>
      {data.state!=undefined?<p dangerouslySetInnerHTML={sanitizedData(data.state.content)}></p>
      :
      <p dangerouslySetInnerHTML={sanitizedData(settings[0]?.content)}></p>
      }
      </div>
      <br></br>
      <br></br>
      {tenantId == "ffe21ec9" ? <Footer3 />:<FooterTwo/>}
    </div>
  )
}

export default Settings