import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { PostApi } from '../services/CommonService2';
function Banner3() {
  const [banners, setBanners]=useState([])
  useEffect(()=>{
    getTenanID()
  },[])
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnHover: true
      };

      async function getTenanID(){
        const reqparamWeb = {
          pageno: '-1',
          query: { status: 'Active', subapplicationid: "" },
          bannerweb: 'Y',
    
        }
    
    
        const ResponseData2 = await PostApi(reqparamWeb, "HOMEBANNER");
        setBanners(ResponseData2.data)
      }
  return (
    <div className='bg-light' style={{width:"100%",overflowX:"hidden"}}>
      
    <div className='px-2' style={{paddingTop:"10px",paddingBottom:"30px"}}>
    <Slider {...settings} >
        {banners.map((ele,ind)=>{
return(
  <div key={ind} className='px-4 rounded bg-light' >
          <img src={ele.image} className='rounded' />
        </div>
)
        })}
       
        
      </Slider>
    </div>
    
    </div>
  )
}

export default Banner3