import React from 'react'
// import { PiLessThanBold } from "react-icons/pi";
import "./questionbar.css"
import { ImCross } from "react-icons/im";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import QuestionItem from './QuestionItem';
import { useState } from 'react';

function QuestionBar(props) {
    
    return (
        
        <div className={props.toggle ?'questionbar-main': 'questionbar-toggle'}>
            <div className='d-flex justify-content-between px-3 py-2 mt-2'>
                <ImCross className='text-light fs-3' onClick={()=>props.setToggle(false)} />
                <div>
                    {/* <PiLessThanBold className="fs-4 fw-bold text-success" /> */}
                    <Link to="/code-home" className="fs-5 fw-bold text-success" >Go Back To Explore Page</Link>
                </div>
            </div>
<div style={{marginTop:"10px"}}>
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
    <QuestionItem />
</div>
        </div>
    )
}

export default QuestionBar