import React, { useEffect, useState } from 'react'
import { FaGreaterThan } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import { PostApi } from '../services/CommonService2';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FaAngleDown } from "react-icons/fa";
function SideBar() {
  const [companyNames, setCompanyNames] = useState([])
  const [topics,setTopics] =useState([])
  const [subTopics,setSubTopics]=useState([])
  const [checkedValues, setCheckedValues] = useState([]);
  useEffect(() => {
    getCompanyName()
    getTopics()
    getSubTopics()
  },[])

  const getCompanyName = async () => {
    var tntId = JSON.parse(localStorage.getItem('tID'))

    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    const userdata = JSON.parse(sessiondetails);


    if (sessiondetails != null) {
      const reqparam = {
        pageno: '-1',
        query: { status: "Active" },

      }


      const response = await PostApi(reqparam, 'COMPANYNAMES');
      setCompanyNames(response.data)

    }
  }
  const getTopics = async ()=>{
    var tntId = JSON.parse(localStorage.getItem('tID'))

    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    const userdata = JSON.parse(sessiondetails);


    if (sessiondetails != null) {
      const reqparam = {
        pageno: '-1',
        query: { status: "Active" },

      }


      const response = await PostApi(reqparam, 'COMPANYTOPICS');
    
      setTopics(response.data)

    }
  }
  const getSubTopics = async ()=>{
    var tntId = JSON.parse(localStorage.getItem('tID'))

    const sessiondetails = localStorage.getItem(`userdata${tntId}`);
    const userdata = JSON.parse(sessiondetails);


    if (sessiondetails != null) {
      const reqparam = {
        pageno: '-1',
        query: { status: "Active" },

      }


      const response = await PostApi(reqparam, 'COMPANYSUBTOPICS');
    console.log(response,'success');
      setSubTopics(response.data)

    }
  }
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setCheckedValues(prevValues => {
      if (prevValues.includes(value)) {
        return prevValues.filter(item => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };
  return (
    <div className='p-4'>
      <h5 className='text-light mt-4 mb-4'>Filters</h5>
      <div className='mt-4 mb-4 d-flex justify-content-between'>
        <div>
          <h6 className='text-light'>COMPANIES</h6>
          <Form className='mt-3'>

           {companyNames.map((ele,ind)=>{
            return(
              <Form.Check // prettier-ignore
              className='text-light mb-2'
              type="checkbox"
              id={ele.name}
              label={ele.name}
            />
            )
           })}
         

          </Form>
        </div>

        <p className='text-success' style={{ fontSize: "18px" }}>View All</p>
      </div>
      <h6 className='text-light mt-3'>Topics</h6>
      {/* <Form className='mt-3'>

        <Form.Check // prettier-ignore
          className='text-light mb-2'
          type="checkbox"
          id="SCHOOL"
          label={`DSA`}
        />
        <Form.Check // prettier-ignore
          className='text-light mb-2'
          type="checkbox"
          id="BASIC"
          label={`Algorithms`}
        />
      
   
      

      </Form> */}
      <div className='mt-1 w-75'>
      {topics.map((ele)=>{
        return(
          <Accordion className='text-light' style={{backgroundColor:'#3b444b'}}>
          <AccordionSummary
            expandIcon={<FaAngleDown className='text-light fs-3' />}
            aria-controls="panel1-content"
            id="panel1-header"
            
          >
          <p> {ele.name}</p>
          </AccordionSummary>
          <AccordionDetails>
           {subTopics.filter(item=>item.topicid==ele.id).map(data=>{
            return(
              <label>
        <input
          type="checkbox"
          name="subtopic"
          value={data.name}
          onChange={handleCheckboxChange}
        /> {data.name} <br/>
      </label>
            )
           })}
          </AccordionDetails>
        </Accordion>
                )
      })}
   
     
    </div>


      <h6 className='text-light mt-3'>DIFFICULTY</h6>
      <Form className='mt-3'>

        <Form.Check // prettier-ignore
          className='text-light mb-2'
          type="checkbox"
          id="SCHOOL"
          label={`SCHOOL`}
        />
        <Form.Check // prettier-ignore
          className='text-light mb-2'
          type="checkbox"
          id="BASIC"
          label={`BASIC`}
        />
        <Form.Check // prettier-ignore
          className='text-light mb-2'
          type="checkbox"
          id="EASY"
          label={`EASY`}
        />
        <Form.Check // prettier-ignore
          className='text-light mb-2'
          type="checkbox"
          id="MEDIUM"
          label={`MEDIUM`}
        />
        <Form.Check // prettier-ignore
          className='text-light mb-2'
          type="checkbox"
          id="HARD"
          label={`HARD`}
        />

      </Form>



      <div className='mt-4 mb-4 d-flex justify-content-between'>
        <div>
          <h6 className='text-light'>MY SPRINT</h6>
          <Form className='mt-3'>

            <Form.Check // prettier-ignore
              className='text-light mb-2'
              type="radio"
              id="BOOKMARK"
              label={`BOOKMARK`}
            />


          </Form>
        </div>

        <p className='text-success' style={{ fontSize: "18px" }}>View All <FaGreaterThan className='bg-success text-light p-2' style={{ fontSize: "22px", borderRadius: "50%" }} /></p>
      </div>

      <div className='mt-4 mb-4 d-flex justify-content-between'>
        <div>
          <h6 className='text-light'>FEATURED SPRINTS</h6>
          <Form className='mt-3'>

            <Form.Check // prettier-ignore
              className='text-light mb-2'
              type="radio"
              id="SPRINTS"
              name='SPRINTS'
              label={`SDE Sheet`}
            />
            <Form.Check // prettier-ignore
              className='text-light mb-2'
              type="radio"
              id="SPRINTS"
              name='SPRINTS'
              label={`Begineer's DSA Sheet`}
            />
            <Form.Check // prettier-ignore
              className='text-light mb-2'
              type="radio"
              id="SPRINTS"
              name='SPRINTS'
              label={`LOVE Babbar Sheet`}
            />

          </Form>
        </div>

        <p className='text-success' style={{ fontSize: "18px" }}>View All <FaGreaterThan className='bg-success text-light p-2' style={{ fontSize: "22px", borderRadius: "50%" }} /></p>
      </div>

    </div>
  )
}

export default SideBar