import DOMPurify from 'dompurify';
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ReactPlayer from 'react-player';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';
import { toast } from "react-toastify";
import FooterTwo from '../../components/FooterTwo.js';
import HeaderTwo from '../../components/HeaderTwo.js';
import { PostApi } from '../../services/CommonService2.js';
import { Styles } from './styles/course.js';
import "./styles/lmsStyles.css";
// import { Document, Page, Text, View, PDFViewer } from '@react-pdf/renderer';
// import pdffile from "./sample3.pdf"
import axios from "axios";
import Header3 from '../../home3components/Header3.jsx';
import Footer3 from '../../home3components/Footer3.js';
let apiurl = "https://asseshub.com";

function LmsDetails() {

    const [videoURL, setVideoURL] = useState("")
    const [finishbtn, setFinishBtn] = useState(false)
    const [details, setDetails] = useState([])
    const [coursedata, setCoursedata] = useState({})
    const data = useLocation();
    const [course, setCourse] = useState([]);
    const [programData, setProgramData] = useState([])
    const [coursePreview, setCoursePreview] = useState(false)
    // const [eduPageType, setEduPageType] = useState(null)
    const [showAlert, setShowAlert] = useState(false);
    const [isPurchased, setIsPurchased] = useState(false)
    const [isPreview, setIsPreview] = useState(false)
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [freeTrail, setFreeTrail] = useState(false);
    const [pagetype, setPagetype] = useState(null);
    const [isCoursePreview, setIsCoursePreview] = useState(false);
    const [itemData, setItemData] = useState([])
    const [itemAlert, setItemAlert] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [show, setShow] = useState(false);
    const [userId, setUserId] = useState("")
    const [sectionId, setSectionId] = useState("")
    const [mainSectionId, setMainSectionId] = useState(null)
    const [iscompletefree, setIscompleteFree] = useState(false);
    const [finishsection, setFinishsection] = useState(false)
    const [QuizTitle, setQuizTitle] = useState(null)
    const [sectionTitle, setSectionTitle] = useState([])
    const [sectionItems, setSectionItems] = useState([])
    const [tenantId, setTenantId] = useState('')
    const history = useHistory();

    const handleClose = () => {
        setShow(false)
        window.location.reload()
    }
    // const handleShow = (userid,sectionid,index) => {
    //     setSectionId(sectionid)
    //     setIndex(index)
    //     setShow(true);

    // }
    // var edupagetype =''
    useEffect(() => {
        // getDetails();

        loadFirst()
        // console.log("data.state.id",data.state.id);
    }, [])




















    const loadFirst = async () => {
        const previewtype = localStorage.getItem('previewtype')
        // console.log("previeww", previewtype);
        if (previewtype != null) {
            if (previewtype == 'Y') {
                setIsPreview(true);
                setIsCoursePreview(true)
            }
        }
        var tntId = JSON.parse(localStorage.getItem('tID'))
        setTenantId(tntId)
        const sessiondetails = localStorage.getItem(`userdata${tntId}`);
        if (sessiondetails != null) {
            const userdata = JSON.parse(sessiondetails);


            const reqparams1 = {
                pageno: 1,
                query: { userid: userdata.user.id },
                ptype: 'PROGRAMPURCHASES'
            }

            let response = await PostApi(reqparams1, 'DASHOARDLABEL');


            if (data.state.program) {
                for (var a = 0; a < response.data.length; a++) {
                    for (var i = 0; i < data.state.program.length; i++) {
                        if (response.data[a].programid == data.state.program[i].value) {
                            setIsPurchased(true)
                        }
                    }
                }
            }





            const reqdata = {
                userid: userdata.id,
                ptype: 'USERSUBSCRIPTION',
                pageno: '-1',
                query: { userid: userdata.id }
            }
            setUserId(userdata.id)
            const userSubsResp = await PostApi(reqdata, 'USERSUBSCRIPTION');


            if (userSubsResp?.data.length > 0) {
                setIsSubscribed(true)
                // console.log("usersubscription",userSubsResp.data);
            }

            loaddatafirst(userdata.id)
        }
    }

    const loaddatafirst = async (userid) => {
        const edupagetype = JSON.parse(localStorage.getItem('edutype'))
        const reqparam = {
            pageno: '-1',
            query: {}
        }
        const tenantresp = await PostApi(reqparam, 'TENANT');
        // console.log("tenantresp", tenantresp);
        // console.log("tenantresp", tenantresp[0]);
        // setEduPageType(edupagetype)

        if (tenantresp.data[0]?.isacademicsfree && edupagetype == 'ACADEMICDETAILS') {

            setIscompleteFree(true)
        }
        if (tenantresp.data[0]?.isstudentprogramfree && edupagetype == 'STUDENTPROGRAMMING') {
            setIscompleteFree(true)
        }

        getDetails(userid)


    }

    const getDetails = async (userId2) => {
        var tntId = JSON.parse(localStorage.getItem('tID'))

        const eduPageType = JSON.parse(localStorage.getItem('edutype'))
        const sessiondetails = localStorage.getItem(`userdata${tntId}`);
        const userdata = JSON.parse(sessiondetails);

        try {

            const reqparam = {
                courseid: data.state.id,
                userid: userId2

            }

            if (eduPageType == 'ACADEMICDETAILS') {
                // console.log("edutype",eduPageType)

                const ResponseData = await PostApi(reqparam, 'ACADEMICDETAILS');
                setCoursedata(ResponseData?.data);
                // alert(JSON.stringify(ResponseData?.data.sections));
                setDetails(ResponseData?.data.sections)
                setVideoURL(ResponseData?.data?.sections[0]?.items[0]?.url)
                if (ResponseData.data?.freetrail === true) {
                    setFreeTrail(true);
                    setIsPreview(false)

                }
                if (isSubscribed) {
                    setIsPreview(false)
                }

            }
            else if (eduPageType == 'ITPROGRAMING') {
                // console.log("edupage", eduPageType);
            const ResponseDataOne = await PostApi(reqparam, 'ITPROGRAMINGONE');
            setCoursedata(ResponseDataOne?.data);
            setDetails(ResponseDataOne?.data?.sections)
            setVideoURL(ResponseDataOne?.data?.sections[0]?.items[0]?.url)
               if(ResponseDataOne?.data?.sections.length > 0){
                 const ResponseData = await PostApi(reqparam, 'ITPROGRAMING');
                 var FilteredTitles =ResponseData?.data?.sections?.slice(1)
              setSectionTitle(FilteredTitles)
               }
             

                setPagetype(eduPageType)


                if (ResponseDataOne.data?.freetrail === true) {
                    setFreeTrail(true);
                    setIsPreview(false)

                }
                // if (ResponseData.data?.ispurchasedcourse === true) {

                //     setIsPurchased(true)
                //     // setIsPreview(false)
                // }

            }
            else if (eduPageType == 'STUDENTPROGRAMMING') {
                const ResponseData = await PostApi(reqparam, 'STUDENTPROGRAMMING');
                setCoursedata(ResponseData?.data);
                if (ResponseData?.data != undefined || ResponseData?.data) {
                    setDetails(ResponseData?.data.sections)
                    setVideoURL(ResponseData?.data?.sections[0]?.items[0]?.url)
                }
                // console.log("ResponseData", ResponseData?.data);
                if (ResponseData.data?.freetrail === true) {
                    setFreeTrail(true);
                    setIsPreview(false)

                }
                if (isSubscribed) {
                    setIsPreview(false)
                }
            }
        }
        catch (e) {

            console.log(e.message || 'Error when get product data');
        }
    }


    const handleGetSectionItems = async (ele) => {
        setSectionItems([])
//SECTIONITEMS
        try {
            console.log(ele,"Section...................")
            const reqparam = ele;
            reqparam.userid=userId
            const ResponseData = await PostApi(reqparam, 'SECTIONITEMS');
            setSectionItems(ResponseData.data);
            console.log(ResponseData,"checking response .data for lms")
        } catch (error) {
            console.log(error)
        }
    }

    const sanitizedData = (data) => {
        const sanitizedHtml = DOMPurify.sanitize(data)
        return { __html: sanitizedHtml }
    }
    const onNavigateLearning = async (ele, index) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setItemData(ele)
        var tntId = JSON.parse(localStorage.getItem('tID'))

        const sessiondetails = localStorage.getItem(`userdata${tntId}`);
        const userdata = JSON.parse(sessiondetails);
        console.log(isPurchased, isSubscribed, freeTrail, "check the subscrition status")
        if (ele.type == 'lp_lesson') {
            // console.log("ele,url", ele.url);
            setVideoURL(ele.url)
            setFinishBtn(true)


        }
        else if (ele.type == "lp_quiz" && (isPurchased || isSubscribed)) {
            const checksectionreq = {
                sectionid: ele.sectionid,
                itemid: ele.id,
                userid: ''

                // query: { tenantid: "7e71674b" }
            }
            setSectionId(ele.id)
            setMainSectionId(ele.sectionid)

            const resp = await PostApi(checksectionreq, 'CHECKSTARTQUIZ');

            console.log("responseeeeeee", resp);

            if (resp?.message == 'SUCCESS') {
                // console.log("open quizz component");
                // toast.info("open quiz component")
                setVideoURL()
                setShow(true)
                const data = details.filter(element => {
                    return element.id == ele.sectionid
                })
// console.log(ele,"setQuizTitle(data[0].title")
                setQuizTitle(ele.title)

            }
            else {
                alert("Please finish all the lesson videos, Before starting the quick test")
            }
        }
        else if (ele.type == "lp_quiz" && (!isPurchased || !isSubscribed)) {
            // alert('Please purchase the course to take assessment')
            toast.error("Please Purchase the course to take assessment", {
                position: "top-center",
                autoClose: 5000

            })

        }
        else if (ele.type == "lp_theory" && (isPurchased || isSubscribed)) {
            try {
                const response = await axios.get(apiurl + `/api/v1/pdfdownload/${ele.theory.filename}`, {
                    responseType: 'blob', // Set responseType to 'blob' to get binary data
                });

                // console.log("response.data", response.data);

                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', ele.theory.filename);
                document.body.appendChild(link)
                    ;

                link.click();

                link.parentNode.removeChild(link)
                    ;
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        }

    }

    const finishItem = async () => {

        setItemAlert(true)
    }

    const itemCancel = () => {
        setItemAlert(false)
    }
    const updateItem = async () => {

        setItemAlert(false)
        const reqdata = {
            sectionid: itemData.sectionid,
            itemid: itemData.id,
            userid: '',
        }
        // console.log("reqqdata", reqdata);

        const saveResponse = await PostApi(reqdata, 'FINISHITEM');
        // console.log("save response", saveResponse.data);
        if (saveResponse?.data.id) {
            toast.success("video completed succesfully", {
                position: "top-center",
                autoClose: 5000
            })
            const reqparam = {
                courseid: data.state.id,
                userid: userId

            }
            // const ResponseData = await PostApi(reqparam, 'ITPROGRAMING');
            // console.log("ResponseData IT Programs", ResponseData.data);
            // setCoursedata(ResponseData?.data);
            // setDetails(ResponseData?.data?.sections)
            const ResponseDataOne = await PostApi(reqparam, 'ITPROGRAMINGONE');
            setCoursedata(ResponseDataOne?.data);
            setDetails(ResponseDataOne?.data?.sections)
            // setVideoURL(ResponseDataOne?.data?.sections[0]?.items[0]?.url)
               if(ResponseDataOne?.data?.sections.length > 0){
                 const ResponseData = await PostApi(reqparam, 'ITPROGRAMING');
                 var FilteredTitles =ResponseData?.data?.sections?.slice(1)
              setSectionTitle(FilteredTitles)
               }


        }
        else {
            // toast.error("failed to complete the video")
            toast.error("failed to complete the video", {
                position: "top-center",
                autoClose: 5000
            })

            // alert("failed to complete")
        }


    }

    const finishCourse = async () => {
        setShowAlert(true);
    };

    const handleCancel = () => {
        setShowAlert(false);

        // Additional logic to handle cancel action if needed
    };

    const handleConfirm = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))

        const sessiondetails = localStorage.getItem(`userdata${tntId}`);
        const userdata = JSON.parse(sessiondetails);
        setShowAlert(false);
        try {
            const reqdata = {
                courseid: data.state.id,
                query: { status: 'Active' }

            };


            const response = await PostApi(reqdata, 'FINISHCOURSE');

            if (response?.message === 'PENDINGITEMS') {
                // alert('Please finish all the sections to complete the course');
                toast.error("Please finish all the sections to complete the course", {
                    position: "top-center",
                    autoClose: 5000
                })


            } else if (response.message === 'SUCCESS') {
                // alert('Course completed successfully');
                toast.success("Course completed successfully", {
                    position: "top-center",
                    autoClose: 5000
                })

            } else {
                toast.error("Failed to complete the course", {
                    position: "top-center",
                    autoClose: 5000
                })


                // alert('Failed to complete the course');
            }
        } catch (error) {
            // console.error('Error occurred:', error);
            alert('An error occurred while finishing the course');
        }
    };

    const sharecontent = async (ele) => {
        let mname = ele.title
        // console.log("data", ele.title);
        // console.log("url", ele.url);
        await navigator.share({
            title: mname,
            // text: 'Content to be shared',
            url: ele.url
        });
    }
    const goBack = () => {
        history.goBack();
    };


    const customStyles = {
        content: {
            width: '100px !important', // Set your desired width here
            margin: 'auto', // Center the modal
        },
    };


    const handleConfirmNew = () => {
        setConfirm(false)
        setShow(false)
    }
    const handleMainClose = () => {
        setConfirm(true)
    }


    // const downloadPDF = () => {
    //     const pdfUrl = pdffile;

    //     fetch(pdfUrl)
    //       .then((response) => response.blob())
    //       .then((blob) => {
    //         const url = URL.createObjectURL(blob);
    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = 'external.pdf'; 
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //       })
    //       .catch((error) => {
    //         console.error('Error downloading PDF:', error);
    //       });
    //   };
    return (

        <>
{console.log(isPurchased, isSubscribed, freeTrail, " isPurchased, isSubscribed, freeTrail, ") }
{tenantId == "ffe21ec9" ? <Header3 />: <HeaderTwo />}
        {details.length > 0 ? <>
            <Modal show={confirm} onHide={() => setConfirm(false)} backdrop="static" style={{ width: "340px", overflow: "hidden", marginLeft: "30%" }} aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header style={{ backgroundColor: "#2C79FF" }}>
                    <h4 className='text-center' style={{ color: "white" }}> Are you sure want to exit from the Test ??</h4>
                </Modal.Header>



                <Modal.Footer style={{ backgroundColor: "#2C79FF" }}>
                    <Button variant="danger" onClick={() => setConfirm(false)} >No</Button>
                    <Button variant="success" onClick={handleConfirmNew}>Yes</Button>
                </Modal.Footer>


            </Modal>

            <Modal show={show} backdrop="static" onHide={handleMainClose} style={{ width: "367px", overflow: "hidden", marginLeft: "30%" }} aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header closeButton="true" >
                    <h3 style={{ padding: "0px 20px" }}>{QuizTitle}</h3>
                    <hr />
                </Modal.Header>




                {/* <iframe src={"http://localhost:19006/" + userId + "/" + sectionId + "/" + mainSectionId} title="description" style={{ minHeight: "480px", overflowX: "scroll" }}></iframe> */}
                <iframe src={"https://techbricksedu.com/" + userId + "/" + sectionId + "/" + mainSectionId} title="description" style={{ minHeight: "480px", overflowX: "scroll" }}></iframe>

            </Modal>

            <div className="main-wrapper course-details-page" >
                <button className="btn btn-primary" onClick={goBack} style={{ position: "fixed", bottom: "20px", left: "10px", zIndex: 999 }}><i class="fa-solid fa-arrow-left"></i></button>





                {/* {/ Header 2 /} */}
               
                {/* {console.log("iscompletefree",iscompletefree)} */}

                {/* {/ Breadcroumb /}
            {/ < BreadcrumbBox title="Course Details" /> /} */}

                <Styles>

                    {/* {console.log("freetrail",freeTrail)} */}
                    {/* {/ Course Details /} */}
                    <section className="course-details-area">

                        {/* {   console.log("edutype",eduPageType)} */}



                        <div className="course-details-top">
                            <div className="course-desc">
                                <h4 className='text-center'>{data?.state?.name}</h4>

                            </div>
                            {/* <div className="course-desc">
                                        {programData.map((data) => (
                                            <h4>{data.description.slice(0, 100)}</h4>

                                        ))}

                                    </div> */}
                            <br />
                            <div className='row p-3 m-0 ' style={{ backgroundColor: "#4682B4" }}>

                                {videoURL != "" ? <div className="course-details-banner col-lg-7 mt-4" style={{ position: "relative" }}>
                                    {/* {/ <img src={process.env.PUBLIC_URL + `/assets/images/details-banner.jpg`} alt="" className="img-fluid" /> /} */}



                                    <ReactPlayer url={videoURL} controls={true} playing="true" config={{
                                        file: {
                                            attributes: {
                                                controlsList: 'nodownload' // This disables the download attribute
                                            }
                                        }
                                    }} className="img-fluid " width="100%" style={{backgroundColor:"black"}}
                                    />
                                    <br />
                                    {finishbtn && (pagetype == 'ITPROGRAMING' && isCoursePreview==false && <button className="btn btn-dark" onClick={finishItem} style={{ position: "absolute", right: "1%" }}>Finish Video</button>)}
                                    <Row>
                                                <Col lg="12" md="12" sm="12" className='bg-light accordian-container mt-5'>
                                                    <div className='course-description'>


                                                        <h3 className='text-center m-3 fs-2' style={{ textDecoration: "underline" }}>Course Description</h3>
                                                        {coursedata?.content ? <div className='fs-5 m-1' dangerouslySetInnerHTML={sanitizedData(coursedata?.content)}  ></div> : <div className='text-center'>Loading . . .</div>}

                                                    </div>
                                                </Col>
                                            </Row>
                                    {/* {/ <iframe width="810" height="450" src="https://www.youtube.com/embed/55NvZjUZIO8?si=2H-xt7Hlch76Lq1m" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> /} */}
                                </div>:<div className="course-details-banner col-lg-7 " style={{ position: "relative" }}>
                                    {/* {/ <img src={process.env.PUBLIC_URL + `/assets/images/details-banner.jpg`} alt="" className="img-fluid" /> /} */}



                                    <ReactPlayer url="testuerl" controls={true} playing="true" config={{
                                        file: {
                                            attributes: {
                                                controlsList: 'nodownload' // This disables the download attribute
                                            }
                                        }
                                    }} className="img-fluid " width="100%"
                                    />
                                    <br />

                                    <Row>
                                                <Col lg="12" md="12" sm="12" className='bg-light accordian-container'>
                                                    <div className='course-description'>


                                                        <h3 className='text-center m-3 fs-2' style={{ textDecoration: "underline" }}>Course Description</h3>
                                                        {coursedata?.content ? <div className='fs-5 m-1' dangerouslySetInnerHTML={sanitizedData(coursedata?.content)}  ></div> : <div className='text-center'>Loading . . .</div>}

                                                    </div>
                                                </Col>
                                            </Row>
                                    {/* {/ <iframe width="810" height="450" src="https://www.youtube.com/embed/55NvZjUZIO8?si=2H-xt7Hlch76Lq1m" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> /} */}
                                </div>}
                                <div className=" col-lg-5">

                                    {/* <div className="course-curriculum">
                                                        <h5>Course Curriculum</h5>
                                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi, quidem facere quisquam aperiam neque dolorem saepe. Laboriosam, quam aliquam. Optio earum accusantium quam eius dignissimos quaerat voluptatem excepturi aliquid dolor ducimus. Illo porro maiores fuga dignissimos temporibus odio nulla nobis nemo.</p>
                                                    </div> */}
                                    <div className="">
                                        {/* <h5 className='text-light p-3 text-center'>Course Content</h5> */}
                                        <div className="mt-4">
                                            <Accordion defaultActiveKey="0" className='accordian-container bg-light' style={{ height: "100vh" }}>
                                            {details?.map((item, ind) => (


<Accordion.Item eventKey={ind.toString()}>
<Accordion.Header className="p-2"> <h6>Section {ind + 1}: {item.title} </h6></Accordion.Header>
                                                        <Accordion.Body >
                                                            <ul >
                                                                {item && item?.items?.map((ele, index) => (
                                                                    <li className='row p-2 m-0'>
                                                                        {/* <span style={{marginRight:"1%"}}>{ele.type=='lp_lesson' && <i class="fa-solid fa-book-open" ></i>}</span> */}
                                                                        <span className='col-1 fs-4' >
                                                                            {ele.type == 'lp_lesson' && <i class="fa-solid fa-book text-primary" ></i>}
                                                                            {ele.type == 'lp_quiz' && <i class="fa-solid fa-circle-question text-info"></i>}
                                                                            {ele.type == "lp_assignment" && <i class="fa-solid fa-file text-success"></i>}
                                                                            {ele.type == "lp_theory" && <i class="fa-solid fa-book-open text-danger"></i>}
                                                                        </span>

                                                                        <span className='col-7 fs-5'>{ele.title}</span>
                                                                        {!isPreview && <>
                                                                            {!isPurchased &&
                                                                                <span className="lecture-duration col-4" >
                                                                                    {(ele.preview && !ele.canshare) &&
                                                                                        <>
                                                                                            <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span></>
                                                                                    }
                                                                                    {(ele.preview && ele.canshare) &&
                                                                                        <> <span className="play-icon fs-4 m-2" onClick={() => sharecontent(ele)}><i class="fa-solid fa-share" style={{ color: "yellow" }}></i></span>
                                                                                            <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index, item.title)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span></>
                                                                                    }
                                                                                    {(!ele.preview && !isSubscribed && !freeTrail && iscompletefree) && !['completed', 'evaluated'].includes(ele.status) &&
                                                                                        (<span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span>)}


                                                                                    {(!ele.preview && isSubscribed && !freeTrail) &&
                                                                                        !['completed', 'evaluated'].includes(ele.status) && (
                                                                                            <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index, item.title)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span>

                                                                                        )}

                                                                                    {(!ele.preview && isSubscribed && freeTrail) &&
                                                                                        !['completed', 'evaluated'].includes(ele.status) && (
                                                                                            <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index, item.title)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span>

                                                                                        )}

                                                                                    {(!ele.preview && freeTrail && !isSubscribed) &&
                                                                                        !['completed', 'evaluated'].includes(ele.status) && (

                                                                                            <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index, item.title)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span>
                                                                                        )}

                                                                                    {(ele.locked && !isSubscribed && !freeTrail && !iscompletefree) && (

                                                                                        <span className="lock-icon fs-4 m-2 "><i class="fa-solid fa-lock text-danger"></i></span>

                                                                                    )}
                                                                                    {(ele.duration !== '' && ele.duration !== '0') && (
                                                                                        <span className="lecture-duration fs-6 col-4">{ele.duration} mins</span>
                                                                                    )}
                                                                                    {['completed', 'evaluated'].includes(ele.status) && (
                                                                                        <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index, item.title)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span>

                                                                                        // <IconI onPress={() => this.onNavigateLearning(item, index)} name="eye-outline" style={styles.iconPreview} />
                                                                                    )}

                                                                                </span>
                                                                            }



                                                                            {(isPurchased && !isPreview) &&

                                                                                <span className="lecture-duration col-4">
                                                                                    {(ele.preview && ele.canshare) &&
                                                                                        <span onClick={() => sharecontent(ele)}><i class="fa-solid fa-share" style={{ color: "yellow" }}></i>
                                                                                        </span>}
                                                                                    {/* <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{border:"1px solid blue", color:"blue"}}  ></i> </span> */}
                                                                                    {ele.type == "lp_lesson" && <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }}  ></i> </span>}
                                                                                    {ele.type == "lp_theory" && <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i class="fa-regular fa-circle-down"></i></span>}
                                                                                    {(ele.type == "lp_quiz" || ele.type == "lp_assignment") && <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i class="fa-solid fa-pen " style={{ color: "#191970",cursor:"pointer" }}></i></span>}
                                                                                    {['completed', 'evaluated'].includes(ele.status) && (
                                                                                        <span className="lock-icon fs-4 m-2"><i class="fa-solid fa-square-check " style={{ color: "green" }}></i></span>
                                                                                    )


                                                                                    }
                                                                                    {finishsection && (<span className="lock-icon fs-4 m-2"><i class="fa-solid fa-square-check " style={{ color: "green" }}></i></span>)}
                                                                                    <span className='fs-6 ' >{ele.duration} mins</span>

                                                                                </span>
                                                                            }
                                                                            {/* {iscompletefree &&
                                                                                                    <span className="lecture-duration">
                                                                                                        {ele.canshare &&
                                                                                                            <span onClick={() => sharecontent(ele)}><i class="fa-solid fa-share"></i>
                                                                                                            </span>
                                                                                                        }
                                                                                                        <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{border:"1px solid blue", color:"blue"}}  ></i> </span>

                                                                                                        {['completed', 'evaluated'].includes(ele.status) && (
                                                                                                            <span className="lock-icon fs-4 m-2"><i class="fa-solid fa-square-check"></i></span>
                                                                                                        )


                                                                                                        }
                                                                                                        <span>{ele.duration} mins</span>

                                                                                                    </span>

                                                                                                } */}




                                                                        </>}
                                                                        {isPreview && <span className="lecture-duration col-4" >
                                                                            {(ele.preview && ele.canshare) &&
                                                                                <> <span className="play-icon fs-4 m-2" onClick={() => sharecontent(ele)}><i class="fa-solid fa-share" style={{ color: "yellow" }}></i></span>
                                                                                    <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span></>
                                                                            }
                                                                            {(ele.preview && !ele.canshare) &&
                                                                                <>
                                                                                    <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span></>
                                                                            }
                                                                            {(ele.locked) && (

                                                                                <span className="lock-icon fs-4 m-2"><i class="fa-solid fa-lock text-danger"></i></span>

                                                                            )}


                                                                        </span>

                                                                        }
                                                                        {/* <span className="lecture-duration">{ele.duration} Min</span> */}
                                                                    </li>
                                                                ))}
                                                            </ul>


                                                        </Accordion.Body>
</Accordion.Item>





))}
                                                {sectionTitle?.map((item, ind) => (


                                                    <Accordion.Item eventKey={(ind+1).toString()}>
                                                        <Accordion.Header className="p-2" > <h6>Section {item.order}: {item.title} </h6></Accordion.Header>
                                                        <Accordion.Body onEntering={()=>handleGetSectionItems(item)}>
                                                        <ul >
                                                                {sectionItems?.items?.length >0 ? sectionItems.items.map((ele, index) => (
                                                                    <li className='row p-2 m-0'>
                                                                        {/* <span style={{marginRight:"1%"}}>{ele.type=='lp_lesson' && <i class="fa-solid fa-book-open" ></i>}</span> */}
                                                                        <span className='col-1 fs-4' >
                                                                            {ele.type == 'lp_lesson' && <i class="fa-solid fa-book text-primary" ></i>}
                                                                            {ele.type == 'lp_quiz' && <i class="fa-solid fa-circle-question text-info"></i>}
                                                                            {ele.type == "lp_assignment" && <i class="fa-solid fa-file text-success"></i>}
                                                                            {ele.type == "lp_theory" && <i class="fa-solid fa-book-open text-danger"></i>}
                                                                        </span>

                                                                        <span className='col-7 fs-5'>{ele.title}</span>
                                                                        {!isPreview && <>
                                                                            {!isPurchased &&
                                                                                <span className="lecture-duration col-4" >
                                                                                    {(ele.preview && !ele.canshare) &&
                                                                                        <>
                                                                                            <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span></>
                                                                                    }
                                                                                    {(ele.preview && ele.canshare) &&
                                                                                        <> <span className="play-icon fs-4 m-2" onClick={() => sharecontent(ele)}><i class="fa-solid fa-share" style={{ color: "yellow" }}></i></span>
                                                                                            <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index, item.title)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span></>
                                                                                    }
                                                                                    {(!ele.preview && !isSubscribed && !freeTrail && iscompletefree) && !['completed', 'evaluated'].includes(ele.status) &&
                                                                                        (<span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span>)}


                                                                                    {(!ele.preview && isSubscribed && !freeTrail) &&
                                                                                        !['completed', 'evaluated'].includes(ele.status) && (
                                                                                            <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index, item.title)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span>

                                                                                        )}

                                                                                    {(!ele.preview && isSubscribed && freeTrail) &&
                                                                                        !['completed', 'evaluated'].includes(ele.status) && (
                                                                                            <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index, item.title)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span>

                                                                                        )}

                                                                                    {(!ele.preview && freeTrail && !isSubscribed) &&
                                                                                        !['completed', 'evaluated'].includes(ele.status) && (

                                                                                            <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index, item.title)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span>
                                                                                        )}

                                                                                    {(ele.locked && !isSubscribed && !freeTrail && !iscompletefree) && (

                                                                                        <span className="lock-icon fs-4 m-2 "><i class="fa-solid fa-lock text-danger"></i></span>

                                                                                    )}
                                                                                    {(ele.duration !== '' && ele.duration !== '0') && (
                                                                                        <span className="lecture-duration fs-6 col-4">{ele.duration} mins</span>
                                                                                    )}
                                                                                    {['completed', 'evaluated'].includes(ele.status) && (
                                                                                        <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index, item.title)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span>

                                                                                        // <IconI onPress={() => this.onNavigateLearning(item, index)} name="eye-outline" style={styles.iconPreview} />
                                                                                    )}

                                                                                </span>
                                                                            }



                                                                            {(isPurchased && !isPreview) &&

                                                                                <span className="lecture-duration col-4">
                                                                                    {(ele.preview && ele.canshare) &&
                                                                                        <span onClick={() => sharecontent(ele)}><i class="fa-solid fa-share" style={{ color: "yellow" }}></i>
                                                                                        </span>}
                                                                                    {/* <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{border:"1px solid blue", color:"blue"}}  ></i> </span> */}
                                                                                    {ele.type == "lp_lesson" && <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }}  ></i> </span>}
                                                                                    {ele.type == "lp_theory" && <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i class="fa-regular fa-circle-down"></i></span>}
                                                                                    {(ele.type == "lp_quiz" || ele.type == "lp_assignment") && <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i class="fa-solid fa-pen " style={{ color: "#191970",cursor:"pointer" }}></i></span>}
                                                                                    {['completed', 'evaluated'].includes(ele.status) && (
                                                                                        <span className="lock-icon fs-4 m-2"><i class="fa-solid fa-square-check " style={{ color: "green" }}></i></span>
                                                                                    )


                                                                                    }
                                                                                    {finishsection && (<span className="lock-icon fs-4 m-2"><i class="fa-solid fa-square-check " style={{ color: "green" }}></i></span>)}
                                                                                    <span className='fs-6 ' >{ele.duration} mins</span>

                                                                                </span>
                                                                            }
                                                                            {/* {iscompletefree &&
                                                                                                    <span className="lecture-duration">
                                                                                                        {ele.canshare &&
                                                                                                            <span onClick={() => sharecontent(ele)}><i class="fa-solid fa-share"></i>
                                                                                                            </span>
                                                                                                        }
                                                                                                        <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{border:"1px solid blue", color:"blue"}}  ></i> </span>

                                                                                                        {['completed', 'evaluated'].includes(ele.status) && (
                                                                                                            <span className="lock-icon fs-4 m-2"><i class="fa-solid fa-square-check"></i></span>
                                                                                                        )


                                                                                                        }
                                                                                                        <span>{ele.duration} mins</span>

                                                                                                    </span>

                                                                                                } */}




                                                                        </>}
                                                                        {isPreview && <span className="lecture-duration col-4" >
                                                                            {(ele.preview && ele.canshare) &&
                                                                                <> <span className="play-icon fs-4 m-2" onClick={() => sharecontent(ele)}><i class="fa-solid fa-share" style={{ color: "yellow" }}></i></span>
                                                                                    <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span></>
                                                                            }
                                                                            {(ele.preview && !ele.canshare) &&
                                                                                <>
                                                                                    <span className="play-icon fs-4 m-2" onClick={() => onNavigateLearning(ele, index)}><i className="las la-play  rounded" style={{ border: "1px solid blue", color: "blue",cursor:"pointer" }} ></i> </span></>
                                                                            }
                                                                            {(ele.locked) && (

                                                                                <span className="lock-icon fs-4 m-2"><i class="fa-solid fa-lock text-danger"></i></span>

                                                                            )}


                                                                        </span>

                                                                        }
                                                                        {/* <span className="lecture-duration">{ele.duration} Min</span> */}
                                                                    </li>
                                                                )):<Skeleton  height={60} count={3} /> }
                                                            </ul>


                                                        </Accordion.Body>
                                                    </Accordion.Item>





                                                ))}

                                            </Accordion>

                                        </div>
                                        {(pagetype == "ITPROGRAMING" && !isCoursePreview) &&
                                            <>


                                               <div style={{textAlign:"right"}}>
                                               <button className="btn btn-dark" onClick={finishCourse}>Finish Course</button>
                                               </div>

                                            </>
                                        }

                                        {showAlert && (
                                            <Modal show={showAlert}>
                                                <Modal.Body>
                                                    <p>Are you sure you want to finish the course?</p>
                                                </Modal.Body>

                                                <Modal.Footer>
                                                    <Button onClick={handleConfirm}>Ok</Button>
                                                    <Button onClick={handleCancel}>Close</Button>
                                                </Modal.Footer>

                                            </Modal>

                                        )}

                                        {itemAlert && (
                                            <Modal show={itemAlert}>
                                                <Modal.Body>
                                                    <p>Are you sure you want to finish the training video ?</p>
                                                </Modal.Body>

                                                <Modal.Footer>
                                                    <Button onClick={updateItem}>Ok</Button>
                                                    <Button onClick={itemCancel}>Close</Button>
                                                </Modal.Footer>

                                            </Modal>

                                        )}





                                    </div>

                                </div>
                            </div>




                        </div>

                        <Row>
                            <Col lg="3" md="4" sm="12">
                                <div className="single-details-sidbar">
                                    <Row>
                                        {/* <Col md="12">
                                            <div className="course-details-feature">
                                                <h5 className="title">Course Details</h5>
                                                <ul className="list-unstyled feature-list">
                                                    <li><i className="las la-calendar"></i> Start Date: <span>Aug 21, 2020</span></li>
                                                    <li><i className="las la-clock"></i> Duration: <span>1 Year</span></li>
                                                    <li><i className="las la-globe"></i> Language: <span>English</span></li>
                                                    <li><i className="las la-sort-amount-up"></i> Skill Level: <span>Beginner</span></li>
                                                    <li><i className="las la-graduation-cap"></i> Subject: <span>Web</span></li>
                                                    <li><i className="las la-book"></i> Lectures: <span>51</span></li>
                                                    <li><i className="las la-bookmark"></i> Enrolled: <span>236</span></li>
                                                    <li><i className="las la-certificate"></i> Certification: <span>Yes</span></li>
                                                </ul>
                                                <button type="button" className="enroll-btn">Enroll Course</button>
                                            </div>
                                        </Col> */}
                                        <Col md="12">
                                            {/* <PopularCourse /> */}
                                        </Col>
                                        <Col md="12">
                                            {/* <CourseTag /> */}
                                        </Col>
                                    </Row>
                                </div>

                            </Col>
                        </Row>

                    </section>
                </Styles>

                {/* {/ Footer 2 /} */}
             
                {/* <ToastContainer /> */}

            </div >
        </> :  <div style={{ width: "90vw", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}><h3>Loading . . .</h3></div>}

        {tenantId == "ffe21ec9" ? <Footer3 />: <FooterTwo />}
        
        </>
    )
}

export default LmsDetails