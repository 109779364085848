import React, { Component } from 'react';
import Datas from '../data/about-us/about-us.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import ModalVideo from 'react-modal-video';
import CountUp from 'react-countup';
import { Styles } from "./styles/aboutUs.js";
import aboutsmall from './about-small.jpg'
import aboutbig from './emcet-big-image.jpg'
class AboutUs extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <Styles>
                {/* About Us */}
                <section className="about-us">
                    <Container>
                        <Row>
                            <Col md="6">
                                <div className="about-image">
                                    <img src={aboutbig} className="main-img" alt="" />
                                    <img src={process.env.PUBLIC_URL + "/assets/images/pattern.png"} className="pattern-img" alt="" />
                                    <div className="video-player" style={{backgroundImage: `url(${aboutsmall})`}}>
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='uXFUl0KcIkA' onClose={() => this.setState({ isOpen: false })} />
                                        <button onClick={this.openModal} className="play-button"><i className="las la-play"></i></button>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="about-content">
                                    <h4 className="about-title">Master Your EAMCET Exam with Ease! 📚</h4>
                                    <h5 className="about-title">Get ahead of the curve with our FREE EAMCET Exam Practice App! 🌟</h5>
                                    
                                    <p className="about-para">🔍 Access a vast question bank covering all subjects.<span>📝 Test your knowledge with mock exams designed to simulate the real EAMCET experience.</span>
                                    <span>🎯 Track your progress and identify areas for improvement.</span>
                                    <span>📱 Available anytime, anywhere - study on the go!</span>
                                    <span>Don't miss out on this invaluable tool to ace your EAMCET exam! </span>
                                    </p>
                                    <h5 className="about-title">Download now and start your journey to success!</h5>
                                    <Row>
                                        <Col sm="4">
                                            <div className="counter-box box1 text-center">
                                                <h3><CountUp end={40} duration={5} delay={1.5} /><i className="las la-plus"></i></h3>
                                                <p>Mock Exams</p>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div className="counter-box box2 text-center">
                                                <h3><CountUp end={2000} duration={5} delay={1.5} /><i className="las la-plus"></i></h3>
                                                <p>Questions</p>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div className="counter-box box3 text-center">
                                                <h3><CountUp end={340} duration={5} delay={1.5} /><i className="las la-plus"></i></h3>
                                                <p>Topics</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <Link className="readmore-btn" to={process.env.PUBLIC_URL + "/about"}>Read More</Link> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default AboutUs
