import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

function Exams() {
    var data2=useLocation();
    var getData=data2?.state?.data
    var screenname=data2?.state?.screenname
// var data={};
        console.log("getData====",getData,"screenname====",screenname);
    // }



   
    console.log('categories===',getData);
  return (
    <div>

    </div>
  )
}

export default Exams