import React, { useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { Button } from 'bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const options = [
    { value: 'Latest', label: 'Latest' },
    { value: 'Accuracy', label: 'Accuracy' },
    { value: 'Submission', label: 'Submission' },
    { value: 'Difficulty', label: 'Difficulty' },
  ];
function MainContainer() {
    const [selectedOption, setSelectedOption] = useState(null);
    return (
        <>
            <div className='bg-light p-5  h-100 m-0 '>
                <div className='row mb-4 p-0'>
                    <div className='col-lg-6'>
                    <h2 className=''>Problems</h2>
                   <div className='mt-3'>
                    <small className=''>? of 182 Problems Solved <span style={{textDecoration:"underline",marginLeft:'30px'}}>Sign-In To Track Your Progress</span></small>
                   <ProgressBar variant="success"  now={25} />
                   </div>
                   <div className='mt-3'>
                    <span className=' p-1 border rounded' style={{marginRight:"10px"}}>Submissions</span>
                    <span className=' p-1 border rounded' style={{marginRight:"10px"}}>Amazon</span>
                    <span className=' p-1 border rounded' style={{marginRight:"10px"}}>Microsoft</span>
                   </div>
                    </div>
                    <div className='col-lg-6 d-flex '>
                    <div className='p-2 w-50'>
<Form.Control
              type="text"
              placeholder="Search"
              className=" mr-sm-2  d-inline" style={{height:"40px"}}
            />
</div>
               <div className='w-50 p-2'>
               <Select 
        placeholder="SORT: Submissions"
        onChange={setSelectedOption}
        options={options}
      />
               </div>


                    </div>
                </div>
                <div className='row pt-4 pb-4 m-0' style={{borderTop:"1px solid gray"}}>
                    <div className='col-lg-9'>
                        <h4 className='mb-2'>Find Indexes of a subarray with given sum</h4>
                        <span className='p-2 text-success'>Amazon</span>
                        <span className='p-2 text-success'>Micro-soft</span>
                        <span className='p-2 text-success'>+87 More</span>
                    </div>
                    <div className='col-lg-3'>
                   <Link to="/taketest" className='btn btn-success fs-5 px-5'> Solve Problem</Link>
                   <div className='mt-1 d-flex justify-content-center'>
                    <p className='m-2'>Medium</p>
                    <p className='m-2 px-2' style={{borderRight:"1px solid gray",borderLeft:"1px solid gray"}}>2M</p>
                    <p className='m-2'>16.5%</p>
                   </div>
                    </div>
                </div>

                <div className='row pt-4 pb-4 m-0' style={{borderTop:"1px solid gray"}}>
                    <div className='col-lg-9'>
                        <h4 className='mb-2'>Find Indexes of a subarray with given sum</h4>
                        <span className='p-2 text-success'>Amazon</span>
                        <span className='p-2 text-success'>Micro-soft</span>
                        <span className='p-2 text-success'>+87 More</span>
                    </div>
                    <div className='col-lg-3'>
                   <Link to="/taketest" className='btn btn-success fs-5 px-5'> Solve Problem</Link>
                   <div className='mt-1 d-flex justify-content-center'>
                    <p className='m-2'>Medium</p>
                    <p className='m-2 px-2' style={{borderRight:"1px solid gray",borderLeft:"1px solid gray"}}>2M</p>
                    <p className='m-2'>16.5%</p>
                   </div>
                    </div>
                </div>

                <div className='row pt-4 pb-4 m-0' style={{borderTop:"1px solid gray"}}>
                    <div className='col-lg-9'>
                        <h4 className='mb-2'>Find Indexes of a subarray with given sum</h4>
                        <span className='p-2 text-success'>Amazon</span>
                        <span className='p-2 text-success'>Micro-soft</span>
                        <span className='p-2 text-success'>+87 More</span>
                    </div>
                    <div className='col-lg-3'>
                   <Link to="/taketest" className='btn btn-success fs-5 px-5'> Solve Problem</Link>
                   <div className='mt-1 d-flex justify-content-center'>
                    <p className='m-2'>Medium</p>
                    <p className='m-2 px-2' style={{borderRight:"1px solid gray",borderLeft:"1px solid gray"}}>2M</p>
                    <p className='m-2'>16.5%</p>
                   </div>
                    </div>
                </div>
                <div className='row pt-4 pb-4 m-0' style={{borderTop:"1px solid gray"}}>
                    <div className='col-lg-9'>
                        <h4 className='mb-2'>Find Indexes of a subarray with given sum</h4>
                        <span className='p-2 text-success'>Amazon</span>
                        <span className='p-2 text-success'>Micro-soft</span>
                        <span className='p-2 text-success'>+87 More</span>
                    </div>
                    <div className='col-lg-3'>
                   <Link to="/taketest" className='btn btn-success fs-5 px-5'> Solve Problem</Link>
                   <div className='mt-1 d-flex justify-content-center'>
                    <p className='m-2'>Medium</p>
                    <p className='m-2 px-2' style={{borderRight:"1px solid gray",borderLeft:"1px solid gray"}}>2M</p>
                    <p className='m-2'>16.5%</p>
                   </div>
                    </div>
                </div>
                <div className='row pt-4 pb-4 m-0' style={{borderTop:"1px solid gray"}}>
                    <div className='col-lg-9'>
                        <h4 className='mb-2'>Find Indexes of a subarray with given sum</h4>
                        <span className='p-2 text-success'>Amazon</span>
                        <span className='p-2 text-success'>Micro-soft</span>
                        <span className='p-2 text-success'>+87 More</span>
                    </div>
                    <div className='col-lg-3'>
                   <Link to="/taketest" className='btn btn-success fs-5 px-5'> Solve Problem</Link>
                   <div className='mt-1 d-flex justify-content-center'>
                    <p className='m-2'>Medium</p>
                    <p className='m-2 px-2' style={{borderRight:"1px solid gray",borderLeft:"1px solid gray"}}>2M</p>
                    <p className='m-2'>16.5%</p>
                   </div>
                    </div>
                </div>
                
            </div>

        </>
    )
}

export default MainContainer