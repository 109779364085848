import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { GlobalStyle } from "./components/common/styles/global.js";
import ScrollToTop from './helper/ScrollToTop';
// import HomeOne from './HomeOne';
import 'react-toastify/dist/ReactToastify.css';
import HomeTwo from './HomeTwo';
import PageNotFound from './pages/404/PageNotFound';
import About from './pages/about/About';
import Login from './pages/account/Login';
import Register from './pages/account/Register';
import BlogClassic from './pages/blog/BlogClassic';
import BlogDetails from './pages/blog/BlogDetails';
import BlogGrid from './pages/blog/BlogGrid';
import ComingSoon from './pages/comingsoon/ComingSoon';
import Contact from './pages/contact/Contact';
import CourseList from './pages/courses/CourseList';
import StuProgramGrid from './pages/courses/StuProgramGrid.js';
import Events from './pages/events/Events';
import EventDetails from './pages/events/EventsDetails';
import Faq from './pages/faq/Faq';
import Gallery from './pages/gallery/Gallery';
import InstructorDetails from './pages/instructor/InstructorDetails';
import Instructor from './pages/instructor/Instructors';
import Cart from './pages/shop/Cart';
import ProductDetails from './pages/shop/ProductDetails';
import Product from './pages/shop/Products';

import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import Settings from './components/Settings.js';
import StripeForm from './components/StripeForm.js';
import AllStProg from './pages/courses/AllStProg.js';
import CrtProgram from './pages/courses/CrtProgram.js';
import LmsDetails from './pages/courses/LmsDetails.js';
import StateClass from './pages/courses/StateCLass.js';
import CourseItemGrid from './pages/courses/components/CourseItemsGrid.js';
import MyProgramsNew from './pages/courses/components/MyProgramsNew.js';
import PartnerOnBoard from './pages/partner-onboard/PartnerOnBoard.js';
import { PostApi } from './services/CommonService2.js';

import { SkeletonTheme } from 'react-loading-skeleton';
import HomeOne from './HomeOne.js';
import SupportEngine from './SupportEngine/SupportEngine.js';
import CodingHome from './coding/CodingHome.js';
import TakeTest from './coding/TakeTest.jsx';
import Compititive from './competitive-vpath/Compititive.js';
import Exams from './components/list/Exams.js';
import Program from './components/list/Program.js';
import ChangePassword from './pages/account/ChangePassword.js';
import Landing from './pages/coding-test/components/Landing.js';
import CompetitiveExams from './pages/courses/CompetitiveExams.js';
import GuidanceHome from './pages/courses/components/GuidanceHome.js';
import { GetApi } from './services/CommonService2.js';
import HomeThree from './HomeThree.js';



function App() {
    const [loadCount, setLoadCount] = useState(true);
    

    useEffect(()=>{
        const queryString = window.location.search;
       var fbAddIdd= queryString.substring(queryString.indexOf("=") + 1);
        if(queryString!=""){
            // JSON.parse(localStorage.removeItem('fbAddId'))
         var fbid= localStorage.setItem('fbAddId',fbAddIdd)
        }
        var tntId=JSON.parse(localStorage.getItem('tID'))
        var tenant_check=JSON.parse(localStorage.getItem('tenant_not_found'))
        // console.log("tenant_check mate",tenant_check);
        if(tntId==undefined ){
          
            setLoadCount(true);

            
            
        }
        else{
           
            setLoadCount(false);
        }

        if(tenant_check!=false){
            setLoadCount(true);
        }

        getTenant()
        
     
      },[])

    const [tenentType,setTenentType] = useState([])
    const [tenant,setTenant]=useState([])

    useEffect(()=>{
        getTenentType();
        checkloggedinsession();
        
    },[])

    const getTenentType = async () => {
        const reqparams = {
            pageno: '-1',
            query: { status: 'Active' },
        }

        const dataResponse = await PostApi(reqparams, 'VPTTYPES');

        setTenentType(dataResponse.data[0])
        // console.log("vpttypes",dataResponse);

       
    }

    const checkloggedinsession =async() =>{
        var tntId=JSON.parse(localStorage.getItem('tID'))

        const sessiondetails = JSON.parse(localStorage.getItem(`userdata${tntId}`))

        if(sessiondetails==null){
            getIntrodata()
        }
    }
    const getIntrodata = async () => {
        const reqparam = {
          pageno: '-1',
          query: {}
        }
        const ResponseData = await GetApi(reqparam, 'INTRO');
        localStorage.setItem('introdata', JSON.stringify(ResponseData.data[0]));

      
    }

    const getTenant=async()=>{
        const reqparam = {
            pageno: '-1',
            query: {}
          }

          const ResponseData = await GetApi(reqparam, 'TENANT');
        //   console.log("TEnant ",ResponseData.data[0]);
          setTenant(ResponseData.data[0])


    }



    const blurStyle = {
        filter: loadCount ? 'blur(30px)' : 'none',
        // transition: 'filter 0.3s ease-out', // Add a smooth transition
      };
    


    
    return (
        <div style={blurStyle}>
             <SkeletonTheme baseColor="#f5f5f5 " highlightColor="rgba(4,22,144,0.1)">
        <Router>
            <GlobalStyle />
            <ScrollToTop />
            {/* {console.log("tenanttype",tenentType)} */}
            <Switch>
                
                {/* {tenant?.tenantid=='80b42fdf' ?  <Route exact path={`${ "/"}`} component={HomeOne} />: <Route exact path={`${ "/"}`} component={HomeTwo} />} */}

                {tenant?.tenantid=='80b42fdf' ?  <Route exact path={`${ "/"}`} component={HomeOne} />: tenant?.tenantid == 'ffe21ec9' ? <Route exact path={`${ "/"}`} component={HomeThree} /> : <Route exact path={`${ "/"}`} component={HomeTwo} /> }


            

                {/* <Route exact path={`${ "/"}`} component={HomeTwo} /> */}
                {/* <Route path={`${ "/home-two"}`} component={HomeTwo} /> */}
                <Route path={`${ "/about"}`} component={About} />
                {tenentType?.tenant?.type=="political"?<Route path={`${ "/partner-onboard"}`} component={PartnerOnBoard } />:""}
            {/* <Route path={`${ "/partner-onboard"}`} component={PartnerOnBoard } /> */}

                <Route path={`${ "/course-grid"}`} component={CourseItemGrid} />
               
                <Route path={`${"/lms-page"}`} component={LmsDetails} />
                <Route path={`${ "/instructor"}`} component={Instructor} />
                <Route path={`${ "/instructor-details"}`} component={InstructorDetails} />
                <Route path={`${ "/gallery"}`} component={Gallery} />
                <Route path={`${ "/events"}`} component={Events} />
                <Route path={`${ "/event-details"}`} component={EventDetails} />
                <Route path={`${ "/login"}`} component={Login} />
                <Route path={`${ "/registration"}`} component={Register} />
                <Route path={`${ "/changepassword"}`} component={ChangePassword} />
                <Route path={`${ "/contact"}`} component={Contact} />
                <Route path={`${ "/faq"}`} component={Faq} />
                <Route path={`${ "/404"}`} component={PageNotFound} />
                <Route path={`${ "/coming-soon"}`} component={ComingSoon} />
                <Route path={`${ "/blog-classic"}`} component={BlogClassic} />
                <Route path={`${ "/blog-grid"}`} component={BlogGrid} />
                <Route path={`${ "/blog-details"}`} component={BlogDetails} />
                <Route path={`${ "/products"}`} component={Product} />
                <Route path={`${ "/product-details"}`} component={ProductDetails} />
                <Route path={`${ "/cart"}`} component={Cart} />
                <Route path={`${"/subjects"}`} component={StateClass} />
                <Route path={`${ "/stuprogram-grid"}`} component={StuProgramGrid} />
                <Route path={`${ "/Settings"}`} component={Settings} />
                <Route path={`${ "/Stripe-Form"}`} component={StripeForm} />
                <Route path={`${ "/MyCourses"}`} component={MyProgramsNew} />
                <Route path={`${ "/course-list"}`} component={CourseList} />
                <Route path={`${ "/allstuprogram-list"}`} component={AllStProg} />
                <Route path={`${ "/crt-program"}`} component={CrtProgram} />
                <Route path={`${ "/nonIT-courses"}`} component={GuidanceHome}/>
                <Route path={`${ "/program"}`} component={Program}/>
                <Route path={`${ "/exams"}`} component={Exams}/>
                <Route path={`${ "/examslist"}`} component={CompetitiveExams}/>
                <Route path={`${ "/codeeditor"}`} component={Landing}/>
                <Route path={`${ "/competitive"}`} component={Compititive} />
                <Route path={`${ "/code-home"}`} component={CodingHome} />
                <Route path={`${ "/taketest"}`} component={TakeTest} />
                {/* <Route path={`${ "/student"}`} component={Student} /> */}



             
                {/* <Route path={`${process.env.PUBLIC_URL + process.env.PUBLIC_URL + "/home-two"}`} component={HomeTwo} /> */}

            </Switch>
      <ToastContainer/>
            {tenant?.tenantid=='526daf25' &&<SupportEngine/>}
        </Router>
        </SkeletonTheme>
        </div>
    );
}

export default App;