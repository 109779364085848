import React, { Component, useEffect, useState } from 'react';
import Datas from '../../../data/course/popular.json';
import { Link } from 'react-router-dom';
import { Styles } from '../styles/popularCourse.js';
import { PostApi } from '../../../services/CommonService2.js';

const PopularCourse = () => {
    useEffect(() => {
        getCourses()
    }, [])

    const [course, setCourse] = useState([])

    const getCourses = async () => {
        const reqparam = {
            pageno: 1,
            query: { status: 'Active' },
            usertype: 'APP',
            tenantid: '',
            // optimize: true,
            userid: ""
        }

        const action = "ITPROGRAMS"


        const ResponseData = await PostApi(reqparam, action);
        // console.log("Courseee Listtt",ResponseData.data);
        setCourse(ResponseData.data)

    }

    return (
        <Styles>
            {/* Popular Course */}
            <div className="popular-course">
                <h5>Popular Programs</h5>
                <div className="popular-items">
                    {
                        course.reverse().map((data, index) => {

                            let imageid = 'https://visualpathedu.com/static/media/4th-year.15b862d606512e3257c0.jpg';
                            if (data?.image) {
                                imageid = data.image;
                            } else if (data?.image_url) {
                                imageid = data.image_url;
                            }
                            else {
                                imageid = data.imageurl ? data.imageurl : "";
                            }

                            return <div className="item-box d-flex" key={index}>
                                {index < 4 ?
                                    <>
                                        <div className="item-img">
                                            <Link to="/course-list"><img src={imageid} alt="" /></Link>
                                        </div>
                                        <div className="item-content">
                                            <p className="title">{data.name}</p>

                                            <p className="price">Rs.{data.sale_price}</p>
                                        </div>
                                    </> : ""}
                            </div>

                        })
                    }
                </div>
            </div>
        </Styles>
    )

}

export default PopularCourse
