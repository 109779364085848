import React, { Fragment, useState } from 'react';
// import { Col } from 'react-bootstrap';
import { Button, Col, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import LoginModal from '../../../components/LoginModal';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
function ExamsList(props) {

   
    const [modalUp, setModalUp] = useState(false)
    const [itemid, setItemId] = useState("")
    const [show, setShow] = useState(false)
    const [userid, setUserId] = useState("")
    const [confirm, setConfirm] = useState(false)
    const history = useHistory();

    const loginValidate = (data) => {
        var tntId = JSON.parse(localStorage.getItem('tID'))

        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        if (userdata == null) {
            setModalUp(true)
            // e.preventDefault()

            toast.info('login to check course details', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else {
            // history.push({
            //     pathname: "/program",
            //     state: data
            // })
            setItemId(data.id)
            setUserId(userdata.id)
            setShow(true)
        }
    }
    const openModal = () => {
        setModalUp(false)
    }

    const logIn = () => {

    }

    // console.log("props.totalRecords data", props.totalRecords);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const lastIndex = props.currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const npage = Math.ceil(props.totalRecords ? props.totalRecords / recordsPerPage : 0 / recordsPerPage)
    const records = props.courseList?.slice(firstIndex, lastIndex)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    const prePage = () => {
        if (props.currentPage !== 1) {
            props.setCurrentPage(props.currentPage - 1)
        }


    }
    const nextPage = () => {
        if (props.currentPage !== npage) {
            props.setCurrentPage(props.currentPage + 1)
        }

    }
    const changeCPage = (id) => {
        props.setCurrentPage(id)

    }
    const handleClose = () => {
        setShow(true)
      }
      const hadleCompetative = () => {
        setShow(true)
      }
    const handleConfirm = ()=>{
        setConfirm(false)
        setShow(false)
    }
    const handleMainClose=()=>{
        setConfirm(true)
    }
    return (
        <>
            <Fragment>
                {props?.courseList?.length > 0 ? props.courseList?.map((data, i) => {
                    let imageid = 'https://visualpathedu.com/static/media/4th-year.15b862d606512e3257c0.jpg';
                    if (data?.image) {
                        imageid = data.image;
                    } else if (data?.image_url) {
                        imageid = data.image_url;
                    }
                    else {
                        imageid = data.imageurl ? data.imageurl : "";
                    }
                    return <Col lg="4" key={i}>
                        {/* {console.log(data, "data image")} */}
                        <div className="course-item" onClick={() => loginValidate(data)}>

                            <div className="course-image">
                                <div >
                                    <img src={imageid} className="course-image"></img>

                                </div>
                            </div>

                            <div className="course-content">
                                <h6 className="heading">{data.examname}</h6>
                                <p className="desc">{data.description.slice(0, 100)}</p>
                                <div className="course-face d-flex justify-content-between">
                                    <div className="duration">
                                        <p><i className="las la-clock"></i>{data.duration} mins</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                }) : <Skeleton height={180} count={4} /> 
                }

                <Col md="12" className="text-center">

                    {/* <Pagination /> */}
                    {props.courseList.length > 0 ? <div className='mainpagination'>
                        <ul className='pagination'>
                            <li className='page-item'>
                                <label className='btn btn-outline-success page-link' onClick={prePage}><i className="fa-solid fa-angles-left"></i></label>

                            </li>
                            {numbers.map((n, i) => (
                                <li className={`page-item ${props.currentPage === n ? 'active' : ""}`} key={i}>
                                    <label className='btn btn-outline-success page-link' onClick={() => changeCPage(n)}>{n}</label>

                                </li>

                            ))}

                            <li className='page-item'>
                                <label className='btn btn-outline-success page-link' onClick={nextPage}><i className="fa-solid fa-angles-right"></i></label>

                            </li>


                        </ul>
                    </div> : ""}
                </Col>

                {modalUp && <LoginModal onClose={openModal} login={logIn}></LoginModal>}

                
                <Modal show={show} onHide={handleMainClose}  backdrop="static" style={{ width: "367px", overflow: "hidden", marginLeft: "30%" }} aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header closeButton="true" >
                    <h3 style={{ padding: "0px 20px" }}></h3>
                    <hr />
                </Modal.Header>


                <iframe src={"https://techbricksedu.com/competitive/"+ userid + "/" +itemid } style={{ minHeight: "460px", overflowX:"scroll" }}></iframe>
                 {/* <iframe src={"http://localhost:19006/competitive/"+ userid + "/" +itemid } style={{ minHeight: "460px", overflowX:"scroll" }}></iframe> */}




            </Modal>

            <Modal show={confirm} onHide={()=>setConfirm(false)} backdrop="static" style={{ width: "340px", overflow: "hidden", marginLeft: "30%" }} aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header  style={{backgroundColor:"#36968B"}}>
             <h4 className='text-center' style={{color:"white"}}> Are you sure want to exit from the Test ??</h4>
                </Modal.Header>


       
                <Modal.Footer style={{backgroundColor:"#36968B"}}>
          <Button variant="dark" onClick={()=>setConfirm(false)} >No</Button>
          <Button variant="danger" onClick={handleConfirm}>Yes</Button>
        </Modal.Footer>


            </Modal>
           

            </Fragment>

        </>
    )
}

export default ExamsList